import { useMutation, useQuery } from "@tanstack/react-query";
import {
  deleteContextFormulationById,
  fetchContextFormulation,
  fetchContextFormulationById,
  postContextFormulation,
} from "services/context_formulation";

export const useContextFormulation = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchContextFormulation(params, signal);
    },
    ...options,
  });
};

export const useContextFormulationById = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const id = queryKey[1];
      return fetchContextFormulationById(id, signal);
    },
    ...options,
  });
};

export const useStoreContextFormulation = (options) => {
  return useMutation({
    mutationFn: (params) => postContextFormulation(params),
    ...options,
  });
};

export const useContextFormulationDeleteById = (options) => {
  return useMutation({
    mutationFn: (params) => deleteContextFormulationById(params),
    ...options,
  });
};
