/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo } from "react";
import {
  Space,
  Table,
  Button,
  // Input,
  Spin,
  Pagination,
  Select,
  DatePicker,
  Modal,
  notification,
} from "antd";
import { NavLink } from "react-router-dom";
import Icon, {
  PlusOutlined,
  CloudDownloadOutlined,
  EyeOutlined,
  EditOutlined,
  RightOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { HomeLined } from "components/atoms/SVG";
import SearchInput from "components/molecules/SearchInput";
import CustomSelect from "components/molecules/CustomSelect";
import {
  useContextFormulation,
  useContextFormulationDeleteById,
} from "hooks/useContextFormulation";
import { debounce, toLower } from "lodash";
import dayjs from "dayjs";
import axiosInstance from "app/interceptors";
import { BASE_URL_MANRISK } from "services/config";
import mime from "mime";
import moment from "moment";

const ContextFormulattionList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [keyword, setKeyword] = useState("");
  const [year, setYear] = useState("");
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [deletedItem, setDeletedItem] = useState(null);

  const orgFilter = [
    { value: "keputusan_dirut", label: "Keputusan Dirut" },
    { value: "peraturan_dirut", label: "Peraturan Dirut" },
    { value: "surat_edaran", label: "Surat Edaran" },
  ];

  const deleteContextItem = useContextFormulationDeleteById({
    // error handling
    onError: (error) => {
      notification.error({
        message: "Kesalahan",
        description: error?.message,
      });
    },
    // success handling
    onSuccess: (data) => {
      if (data.isSuccess) {
        notification.success({
          message: data?.message,
          description: "Data telah Terhapus",
        });

        contextFormulation.refetch();
      } else {
        notification.error({
          message: data?.message ?? "Kesalahan",
          description: data?.innerMessage ?? data?.message,
        });
      }
      setDeletedItem(null);
      setOpen(false)
    },
  });

  const contextFormulation = useContextFormulation([
    "fetchContextFormulation",
    {
      Length: pageSize,
      Start: (currentPage - 1) * pageSize,
      Keyword: keyword,
      Year: year,
    },
  ]);

  const totalRecords = useMemo(
    () => contextFormulation?.data?.payload?.recordsTotal ?? 0,
    [contextFormulation]
  );

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleYearChange = (val) => {
    setYear(val?.$y ?? "");
  };

  const onSearch = debounce((e) => {
    setKeyword(toLower(e.target.value));
  }, 1000);

  const handleDownload = async (id) => {
    try {
      const response = await axiosInstance.get(`${BASE_URL_MANRISK}/context-formulation/download-context-formulation/${id}`, {
        responseType: 'blob',
      });
      
      const ext = mime.getExtension(response.data.type) === 'bin' ? 'pdf' : mime.getExtension(response.data.type); 
      if (ext !== 'json'){
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const filename = `${moment(Date.now()).format("YYYYMMDDkkmmss")}-perumusan-konteks.${ext}`;
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }else{
        const errorMsg = JSON.parse(await response.data.text());
        notification.error({
          message: "Kesalahan",
          description: errorMsg.message,
        });
      }
      
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  const columns = useMemo(() => {
    const defaultColumns = [
      {
        title: "No",
        key: "number",
        width: "68px",
        height: "72px",
        render: (val, text, data) => {
          return <p>{data + 1}</p>;
        },
      },
      {
        title: "Unit Organisasi",
        dataIndex: "uniteOrganization",
        key: "uniteOrganization",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val || ""}</p>;
        },
      },
      {
        title: "Periode Penerapan",
        key: "period",
        sortDirections: ["descend", "ascend"],
        render: (val) => {
          const start = val["startDate"];
          const end = val["endDate"];
          return (
            <p>{`${dayjs(start).format("MMMM-YYYY")} / ${dayjs(end).format(
              "MMMM-YYYY"
            )}`}</p>
          );
        },
      },
      {
        title: "Action",
        key: "action",
        render: (val) => (
          <Space size="middle">
            <NavLink to={`${val.id}`}>
              {<EyeOutlined style={{ fontSize: "18px" }} />}
            </NavLink>
            <NavLink to={`edit/${val.id}`}>
              {<EditOutlined style={{ fontSize: "18px" }} />}
            </NavLink>
            <NavLink to='#' onClick={() => handleDownload(val.id)}>
              {<CloudDownloadOutlined style={{ fontSize: "18px" }} />}
            </NavLink>
            <DeleteOutlined
              style={{ fontSize: "18px" }}
              onClick={() => showModal(val)}
            />
          </Space>
        ),
      },
    ];
    return defaultColumns;
  }, []);

  const showModal = (item) => {
    setDeletedItem(item);
    setOpen(true);
  };

  const handleOk = () => {
    deleteContextItem.mutate({id: deletedItem?.id});
  };

  const handleCancel = () => {
    setDeletedItem(null);
    setOpen(false);
  };

  return (
    <>
      <div className="flex items-center py-4 flex-wrap">
        <Space>
          <Icon component={HomeLined} className="mr-2" />
          <RightOutlined className="mr-3 text-xs right-cursor" />
        </Space>
        <div>
          <p className="font-medium" style={{ color: "#475467" }}>
            Dokumen Pendukung MR
          </p>
        </div>
        <Space>
          <RightOutlined className="mx-3 text-xs right-cursor" />
        </Space>

        <div>
          <p className="font-medium" style={{ color: "#01A24A" }}>
            Perumusan Konteks
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-medium">List Perumusan Konteks</h1>
        <Space>
          {/* <Button
            htmlType="submit"
            icon={<CloudDownloadOutlined />}
            className="btn-default mt-4"
          >
            <span className="ml-2 text-center">Download</span>
          </Button> */}
          <NavLink to={"create"}>
            <Button
              htmlType="submit"
              icon={<PlusOutlined />}
              className="btn-primary flex justify-center items-center font-medium mt-4 p-6 rounded-lg"
            >
              <span className="ml-2 text-center">Buat Baru</span>
            </Button>
          </NavLink>
        </Space>
      </div>
      <div
        className="table-list"
        style={{ border: "1px solid #EAECF0", borderRadius: "12px" }}
      >
        <div className="flex gap-4 py-6 px-6">
          <div className="space-x-5 flex w-full justify-end items-end">
            {/* <SearchInput
              className="flex-1"
              placeholder="Cari unit organisasi..."
              onChange={onSearch}
            /> */}
            {/* <CustomSelect
              optionsData={orgFilter}
              placeholder="Unit Organisasi"
              className="flex-none lg:w-96 rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none"
              isClearable={true}
            /> */}
            <DatePicker
              className="flex w-52 h-9"
              onChange={handleYearChange}
              picker="year"
              placeholder="Tahun"
            />
          </div>
        </div>
        <div>
          <Spin spinning={contextFormulation.isLoading}>
            <Table
              columns={columns}
              dataSource={contextFormulation?.data?.payload?.data}
              pagination={false}
              className="px-5"
              style={{ overflowX: "auto" }}
            />
          </Spin>
          <div className="flex table-paginator-wrapper my-3 px-2 justify-between">
            <div className="flex flex-row justify-content-between mb-2 px-2 justify-between items-center">
              <div className="show align-self-center pr-2 font-medium text-sm">
                {"Tampilkan Data"}
              </div>
              <div className="dropdown-paging align-self-center pr-2">
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Select
                    className="select-paging-ppd"
                    style={{ width: "60px" }}
                    options={[
                      { value: 5, label: 5 },
                      { value: 10, label: 10 },
                      { value: 30, label: 30 },
                      { value: 50, label: 50 },
                      { value: 100, label: 100 },
                    ]}
                    onChange={handlePageSizeChange}
                    value={pageSize}
                  />
                </Space>
              </div>
              <div className="border-paging align-self-center pr-2"></div>
              <div className="total align-self-center pr-2">
                {`Showing ${Math.min(
                  (currentPage - 1) * pageSize + 1,
                  totalRecords
                )} to ${Math.min(
                  currentPage * pageSize,
                  totalRecords
                )} of ${totalRecords} entries`}
              </div>
            </div>
            <div>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalRecords}
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Peringatan! Penghapusan data ini bersifat permanen."
        open={open}
        onOk={handleOk}
        confirmLoading={deleteContextItem.status === "pending"}
        onCancel={handleCancel}
      >
        <p>{"Apakah anda yakin akan menghapus data ini ?"}</p>
      </Modal>
    </>
  );
};

export default ContextFormulattionList;
