import { BASE_URL_MANRISK } from "./config";
import axiosInstance from "app/interceptors";

export const fetchManualIru = async (params, signal) => {
    const {data} = await axiosInstance.get(`${BASE_URL_MANRISK}/manual-iru/datatable`, {params, signal});
    return data
}


export const postManualIru = async ({ type, params }) => {
    const { data } = await axiosInstance.patch(`${BASE_URL_MANRISK}/manual-iru/${type}`, params);

    return data
}