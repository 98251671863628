import React, { useState } from "react";
import _ from "lodash";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import image from "../../../assets/Logo.png";
import "../../styles/menu.css";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import classNames from "classnames";
// import imageSVG from "../../../assets/Logo.svg";

const Sidebar = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userAccessRights } = useOutletContext();

  const handleMenu = (key, showChildren) => {
    const updatedMenu = [...menu];

    updatedMenu[key] = {
      ...updatedMenu[key],
      showChildren: !showChildren,
    };

    setMenu(updatedMenu);
  };

  const [menu, setMenu] = useState([
    {
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_9257_1779)">
            <path
              d="M13 21V11H21V21H13ZM3 13V3H11V13H3ZM9 11V5H5V11H9ZM3 21V15H11V21H3ZM5 19H9V17H5V19ZM15 19H19V13H15V19ZM13 3H21V9H13V3ZM15 5V7H19V5H15Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_9257_1779">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      label: "Dashboard",
      path: "/dashboard",
      key: "dashboard",
    },

    {
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 19V9H4V19H11ZM11 7V4C11 3.73478 11.1054 3.48043 11.2929 3.29289C11.4804 3.10536 11.7348 3 12 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V8C2 7.73478 2.10536 7.48043 2.29289 7.29289C2.48043 7.10536 2.73478 7 3 7H11ZM13 5V19H20V5H13ZM5 16H10V18H5V16ZM14 16H19V18H14V16ZM14 13H19V15H14V13ZM14 10H19V12H14V10ZM5 13H10V15H5V13Z"
            fill="white"
          />
        </svg>
      ),
      label: "Master Data",
      key: "master-data",
      parentPath: "/master-data",
      showChildren: false,
      children: [
        {
          label: "Level Kemungkinan",
          path: "/level-kemungkinan",
          key: "probability-level",
        },
        {
          label: "Kategori Risiko",
          path: "/kategori-risiko",
          key: "risk-category",
        },
        {
          label: "Area Dampak",
          path: "/area-dampak",
          key: "impact-area",
        },
        {
          label: "Data Level Risiko",
          path: "/data-level-risiko",
          key: "risk-level",
        },
        {
          label: "Data Besaran Risiko",
          path: "/data-besaran-risiko",
          key: "risk-size",
        },
        {
          label: "Sasaran Organisasi",
          path: "/sasaran-organisasi",
          key: "oragnization-target",
        },
        {
          label: "Level Dampak",
          path: "/level-dampak",
          key: "impact-level",
        },
      ],
    },

    {
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_9257_1801)">
            <path
              d="M17 15.246V22.118C17 22.2064 16.9766 22.2933 16.932 22.3697C16.8875 22.4462 16.8235 22.5094 16.7465 22.5531C16.6696 22.5967 16.5824 22.6191 16.4939 22.618C16.4055 22.6169 16.3189 22.5924 16.243 22.547L12 20.001L7.757 22.547C7.68104 22.5925 7.59436 22.617 7.50581 22.618C7.41727 22.6191 7.33004 22.5965 7.25304 22.5528C7.17605 22.5091 7.11205 22.4457 7.06759 22.3691C7.02313 22.2925 6.9998 22.2055 7 22.117V15.247C5.70615 14.211 4.76599 12.7988 4.30946 11.2055C3.85293 9.61215 3.90256 7.91634 4.45149 6.35242C5.00043 4.78851 6.02156 3.4337 7.37378 2.47523C8.726 1.51676 10.3425 1.00195 12 1.00195C13.6575 1.00195 15.274 1.51676 16.6262 2.47523C17.9784 3.4337 18.9996 4.78851 19.5485 6.35242C20.0974 7.91634 20.1471 9.61215 19.6905 11.2055C19.234 12.7988 18.2939 14.211 17 15.247V15.246ZM9 16.419V19.469L12 17.669L15 19.469V16.419C14.0468 16.8044 13.0282 17.0021 12 17.001C10.9718 17.0021 9.95316 16.8044 9 16.419ZM12 15.001C13.5913 15.001 15.1174 14.3688 16.2426 13.2436C17.3679 12.1184 18 10.5922 18 9.00095C18 7.40965 17.3679 5.88353 16.2426 4.75831C15.1174 3.63309 13.5913 3.00095 12 3.00095C10.4087 3.00095 8.88258 3.63309 7.75736 4.75831C6.63214 5.88353 6 7.40965 6 9.00095C6 10.5922 6.63214 12.1184 7.75736 13.2436C8.88258 14.3688 10.4087 15.001 12 15.001Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_9257_1801">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      label: "Piagam Manajemen Resiko",
      path: "/piagam-manajemen-risiko",
      key: "risk-management-charter",
    },

    {
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_9257_1813)">
            <path
              d="M19 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V15H22V19C22 19.7956 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7956 22 19 22ZM18 17V19C18 19.2652 18.1054 19.5196 18.2929 19.7071C18.4804 19.8946 18.7348 20 19 20C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V17H18ZM16 20V4H4V19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H16ZM6 7H14V9H6V7ZM6 11H14V13H6V11ZM6 15H11V17H6V15Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_9257_1813">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      label: "Dokumen Pendukung MR",
      parentPath: "/dokument-pendukung",
      key: "supporting-document",
      showChildren: false,
      children: [
        {
          label: "Daftar Resiko",
          path: "/daftar-risiko",
          key: "risk-list",
        },
        {
          label: "Perumusan Konteks",
          path: "/perumusan-konteks",
          key: "context-formula",
        },
        {
          label: "Profile Resiko (Direktur)",
          path: "/profil-risiko-admin",
          key: "risk-profile-admin",
        },
        {
          label: "Profile Resiko (Staff)",
          path: "/profil-risiko-staff",
          key: "risk-profile-staff",
        },
        {
          label: "Prioritas Resiko",
          path: "/prioritas-resiko",
          key: "risk-priority",
        },
        {
          label: "Manual IRU (Direktur)",
          path: "/manual-iru-admin",
          key: "manual-iru-admin",
        },
        {
          label: "Manual IRU (Staff)",
          path: "/manual-iru-staff",
          key: "manual-iru-staff",
        },
        {
          label: "Rencana Mitigasi (Direktur)",
          path: "/rencana-mitigasi-admin",
          key: "mitigation-plan-admin",
        },
        {
          label: "Rencana Mitigasi (Staff)",
          path: "/rencana-mitigasi-staff",
          key: "mitigation-plan-staff",
        },
      ],
    },

    {
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_9257_1878)">
            <path
              d="M17 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H7V0H9V2H15V0H17V2ZM17 4V6H15V4H9V6H7V4H5V20H19V4H17ZM7 8H17V10H7V8ZM7 12H17V14H7V12Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_9257_1878">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      label: "Laporan Triwulan MR",
      path: "/laporan-triwulan",
      key: "quater-report",
    },

    {
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_9257_1889)">
            <path
              d="M9 1V3H15V1H17V3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9ZM20 10H4V19H20V10ZM15.036 11.136L16.45 12.55L11.5 17.5L7.964 13.964L9.38 12.55L11.501 14.672L15.037 11.136H15.036ZM7 5H4V8H20V5H17V6H15V5H9V6H7V5Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_9257_1889">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      label: "Agenda Pembahasan (Direktur)",
      path: "/agenda-pembahasan-admin",
      key: "agenda",
    },

    {
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_9257_1889)">
            <path
              d="M9 1V3H15V1H17V3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9ZM20 10H4V19H20V10ZM15.036 11.136L16.45 12.55L11.5 17.5L7.964 13.964L9.38 12.55L11.501 14.672L15.037 11.136H15.036ZM7 5H4V8H20V5H17V6H15V5H9V6H7V5Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_9257_1889">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      label: "Agenda Pembahasan (Staff)",
      path: "/agenda-pembahasan-staff",
      key: "agenda-staff",
    },

    {
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_9257_1900)">
            <path
              d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12H4C4 16.418 7.582 20 12 20C16.418 20 20 16.418 20 12C20 7.582 16.418 4 12 4C9.25 4 6.824 5.387 5.385 7.5H8V9.5H2V3.5H4V6C5.824 3.57 8.729 2 12 2ZM13 7V11.585L16.243 14.828L14.828 16.243L11 12.413V7H13Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_9257_1900">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      label: "Riwayat Management Resiko",
      path: "/riwayat-manajemen-risiko",
      key: "risk-management-history",
    },
  ]);

  return (
    <aside className="overflow-y-auto border-gray-500 bg-[#101828] h-screen shadow-2xl w-[295px] flex-col flex justify-between">
      <div>
        <div className="relative">
          <img src={image} className="w-36 object-contain mx-auto py-5" alt="" />
        </div>
        <div className="w-full font-sans text-sm text-center p-3 text-[#D0D5DD] bg-[#344054]">
          Manajemen Risiko
        </div>
        <ul className="space-y-2 m-4">
          {_.map(
            menu,
            (
              { label, icon, path, parentPath, children, showChildren, key },
              index
            ) =>
              userAccessRights?.[key]?.canView && (
                <li key={index} className="cursor-pointer">
                  <div
                    onClick={() => {
                      if (path) {
                        navigate(path);
                      } else {
                        handleMenu(index, showChildren);
                      }
                    }}
                    className={`flex justify-between gap-2 items-center p-2 font-semibold text-sm text-[#ffffff] rounded-lg hover:bg-[#01A24A] 
                  ${
                    (location?.pathname?.includes(path) ||
                      location?.pathname?.includes(parentPath)) &&
                    "bg-[#01A24A]"
                  }`}
                  >
                    <div className="flex">
                      {icon}
                      <span className="ml-3">{label}</span>
                    </div>
                    {children && children.length > 0 ? (
                      <BiChevronDown
                        className={classNames(
                          "text-2xl",
                          "transition-all",
                          showChildren ? "rotate-180" : ""
                        )}
                      />
                    ) : null}
                  </div>

                  {children && showChildren && (
                    <div
                      className={showChildren ? "show-submenu" : "hide-submenu"}
                    >
                      <ul className="space-y-2 m-4">
                        {children.map(
                          ({ label, child, path, key }, index) =>
                            userAccessRights?.[key]?.canView && (
                              <li key={index} className="cursor-pointer">
                                <div
                                  onClick={() => {
                                    navigate(path);
                                  }}
                                  className={`flex items-center p-2 font-semibold text-sm text-[#ffffff] rounded-lg hover:bg-[#01A24A] ${
                                    location?.pathname?.includes(path) &&
                                    "bg-[#01A24A]"
                                  }`}
                                >
                                  {child}
                                  <span className="ml-3">{label}</span>
                                </div>
                              </li>
                            )
                        )}
                      </ul>
                    </div>
                  )}
                </li>
              )
          )}
        </ul>
      </div>
      <div className="text-white ml-4 mt-12 mb-3 font-normal text-sm">
        © BPDLH 2023, Version 1.0
      </div>
    </aside>
  );
};

export default Sidebar;
