import React from "react";
import { Label } from "components";

const My403Component = () => {
  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <div className="text-3xl font-bold">
        You are not allowed to access this page
      </div>
    </div>
  );
};

export default My403Component;
