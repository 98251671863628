import { BASE_URL_MANRISK } from "./config";
import axiosInstance from "app/interceptors";

export const fetchRiskProfileHeader = async (id, signal) => {
    const {data} = await axiosInstance.get(`${BASE_URL_MANRISK}/risk-profile-probis/header/${id}`, {signal});

    return data
}

export const fetchRiskProfileById = async (id, signal) => {
    const {data} = await axiosInstance.get(`${BASE_URL_MANRISK}/risk-profile-probis/items/${id}`, {signal});

    return data
}

export const fetchRiskProfileByIdSingle = async (id, signal) => {
    const {data} = await axiosInstance.get(`${BASE_URL_MANRISK}/risk-profile-probis/${id}`, {signal});

    return data
}

export const postRiskProfile = async ({ type, params }) => {
    const { data } = await axiosInstance.post(`${BASE_URL_MANRISK}/risk-profile-probis/${type}`, params);

    return data
}