import { BASE_URL_MANRISK } from "./config";
import axiosInstance from "app/interceptors";

export const fetchRiskMitigationById = async (id, signal) => {
    const {data} = await axiosInstance.get(`${BASE_URL_MANRISK}/risk-mitigation/${id}`, {signal});

    return data
}

export const fetchRiskMitigationOption = async (signal) => {
    const {data} =  await axiosInstance.get(`${BASE_URL_MANRISK}/master-data/risk-mitigation-option`, {signal});

    return data
}

export const postRiskMitigation = async ({type, params}) => {
    const {data} =  await axiosInstance.patch(`${BASE_URL_MANRISK}/risk-mitigation/${type}`, params);

    return data
}