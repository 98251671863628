import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Space,
  notification,
  Spin,
  Select,
  Row,
  Col,
  Table,
  Typography,
} from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  LoadingOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import "./index.scss";
import { NavLink, useParams } from "react-router-dom";
import Icon, { RightOutlined } from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";

import { BASE_URL_MANRISK } from "services/config";
import { Input } from "components";
import TextArea from "antd/es/input/TextArea";
import { useNavigate } from "react-router-dom";
import axiosInstance from "app/interceptors";

const HomeLine = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.66667 13.1667H12.3333M8.18141 1.30333L2.52949 5.69927C2.15168 5.99312 1.96278 6.14005 1.82669 6.32405C1.70614 6.48704 1.61633 6.67065 1.56169 6.86588C1.5 7.08627 1.5 7.32558 1.5 7.80421V13.8333C1.5 14.7667 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1585 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1585 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7667 16.5 13.8333V7.80421C16.5 7.32558 16.5 7.08627 16.4383 6.86588C16.3837 6.67065 16.2939 6.48704 16.1733 6.32405C16.0372 6.14005 15.8483 5.99312 15.4705 5.69927L9.81859 1.30333C9.52582 1.07562 9.37943 0.961766 9.21779 0.918001C9.07516 0.879384 8.92484 0.879384 8.78221 0.918001C8.62057 0.961766 8.47418 1.07562 8.18141 1.30333Z"
      stroke="#667085"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const { Option } = Select;

const CreateAdminRiskProfile = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const spinIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
  const requiredField = [{ required: true, message: "Wajib Diisi" }];

  const [loading, setLoading] = useState(false);
  const [dataProfileRisk, setDataProfileRisk] = useState({});
  const [dataDivision, setDataDivision] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState([]);

  const profileId = params?.id;

  const onFinish = (values) => {
    setLoading(true);

    const url = `${BASE_URL_MANRISK}/risk-profile-admin`;

    axiosInstance
      .put(url, values)
      .then((response) => {
        if (response.data.isSuccess) {
          notification.success({
            message: response.data.message,
            description: "Data telah disimpan",
          });

          navigate("/profil-risiko-admin");
        } else {
          notification.error({
            message: "Kesalahan",
            description: response.data.message,
          });
        }
      })
      .catch((err) => {
        notification.error({
          message: "Kesalahan",
          description: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    axiosInstance.get(`${BASE_URL_MANRISK}/risk-profile-admin/${profileId}`)
      .then((response) => response.data)
      .then((data) => {
        setDataProfileRisk(data.payload);

        form.setFieldsValue({ id: profileId });

        form.setFieldsValue({
          idsKadivProbis: data.payload.kadivProbises.map((item) => item.id),
        });

        setSelectedDivision(data.payload.kadivProbises.map((item) => item.id));

        form.setFieldsValue({ notes: data.payload.notes });
      });

    axiosInstance.get(`${BASE_URL_MANRISK}/master-data/division`)
      .then((response) => response.data)
      .then((data) => {
        setDataDivision(data.payload);
      });
  }, [profileId, form]);

  const columns = [
    {
      title: "Kode SS/IKU",
      key: "number",
      width: 40,
      render: (val, text, data) => {
        return <p>{"-"}</p>;
      },
    },
    {
      title: "Indikator Kinerja Utama",
      key: "sasaranOrganisasi",
      width: 100,
      // dataIndex: 'sasaranOrganisasi',
      // sortDirections: ['descend', 'ascend'],
      render: (value) => <span>{dataProfileRisk?.ikuName}</span>,
    },
    {
      title: "Level Target",
      key: "levelDampak",
      // dataIndex: 'sasaranOrganisasi',
      children: [
        {
          title: "Q1",
          key: "q1",
          width: 50,
          render: (val) => {
            return <span>{val?.q1 || 0}</span>;
          },
        },
        {
          title: "Q2",
          key: "q2",
          width: 50,
          render: (val) => {
            return <span>{val?.q2 || 0}</span>;
          },
        },
        {
          title: "Smt1",
          key: "smt1",
          width: 50,
          render: (val) => {
            return <span>{val?.semester || 0}</span>;
          },
        },
        {
          title: "Q3",
          key: "q3",
          width: 50,
          render: (val) => {
            return <span>{val?.q3 || 0}</span>;
          },
        },
        {
          title: "s.d.Q3",
          key: "sdQ3",
          width: 50,
          render: (val) => {
            return <span>{val?.toQ3 || 0}</span>;
          },
        },
        {
          title: "Q4",
          key: "q4",
          width: 50,
          render: (val) => {
            return <span>{val?.q4 || 0}</span>;
          },
        },
        {
          title: "Y",
          key: "y",
          width: 50,
          render: (val) => {
            return <span>{val?.y || 0}</span>;
          },
        },
      ],
    },
  ];

  return (
    <>
      <Spin spinning={loading} indicator={spinIcon}>
        <div className="px-4">
          <Form
            form={form}
            name="form-create-risk-profile"
            onFinish={onFinish}
            initialValues={{
              idsKadivProbis: [{}],
            }}
          >
            {/* <Form.Item noStyle shouldUpdate>
                            {() => (
                                <Typography>
                                    <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
                                </Typography>
                            )}
                        </Form.Item> */}

            <Form.Item name={"id"} noStyle />
            <div className="flex items-center py-5 flex-wrap">
              <Space>
                <Icon component={HomeLine} className="mr-2" />
                <RightOutlined className="mr-3 text-xs right-cursor" />
              </Space>
              <div>
                <p className="font-medium mr-3">Dokumen Pendukung MR</p>
              </div>
              <Space>
                <RightOutlined className="mr-3 text-xs right-cursor" />
              </Space>
              <div>
                <p className="font-medium mr-3">Profile Risiko</p>
              </div>

              <Space>
                <RightOutlined className="mr-3 text-xs right-cursor" />
              </Space>
              <div>
                <p className="font-medium" style={{ color: "#01A24A" }}>
                  Tambah Profile & Peta Risiko
                </p>
              </div>
            </div>

            <div className="py-5">
              <p className="text-2xl font-medium">Formulir Profil Risiko</p>
            </div>

            <div
              className="w-100 overflow-hidden mb-24"
              style={{
                borderRadius: "8px",
                border: "1px solid #EAECF0",
                background: "#FFFF",
              }}
            >
              <div className="">
                <div
                  className=""
                  style={{ background: "#00BD52", height: "10px" }}
                ></div>

                <div
                  className="m-5 w-100 overflow-hidden mb-6"
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #EAECF0",
                    background: "#FFFF",
                  }}
                >
                  <div className="">
                    <div
                      className=""
                      style={{ background: "#00BD52", height: "10px" }}
                    ></div>
                  </div>

                  <div className="row px-5 mt-4">
                    <label className="field-required font-medium">
                      Sasaran Organisasi
                    </label>
                    {/* <Form.Item
											name={["idOrganizationalGoal"]}
											initialValues={dataProfileRisk.organizationTargetName}
											rules={requiredField}
											style={{ width: "100%" }}
										> */}
                    <Input
                      placeholder="Sasaran Organisasi"
                      type="text"
                      className="form-input my-3"
                      disabled
                      style={{ width: "100%" }}
                      value={dataProfileRisk.organizationTargetName}
                    />

                    {/* </Form.Item> */}
                  </div>
                  <div className="px-5">
                    <label className="field-required font-medium">
                      Indikator Kinerja Utama
                    </label>
                    {/* <Form.Item
											name={["idIKU"]}
											rules={requiredField}
											style={{ width: "100%" }}
										> */}
                    <Input
                      placeholder="Sasaran Organisasi"
                      type="text"
                      className="form-input my-3"
                      disabled
                      style={{ width: "100%" }}
                      value={dataProfileRisk.ikuName}
                    />
                    {/* </Form.Item> */}
                  </div>

                  <div className="px-5">
                    <label className="field-required font-medium">
                      SubIndikator Kinerja Utama
                    </label>
                    <Input
                      placeholder="SubIndikator Kinerja Utama"
                      type="text"
                      className="form-input my-3"
                      disabled
                      style={{ width: "100%" }}
                      value={dataProfileRisk.subIKUName || "-"}
                    />
                  </div>

                  <div className="px-5">
                    <label className="field-required font-medium">Target</label>
                    <Input
                      placeholder="Target"
                      type="text"
                      className="form-input my-3"
                      disabled
                      style={{ width: "100%" }}
                      value={dataProfileRisk.target || "-"}
                    />
                  </div>

                  <div className="px-5">
                    <label className="field-required font-medium">Divisi</label>
                    <Form.Item shouldUpdate>
                      {() => (
                        <Form.List name="idsKadivProbis">
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map((field) => (
                                <div key={field.key} className="">
                                  <Row className="mt-2">
                                  <Col
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "start",
                                        marginRight: "10px",
                                      }}
                                    >
                                      <div className="mr-4">
                                        <Button
                                          size="large"
                                          style={{
                                            borderRadius: "8px",
                                            width: "50px",
                                            height: "48px",
                                            background: "#F04438",
                                            color: "#FFFF",
                                            border: "none",
                                          }}
                                          className="flex px-2 py-2 pl-4 pr-4 justify-center items-center"
                                          icon={<DeleteOutlined />}
                                          onClick={() => remove(field.name)}
                                        ></Button>
                                      </div>
                                    </Col>
                                    <Col
                                      span={20}
                                      style={{
                                        marginRight: "10px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div className="mr-4">
                                        <Form.Item name={[field.name]}>
                                          <Select
                                            placeholder="Divisi"
                                            type="text"
                                            className=""
                                            style={{ width: "100%" }}
                                            onChange={() => setSelectedDivision(form.getFieldValue('idsKadivProbis')) }
                                          >
                                            {dataDivision.map((item) => (
                                              <Option
                                                key={item.id}
                                                value={item.id}
                                                disabled={selectedDivision.includes(item.id)}
                                              >
                                                {item.name}
                                              </Option>
                                            ))}
                                          </Select>
                                        </Form.Item>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              ))}
                              <Space
                                direction="horizontal"
                                style={{
                                  width: "100%",
                                  justifyContent: "end",
                                  marginLeft: "-20px",
                                }}
                              >
                                <div>
                                  <Button
                                    style={{
                                      borderRadius: "8px",
                                      height: "40px",
                                    }}
                                    icon={<PlusOutlined />}
                                    className="flex px-2 py-2 pl-4 pr-4 justify-center items-center"
                                    onClick={() => add()}
                                  >
                                    <span className="ml-2 font-medium">
                                      Tambah Divisi
                                    </span>
                                  </Button>
                                </div>
                              </Space>
                            </>
                          )}
                        </Form.List>
                      )}
                    </Form.Item>
                  </div>
                </div>

                <div
                  className="m-5 w-100 overflow-hidden mb-6"
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #EAECF0",
                    background: "#FFFF",
                  }}
                >
                  <div className="">
                    <div
                      className=""
                      style={{ background: "#00BD52", height: "10px" }}
                    ></div>
                  </div>

                  <div className="p-5">
                    <Table
                      dataSource={[dataProfileRisk?.targetImpact ?? {}]}
                      columns={columns}
                      bordered
                      size="middle"
                      pagination={false}
                    />
                  </div>
                </div>

                <div
                  className="m-5 w-100 overflow-hidden mb-6"
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #EAECF0",
                    background: "#FFFF",
                  }}
                >
                  <div className="">
                    <div
                      className=""
                      style={{ background: "#00BD52", height: "10px" }}
                    ></div>
                  </div>

                  <div className="row px-5 mt-4">
                    <div className="">
                      <label className="font-medium">
                        Catatan
                      </label>
                      <Form.Item name={["notes"]} 
                        // rules={requiredField}
                      >
                        <TextArea
                          placeholder="Catatan"
                          type="text"
                          className="form-input mt-2"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="mt-6 px-4"
              style={{
                borderRadius: "8px",
                border: "1px solid #EAECF0",
                background: "#FFFF",
                position: "fixed",
                bottom: 0,
                width: "calc(100vw - 400px)",
              }}
            >
              <div className="flex justify-between items-center">
                <Space>
                  <NavLink to={"/profil-risiko-admin"}>
                    <Button icon={<ArrowLeftOutlined />} className="btn-back">
                      <span className="ml-2 text-center">Kembali</span>
                    </Button>
                  </NavLink>
                </Space>
                <div className="row">
                  <Form.Item>
                    <Space>
                      {/* <NavLink to={'/proposal-submission'}> */}
                      {/* <Button
												icon={<SaveOutlined />}
												className="btn-back"
												onClick={() => { onFinish(form.getFieldsValue(), "list") }}
											>
												<span className="ml-2 text-center">Simpan & Draft</span>
											</Button> */}
                      {/* </NavLink> */}
                    </Space>
                    <Space>
                      {/* <NavLink to={'/proposal-submission/create-proposal/step-2'}> */}
                      <Button
                        htmlType="submit"
                        className="btn-submit mt-4 ml-4"

                        // onClick={() => {
                        //   let error = form.getFieldsError()

                        //   console.log(error)
                        //   onFinish(form.getFieldsValue(), "next")
                        // }}
                      >
                        <span className="ml-2 text-center">Simpan</span>
                        <Icon
                          component={ArrowRightOutlined}
                          className="mr-2"
                          style={{ fontSize: 15 }}
                        />
                      </Button>
                      {/* </NavLink> */}
                    </Space>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Spin>
    </>
  );
};

export default CreateAdminRiskProfile;
