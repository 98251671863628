/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Space,
  Table,
  Button,
  // Input,
  Spin,
  Pagination,
  Select,
  Tag,
  Modal,
  notification,
} from "antd";
import { NavLink } from "react-router-dom";
import Icon, {
  CloudDownloadOutlined,
  // SearchOutlined,
  PlusCircleOutlined,
  EyeOutlined,
  EditOutlined,
  RightOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import "../index.scss";
import { BASE_URL_MANRISK } from "services/config";
import { HomeLined } from "components/atoms/SVG";
import axiosInstance from "app/interceptors";
import { useSelector } from "react-redux";
import { debounce, toLower } from "lodash";
import SearchInput from "components/molecules/SearchInput";

const RiskProfileList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const userRole = localStorage.getItem("role");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [open, setOpen] = useState(false);
  const [deletedItem, setDeletedItem] = useState(null);
  const [Keyword, setKeyword] = useState("");

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const showModal = (item) => {
    setDeletedItem(item);
    setOpen(true);
  };

  const handleCancel = () => {
    setDeletedItem(null);
    setOpen(false);
  };

  const handleDeleteClick = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.delete(
        `${BASE_URL_MANRISK}/risk-profile-probis`,
        {
          params: {
            riskProfileProbisId: deletedItem,
          },
        }
      );

      if (response?.data?.isSuccess) {
        notification.success({
          message: response.data.message,
          description: "Data berhasil dihapus",
        });
        setFetchTrigger(!fetchTrigger);
      } else {
        notification.error({
          message: "Kesalahan",
          description: response.message,
        });
      }
    } catch (error) {
      notification.error({
        message: "Kesalahan",
        description: error.message,
      });
    } finally {
      setDeletedItem(null);
      setOpen(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let path = "";

        path = `${BASE_URL_MANRISK}/risk-profile-probis/datatable`;

        const response = await axiosInstance.get(path, {
          params: {
            Length: pageSize,
            Start: (currentPage - 1) * pageSize,
            Keyword: Keyword,
          },
        });

        if (response.data.isSuccess) {
          setData(response.data.payload.data);
          setTotalRecords(response.data.payload.recordsTotal);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [Keyword, userRole, currentPage, pageSize, fetchTrigger]);

  const renderData = data.slice(0, pageSize);

  const columns = [
    {
      title: "No",
      key: "number",
      width: "68px",
      height: "72px",
      render: (val, text, data) => {
        return <p>{data + 1}</p>;
      },
    },
    {
      title: "Sasaran Organisasi",
      dataIndex: "organizationTargetName",
      key: "organizationTargetName",
    },
    {
      title: "IKU",
      dataIndex: "iku",
      key: "iku",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Divisi",
      dataIndex: "divisionName",
      key: "divisionName",
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      sortDirections: ["descend", "ascend"],
      render: (status) => {
        let color;
        switch (status) {
          case "Selesai":
            color = "success";
            break;
          case "Sedang Diproses":
            color = "warning";
            break;
          default:
            color = "default";
        }

        return (
          <Tag color={color}>
            <span className="font-semibold">{status}</span>
          </Tag>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (val) => (
        <Space size="middle">
          {val.status === "Belum Mulai" ? (
            <>
            {!val.canEdit && (
              <NavLink to={`detail-kadiv-probis/${val.id}`}>
                {<EyeOutlined style={{ fontSize: "18px" }} />}
              </NavLink>
            )}
            {val.canEdit &&
              <NavLink to={`create-kadiv-probis/${val.id}`}>
                <PlusCircleOutlined style={{ fontSize: "18px" }} />
              </NavLink>
            }
            </>
          ) : (
            <Space size="middle">
              <NavLink to={`detail-kadiv-probis/${val.id}`}>
                {<EyeOutlined style={{ fontSize: "18px" }} />}
              </NavLink>
              {val.canEdit && 
                <NavLink to={`create-kadiv-probis/${val.id}`}>
                  <EditOutlined style={{ fontSize: "18px" }} />
                </NavLink>
              }
              {/* <NavLink to="#" onClick={() => showModal(val.id)}>
                {<DeleteOutlined style={{ fontSize: "18px" }} />}
              </NavLink> */}
            </Space>
          )}
        </Space>
      ),
    },
  ];

  const onSearch = debounce((e) => {
    setKeyword(toLower(e.target.value));
  }, 1000);

  return (
    <>
      <div className="flex items-center py-4 flex-wrap">
        <Space>
          <Icon component={HomeLined} className="mr-2" />
          <RightOutlined className="mr-3 text-xs right-cursor" />
        </Space>
        <div>
          <p className="font-medium" style={{ color: "#475467" }}>
            Dokumen Pendukung MR
          </p>
        </div>
        <Space>
          <RightOutlined className="mx-3 text-xs right-cursor" />
        </Space>

        <div>
          <p className="font-medium" style={{ color: "#01A24A" }}>
            Profile Risiko - Staff
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-medium">List Profile Risiko - Staff</h1>
        <Space>
          {/* <Button
            htmlType="submit"
            icon={<CloudDownloadOutlined />}
            className="btn-default mt-4"
          >
            <span className="ml-2 text-center">Download</span>
          </Button> */}
        </Space>
      </div>
      <div
        className="table-list"
        style={{ border: "1px solid #EAECF0", borderRadius: "12px" }}
      >
        <div className="flex gap-4 py-6 px-6">
          <div className="space-x-5 flex w-full">
            <SearchInput placeholder="Cari..." onChange={onSearch} />
          </div>
        </div>
        <div>
          <Spin spinning={loading}>
            <Table
              columns={columns}
              dataSource={renderData}
              pagination={false}
              className="px-5"
              style={{ overflowX: "auto" }}
            />
          </Spin>
          <div className="flex table-paginator-wrapper my-3 px-2 justify-between">
            <div className="flex flex-row justify-content-between mb-2 px-2 justify-between items-center">
              <div className="show align-self-center pr-2 font-medium text-sm">
                {"Tampilkan Data"}
              </div>
              <div className="dropdown-paging align-self-center pr-2">
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Select
                    className="select-paging-ppd"
                    style={{ width: "60px" }}
                    options={[
                      { value: 5, label: 5 },
                      { value: 10, label: 10 },
                      { value: 30, label: 30 },
                      { value: 50, label: 50 },
                      { value: 100, label: 100 },
                    ]}
                    onChange={handlePageSizeChange}
                    value={pageSize}
                  />
                </Space>
              </div>
              <div className="border-paging align-self-center pr-2"></div>
              <div className="total align-self-center pr-2">
                {`Showing ${Math.min(
                  (currentPage - 1) * pageSize + 1,
                  totalRecords
                )} to ${Math.min(
                  currentPage * pageSize,
                  totalRecords
                )} of ${totalRecords} entries`}
              </div>
            </div>
            <div>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalRecords}
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
        title="Peringatan! Penghapusan data ini bersifat permanen."
        open={open}
        onOk={handleDeleteClick}
        onCancel={handleCancel}
      >
        <p>{"Apakah anda yakin akan menghapus data ini ?"}</p>
      </Modal> */}
    </>
  );
};

export default RiskProfileList;
