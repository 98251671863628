export const API = async ({ params, service }, callback) => {
  try {
    const response = await service(params);
    if (response?.data?.meta?.success) {
      callback?.(true, response?.data);
    } else {
      callback?.(false, response?.data);
    }
    return response;
  } catch (error) {
    callback?.(false, error?.response);
    throw error;
  }
};

export const getErrorMessage = (error) => {
  let message =
    error?.response?.data?.data?.message ??
    error?.response?.data?.meta?.message ??
    error?.response?.data?.message ??
    error?.response?.message ??
    error?.message ??
    "Something Went Wrong";
  return message;
};

export const dashboardBackground = (val) => {
  let color = "bg-[#669f2a]";
  if (val >= 1 && val <= 5) color = "bg-[#06aed4]";
  if (val >= 6 && val <= 11) color = "bg-[#669f2a]";
  if (val >= 12 && val <= 15) color = "bg-[#fec84b]";
  if (val >= 16 && val <= 19) color = "bg-[#f3742d]";
  if (val >= 20 && val <= 24) color = "bg-[#d92d20]";
  if (val >= 25) color = "bg-[#bc1b06]";

  return `font-bold text-white ${color} border-s-4 border-[#f9fcf6] text-2xl h-24`;
};

export const dashboardBackground2 = (val) => {
  let color = "bg-[#669f2a]";
  if (val >= 1 && val <= 5) color = "bg-[#06aed4]";
  if (val >= 6 && val <= 11) color = "bg-[#669f2a]";
  if (val >= 12 && val <= 15) color = "bg-[#fec84b]";
  if (val >= 16 && val <= 19) color = "bg-[#f3742d]";
  if (val >= 20 && val <= 24) color = "bg-[#d92d20]";
  if (val >= 25) color = "bg-[#bc1b06]";

  return `font-bold text-white ${color} border-s-4 border-[#f9fcf6] text-xl h-12`;
};

export const toRoman = (num) => {
  const romanNumerals = [
    { value: 1000, numeral: "M" },
    { value: 900, numeral: "CM" },
    { value: 500, numeral: "D" },
    { value: 400, numeral: "CD" },
    { value: 100, numeral: "C" },
    { value: 90, numeral: "XC" },
    { value: 50, numeral: "L" },
    { value: 40, numeral: "XL" },
    { value: 10, numeral: "X" },
    { value: 9, numeral: "IX" },
    { value: 5, numeral: "V" },
    { value: 4, numeral: "IV" },
    { value: 1, numeral: "I" },
  ];

  if (isNaN(num)) {
    return "";
  }
  let romanNumeral = "";
  for (let i = 0; i < romanNumerals.length; i++) {
    while (num >= romanNumerals[i].value) {
      romanNumeral += romanNumerals[i].numeral;
      num -= romanNumerals[i].value;
    }
  }
  return romanNumeral;
};
