import React, { useMemo } from "react";
import {
  Routes as WrapperRoutes,
  Route,
  BrowserRouter as Router,
  Navigate,
  useOutletContext,
  createBrowserRouter,
  defer,
  RouterProvider,
} from "react-router-dom";
import _, { map } from "lodash";
import { useSelector } from "react-redux";

import route from "./route";

import My404Component from "pages/My404Component";

import { AuthLayout, BasicLayout } from "components";
import { AuthConsumer } from "providers/authProvider";
import {
  CALLBACK,
  LOGOUT,
  LOGOUT_CALLBACK,
  SILENT_RENEW,
} from "components/auth";
import { getUserDetail } from "services/user";
import My403Component from "pages/403";

const ProtectedRoute = ({ children, routeKey, type }) => {
  const { userAccessRights } = useOutletContext();
  return (
    <AuthConsumer>
      {({ isAuthenticated, signinRedirect }) => {
        if (isAuthenticated()) {
          // return (
          //   <BasicLayout>
          //     {React.cloneElement(children, {
          //       // accessRight: userAccessRights?.[routeKey],
          //     })}
          //   </BasicLayout>
          // );
          if (routeKey === "" || userAccessRights?.[routeKey]?.[type]) {
            return (
              <BasicLayout>
                {React.cloneElement(children, {
                  accessRight: userAccessRights?.[routeKey],
                })}
              </BasicLayout>
            );
          } else return <Navigate to="/unauthorized" replace />;
        } else {
          return signinRedirect();
        }
      }}
    </AuthConsumer>
  );
};

const Routes = () => {
  const { user } = useSelector(({ auth }) => auth);
  const RouteElements = useMemo(() => {
    return createBrowserRouter([
      {
        element: <CALLBACK />,
        path: "/signin-oidc",
      },
      {
        element: <LOGOUT />,
        path: "/logout",
      },
      {
        element: <LOGOUT_CALLBACK />,
        path: "/signout-oidc",
      },
      {
        element: <SILENT_RENEW />,
        path: "/silentrenew",
      },
      {
        element: <AuthLayout />,
        loader: async ({ signal }) => {
          try {
            if (user?.UserId) {
              // const { data: userDetail } = await getUserDetail(
              //   user?.UserId,
              //   signal
              // );
              // if (
              //   userDetail?.userAccess &&
              //   Array.isArray(userDetail?.userAccess)
              // ) {
              if (user?.userAccess?.moduleName?.toLowerCase() === 'management risiko') {
                // const index = userDetail?.userAccess?.findIndex(
                //   (x) => x.moduleName?.toLowerCase() === "management risiko"
                // );
                // if (index > -1) {
                  // const userAccess = userDetail?.userAccess?.[index]?.menuModules;
                  const userAccess = user?.userAccess.menuModules;
                  console.log(user);
                  const userAccessRights = userAccess.reduce((obj, item) => {
                    return Object.assign(obj, {
                      [item.key]: { ...item },
                    });
                  }, {});
                  return defer({
                    userAccessRights,
                  });
                // }
              }
              return defer({
                userAccessRights: null,
              });
            }
            return defer({
              userAccessRights: null,
            });
          } catch (error) {
            console.log("Error while getting user access rights ", error);
            return defer({
              userAccessRights: null,
            });
          }
        },
        children: map(route, (val, key) => {
          const Component = val.element;
          return {
            key: key,
            path: val.path,
            element: (
              <ProtectedRoute
                routeKey={val.key}
                type={val?.type || "canUpdate"}
              >
                <Component />
              </ProtectedRoute>
            ),
          };
        }),
      },
      {
        element: <My404Component />,
        path: "*",
      },
      { element: <My403Component />, path: "/unauthorized" },
    ]);
  }, [user]);
  return <RouterProvider router={RouteElements} />;
};

const OldRoutes = () => {
  const { token } = useSelector((state) => state.auth);
  return (
    <Router>
      <WrapperRoutes>
        {_.map(_.filter(route, { routeType: "public" }), (val, key) => {
          const Component = val.element;
          return (
            <Route
              exact={true}
              key={key}
              path={val.path}
              element={
                !token ? (
                  <AuthLayout>
                    <Component />
                  </AuthLayout>
                ) : (
                  <Navigate to={"/dashboard"} />
                )
              }
            />
          );
        })}
        {_.map(_.filter(route, { routeType: "private" }), (val, key) => {
          const Component = val.element;
          return (
            <Route
              exact={true}
              key={key}
              path={val.path}
              element={
                token ? (
                  <BasicLayout>
                    <Component />
                  </BasicLayout>
                ) : (
                  <Navigate to={"/"} />
                )
              }
            />
          );
        })}
        <Route path="*" element={<My404Component />} />
      </WrapperRoutes>
    </Router>
  );
};

export default Routes;
