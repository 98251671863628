import React, { createContext, useEffect, useState } from "react"
import {
    Form,
    Button,
    Space,
    notification,
    Spin,
    message,
    Upload,
    Row,
    Col,
    DatePicker,
    // Typography
} from 'antd'
import {
    ArrowLeftOutlined,
    LoadingOutlined,
    SaveOutlined,
} from '@ant-design/icons'
import "./index.scss"
import { useNavigate, NavLink, useParams } from "react-router-dom"
import Icon, { RightOutlined } from '@ant-design/icons'
import { BASE_URL_FILE_SERVICE, BASE_URL_MANRISK } from 'services/config'
import { Input } from "components"
import { CalenderIcon } from "components/atoms/SVG"
import dayjs from 'dayjs'
import axiosInstance from "app/interceptors"


const ReachableContext = createContext(null)

const PdfViewer = ({ pdfUrl, pdfName }) => {
    const openPdf = () => {
        window.open(pdfUrl, '_blank')
    }

    return (
        <div>
            <NavLink color="primary" onClick={openPdf}>
                {pdfName}
            </NavLink>
            {/* <Button disabled={false} type="primary" onClick={openPdf}>
                Open PDF
            </Button> */}
        </div>
    )
}

const HomeLine = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.66667 13.1667H12.3333M8.18141 1.30333L2.52949 5.69927C2.15168 5.99312 1.96278 6.14005 1.82669 6.32405C1.70614 6.48704 1.61633 6.67065 1.56169 6.86588C1.5 7.08627 1.5 7.32558 1.5 7.80421V13.8333C1.5 14.7667 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1585 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1585 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7667 16.5 13.8333V7.80421C16.5 7.32558 16.5 7.08627 16.4383 6.86588C16.3837 6.67065 16.2939 6.48704 16.1733 6.32405C16.0372 6.14005 15.8483 5.99312 15.4705 5.69927L9.81859 1.30333C9.52582 1.07562 9.37943 0.961766 9.21779 0.918001C9.07516 0.879384 8.92484 0.879384 8.78221 0.918001C8.62057 0.961766 8.47418 1.07562 8.18141 1.30333Z" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

const UploadIcon = () => (
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3" y="3" width="40" height="40" rx="20" fill="#F2F4F7" />
        <path d="M19.667 26.3333L23.0003 23M23.0003 23L26.3337 26.3333M23.0003 23V30.5M29.667 26.9524C30.6849 26.1117 31.3337 24.8399 31.3337 23.4167C31.3337 20.8854 29.2816 18.8333 26.7503 18.8333C26.5682 18.8333 26.3979 18.7383 26.3054 18.5814C25.2187 16.7374 23.2124 15.5 20.917 15.5C17.4652 15.5 14.667 18.2982 14.667 21.75C14.667 23.4718 15.3632 25.0309 16.4894 26.1613" stroke="#475467" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
        <rect x="3" y="3" width="40" height="40" rx="20" stroke="#F9FAFB" strokeWidth="6" />
    </svg>
)

const CreateRiskManagementCharter = () => {
    let navigate = useNavigate()
    const [form] = Form.useForm()
    const spinIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />

    const { id } = useParams()

    const [loading, setLoading] = useState(false)
    const requiredField = [{ required: true, message: 'Wajib Diisi' }]
    const [pdfData, setPdfData] = useState(null)
    const [selectedYear, setSelectedYear] = useState(null)

    useEffect(() => {
        axiosInstance.get(`${BASE_URL_MANRISK}/risk-management-charter/${id}`)
            .then((response) => response.data)
            .then((data) => {
                form.setFieldsValue({
                    id: data.payload.id,
                    name: data.payload.name,
                    nomor: data.payload.nomor,
                    year: dayjs(data.payload.year),
                    attachmentId: data.payload.attachmentId,
                })

                setSelectedYear(dayjs(data.payload.year))

                axiosInstance.get(`${BASE_URL_FILE_SERVICE}/files/${data.payload.attachmentId}`)
                    .then((pdfResponse) => pdfResponse.data)
                    .then((pdfData) => {
                        if (pdfData.message === 'success') {
                            setPdfData(pdfData.data)
                        } else {
                            message.error('Failed to fetch PDF details')
                        }
                    })
                    .catch((error) => {
                        console.error('Error fetching PDF details:', error)
                        message.error('Error fetching PDF details')
                    })
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
                message.error('Error fetching data')
            })
    }, [id, form])

    const handleDatePickerChange = (date, dateString) => {
        setSelectedYear(date)
    }

    const handleAttachmentChange = (info) => {
        const { file } = info

        if (file.status === 'done' || file.status === 'error') {
            setLoading(false) // Set loading to false after the file upload is complete (success or error)

            if (file.status === 'done') {
                const { id } = file.response.data
                form.setFieldsValue({ attachmentId: id })
                setPdfData(file.response.data) // Update pdfData with the uploaded file data
            }

            if (file.status === 'error') {
                form.setFieldsValue({ attachmentId: undefined })
                message.error(`${info.file.name} file upload failed.`)
            }
        }

        if (file.status !== 'uploading') {
            console.log(info.file, info.fileList)
        }

        if (file.status === 'removed') {
            form.setFieldsValue({ attachmentId: undefined })
            setPdfData(null)
        }
    }

    const beforeUpload = (file) => {
        const isPDF = file.type === 'application/pdf'
        if (!isPDF) {
            form.setFieldsValue({
                'attachmentId': undefined,
            })
            message.error('You can only upload PDF files!')
        } else {
            setLoading(true) // Set loading to true when the upload starts
        }
        return isPDF
    }

    const props = {
        name: 'file',
        action: `${BASE_URL_FILE_SERVICE}/files/docs`,
        onChange: handleAttachmentChange,
        beforeUpload: beforeUpload,
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files)
        },
        maxCount: 1,
        accept: "application/msword, application/vnd.ms-excel, application/pdf"
        // fileList: pdfData ? [{ uid: 'pdf-uid', name: pdfData.name }] : [],
    }

    const onFinish = (values) => {
        setLoading(true)

        const url = `${BASE_URL_MANRISK}/risk-management-charter`

        axiosInstance
            .patch(url, values)
            .then((response) => {
                if (response.data.isSuccess) {
                    notification.success({
                        message: response.data.message,
                        description: "Data telah disimpan"
                    })

                    navigate('/piagam-manajemen-risiko')
                } else {
                    notification.error({
                        message: "Kesalahan",
                        description: response.data.message
                    })
                }
            })
            .catch((err) => {
                notification.error({
                    message: "Kesalahan",
                    description: err.message
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <>
            <Spin spinning={loading} indicator={spinIcon}>
                <div className="px-4">
                    <Form
                        form={form}
                        name="form-create-donor"
                        onFinish={onFinish}
                    >
                        <div className="flex items-center py-5 flex-wrap">
                            <Space>
                                <Icon component={HomeLine} className="mr-2" />
                                <RightOutlined
                                    className="mr-3 text-xs right-cursor" />
                            </Space>
                            <div>
                                <p className="font-medium mr-3">Piagam Manajemen Risiko</p>
                            </div>
                            <Space>
                                <RightOutlined
                                    className="mr-3 text-xs right-cursor" />
                            </Space>
                            <div>
                                <p className="font-medium mr-3" style={{ color: "#01A24A" }}>Edit Data Piagam</p>
                            </div>
                        </div>
                        <div className="py-5">
                            <p className="text-2xl font-medium">Edit Data Piagam</p>
                        </div>

                        {/* <Form.Item noStyle shouldUpdate>
                            {() => (
                                <Typography>
                                    <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
                                </Typography>
                            )}
                        </Form.Item> */}
                        <Form.Item
                            name={["id"]}
                            noStyle
                        >

                        </Form.Item>
                        <div className="w-100 overflow-hidden mb-12 pb-12" style={{ borderRadius: "8px", border: "1px solid #EAECF0" }}>
                            <div className="" style={{ background: "#00BD52", height: "10px" }}></div>
                            <div className="row px-5 mt-4">
                                <label className="field-required font-medium">Nama UPR BPDLH</label>
                                <Form.Item
                                    name={["name"]}
                                    rules={requiredField}
                                    style={{ width: "100%" }}
                                >
                                    <Input
                                        placeholder="Nama UPR BPDLH"
                                        type="text"
                                        disabled
                                        className="form-input mt-2"
                                        style={{ width: "100%" }} />

                                </Form.Item>
                            </div>

                            <div className="row px-5 mt-2">
                                <Row gutter={24} className="">
                                    <Col span={12}>
                                        <div className="">
                                            <label className="field-required font-medium">Nomor</label>
                                            <Form.Item
                                                name={["nomor"]}
                                                rules={requiredField}
                                                style={{ width: "100%" }}
                                            >
                                                <Input
                                                    placeholder="Nomor"
                                                    type="text"
                                                    className="form-input mt-2"
                                                    style={{ width: "100%" }} />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="">
                                            <label className="field-required font-medium">Tahun Ditetapkan</label>
                                            <Form.Item name={["year"]} rules={requiredField}>
                                                <DatePicker
                                                    suffixIcon={<CalenderIcon />}
                                                    placeholder="dd/mm/yyyy"
                                                    className="form-input mt-2"
                                                    style={{ width: "100%" }}
                                                    onChange={handleDatePickerChange}
                                                    value={selectedYear}
                                                >
                                                    <Icon component={CalenderIcon} className="mr-2" style={{ fontSize: 15 }} />
                                                </DatePicker>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="px-5">
                                <label className="field-required font-medium">Dokumen Pendukung</label>
                                <div className="row mt-4 mb-4">
                                    {pdfData && (
                                        <div>
                                            <PdfViewer disabled={true} pdfUrl={pdfData.url} pdfName={pdfData.name} className="mb-4 my-4" />
                                            {/* <iframe
                                                className="my-4"
                                                title="PDF Viewer"
                                                width="100%"
                                                height="600px"
                                                src={pdfData.url}
                                            /> */}
                                        </div>
                                    )}
                                    <Form.Item
                                        name={["attachmentId"]}
                                        className="my-4"
                                        rules={requiredField}
                                    >
                                        <Upload.Dragger  {...props}>
                                            <p className="ant-upload-drag-icon flex justify-center">
                                                <UploadIcon />
                                            </p>
                                            <p className="ant-upload-text"><strong style={{ color: "#01A24A" }}>Klik untuk upload</strong> atau drag and drop</p>
                                            <p className="ant-upload-hint">PDF (max. 1mb)</p>
                                        </Upload.Dragger>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>

                        <div className="list mt-12 px-4" style={{ borderRadius: "8px", border: "1px solid #EAECF0", background: "#FFFF", position: "fixed", bottom: 20, width: "calc(100vw - 400px)" }}>
                            <div className="flex justify-between items-center">
                                <Space>
                                    <NavLink to={'/piagam-manajemen-risiko'}>
                                        <Button
                                            style={{ borderRadius: "8px", border: "1px solid #EAECF0", width: "120px", height: "50px" }}
                                            icon={<ArrowLeftOutlined />}
                                            className="flex justify-center items-center font-medium"
                                        >
                                            <span className="ml-2 text-center">Kembali</span>
                                        </Button>
                                    </NavLink>
                                </Space>
                                <Form.Item>
                                    <ReachableContext.Provider value="Light">
                                        <Space>
                                            <Button
                                                style={{ background: "#00BD52", color: "#FFFF", borderRadius: "8px", width: "120px", height: "50px" }}
                                                htmlType="submit"
                                                icon={<SaveOutlined />}
                                                className="flex justify-center items-center font-medium mt-4"
                                            >
                                                <span className="ml-2 text-center">Simpan</span>
                                            </Button>
                                        </Space>
                                    </ReachableContext.Provider>
                                </Form.Item>
                            </div>
                        </div>

                    </Form>
                </div>
            </Spin>
        </>
    )
}

export default CreateRiskManagementCharter