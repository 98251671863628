import { useQuery } from "@tanstack/react-query";
import {
  fetchAreaImpact,
  fetchAreaImpactLevel,
  fetchAreaImpactLevelCategory,
  fetchDirectorat,
  fetchDivision,
  fetchDivisionById,
  fetchLowProbabilityLevel,
  fetchLowProbabilityOption,
  fetchNonLowProbabilityLevel,
  fetchNonLowProbabilityOption,
  fetchOrganizationalGoalsList,
  fetchPosition,
  fetchPositionKadiv,
  fetchProbabilityLevel,
  fetchRiskCategory,
  fetchRiskLevel,
  fetchRiskLevelDatatable,
  fetchRiskSize,
  fetchRiskSizeDatatable,
} from "services/master";

export const useDirectorat = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchDirectorat(params, signal);
    },
    ...options,
  });
};

export const useDivision = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchDivision(params, signal);
    },
    ...options,
  });
};

export const useDivisionById = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const id = queryKey[1];
      return fetchDivisionById(id, signal);
    },
    ...options,
  });
};

export const usePosition = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchPosition(params, signal);
    },
    ...options,
  });
};

export const usePositionKadiv = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchPositionKadiv(params, signal);
    },
    ...options,
  });
};

export const useAreaImpact = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchAreaImpact(params, signal);
    },
    ...options,
  });
};

export const useAreaImpactLevel = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchAreaImpactLevel(params, signal);
    },
    ...options,
  });
};

export const useAreaImpactLevelCategory = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchAreaImpactLevelCategory(params, signal);
    },
    ...options,
  });
};

export const useRiskCategory = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchRiskCategory(params, signal);
    },
    ...options,
  });
};

export const useProbabilityLevel = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchProbabilityLevel(params, signal);
    },
    ...options,
  });
};

export const useLowProbabilityOption = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchLowProbabilityOption(params, signal);
    },
    ...options,
  });
};

export const useLowProbabilityLevel = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchLowProbabilityLevel(params, signal);
    },
    ...options,
  });
};

export const useNonLowProbabilityOption = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchNonLowProbabilityOption(params, signal);
    },
    ...options,
  });
};

export const useNonLowProbabilityLevel = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchNonLowProbabilityLevel(params, signal);
    },
    ...options,
  });
};

export const useRiskSize = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchRiskSize(params, signal);
    },
    ...options,
  });
};

export const useRiskLevel = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchRiskLevel(params, signal);
    },
    ...options,
  });
};

export const useRiskLevelDatatable = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchRiskLevelDatatable(params, signal);
    },
    ...options,
  });
};

export const useRiskSizeDatatable = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchRiskSizeDatatable(params, signal);
    },
    ...options,
  });
};

export const useOrganizationalGoalsList = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchOrganizationalGoalsList(params, signal);
    },
    ...options,
  });
};
