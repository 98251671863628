import { Pagination, Select, Space, Spin, Table, Tag } from "antd";
import { useRiskHistory, useRiskHistoryMitigation } from "hooks/useRiskHistory";
import React, { useMemo, useState } from "react";

import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";

const MitigationPlanTabs = ({ year }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const mitigation = useRiskHistoryMitigation([
    "risk-history/mitigation-plan",
    {
      Year: year,
      Length: pageSize,
      Start: (currentPage - 1) * pageSize,
    },
  ]);

  const totalRecords = useMemo(
    () => mitigation?.data?.payload?.recordsTotal ?? 0,
    [mitigation]
  );

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = useMemo(() => {
    const defaultColumns = [
      {
        title: "No",
        key: "number",
        width: "68px",
        height: "72px",
        render: (val, text, data) => {
          return <p>{data + 1}</p>;
        },
      },
      {
        title: "Unit Organisasi",
        dataIndex: "unitOrganization",
        key: "unitOrganization",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val || ""}</p>;
        },
      },
      {
        title: "Kadiv Probis",
        dataIndex: "division",
        key: "division",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val || ""}</p>;
        },
      },
      {
        title: "Kejadian Risiko",
        dataIndex: "riskEvent",
        key: "riskEvent",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val || ""}</p>;
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sortDirections: ["descend", "ascend"],
        render: (status) => {
          let color;
          let desc;
          switch (status) {
            case "Selesai":
              color = "success";
              desc = status;
              break;
            case "Sedang Diproses":
              color = "warning";
              desc = "Sedang Diproses";
              break;
            case "BelumMulai":
              color = "default";
              desc = "Belum Mulai"
              break;
            default:
              color = "default";
              desc = status;
          }

          return (
            <Tag color={color}>
              <span className="font-semibold">{desc}</span>
            </Tag>
          );
        },
      },
      {
        title: "Action",
        key: "action",
        render: (val) => (
          <Space size="middle">
            <NavLink to={`${window.location.origin}/rencana-mitigasi-admin/${val.idRiskMitigation}`}>
              {<EyeOutlined style={{ fontSize: "18px" }} />}
            </NavLink>
          </Space>
        ),
      },
    ];
    return defaultColumns;
  }, []);

  return (
    <>
      <Spin spinning={mitigation.isLoading}>
        <Table
          columns={columns}
          dataSource={mitigation?.data?.payload?.data}
          pagination={false}
          style={{ overflowX: "auto" }}
        />
      </Spin>
      <div className="flex table-paginator-wrapper my-3 px-2 justify-between">
        <div className="flex flex-row justify-content-between mb-2 px-2 justify-between items-center">
          <div className="show align-self-center pr-2 font-medium text-sm">
            {"Tampilkan Data"}
          </div>
          <div className="dropdown-paging align-self-center pr-2">
            <Space direction="vertical" style={{ width: "100%" }}>
              <Select
                className="select-paging-ppd"
                style={{ width: "60px" }}
                options={[
                  { value: 5, label: 5 },
                  { value: 10, label: 10 },
                  { value: 30, label: 30 },
                  { value: 50, label: 50 },
                  { value: 100, label: 100 },
                ]}
                onChange={handlePageSizeChange}
                value={pageSize}
              />
            </Space>
          </div>
          <div className="border-paging align-self-center pr-2"></div>
          <div className="total align-self-center pr-2">
            {`Showing ${Math.min(
              (currentPage - 1) * pageSize + 1,
              totalRecords
            )} to ${Math.min(
              currentPage * pageSize,
              totalRecords
            )} of ${totalRecords} entries`}
          </div>
        </div>
        <div>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalRecords}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default MitigationPlanTabs;
