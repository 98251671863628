import React, { useState, useEffect } from "react";
import { Space, Table, Spin } from "antd";
import Icon, { RightOutlined } from "@ant-design/icons";
import { BASE_URL_MANRISK } from "services/config";
import axiosInstance from "app/interceptors";
import { useDashboard } from "hooks/useDashboard";
import { dashboardBackground2 } from "helpers";
import { find } from "lodash";
import classNames from "classnames";

const HomeLined = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.66667 13.1667H12.3333M8.18141 1.30333L2.52949 5.69927C2.15168 5.99312 1.96278 6.14005 1.82669 6.32405C1.70614 6.48704 1.61633 6.67065 1.56169 6.86588C1.5 7.08627 1.5 7.32558 1.5 7.80421V13.8333C1.5 14.7667 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1585 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1585 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7667 16.5 13.8333V7.80421C16.5 7.32558 16.5 7.08627 16.4383 6.86588C16.3837 6.67065 16.2939 6.48704 16.1733 6.32405C16.0372 6.14005 15.8483 5.99312 15.4705 5.69927L9.81859 1.30333C9.52582 1.07562 9.37943 0.961766 9.21779 0.918001C9.07516 0.879384 8.92484 0.879384 8.78221 0.918001C8.62057 0.961766 8.47418 1.07562 8.18141 1.30333Z"
      stroke="#667085"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const RiskSize = () => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);

  const dashboard = [
    {
      "probabilityLevel": 5,
      "probabilityName": "Hampir pasti terjadi",
      "riskSizes": [
        {
          "riskSizeName": "7",
          "impactLevel": 1,
          "impactName": "Rendah",
        },
        {
          "riskSizeName": "12",
          "impactLevel": 2,
          "impactName": "Sedang",
        },
        {
          "riskSizeName": "17",
          "impactLevel": 3,
          "impactName": "Tinggi",
        },
        {
          "riskSizeName": "22",
          "impactLevel": 4,
          "impactName": "Sangat Tinggi",
        },
        {
          "riskSizeName": "25",
          "impactLevel": 5,
          "impactName": "Sangat Tinggi",
        }
      ]
    },
    {
      "probabilityLevel": 4,
      "probabilityName": "Sering terjadi",
      "riskSizes": [
        {
          "riskSizeName": "4",
          "impactLevel": 1,
          "impactName": "Sangat Rendah",
        },
        {
          "riskSizeName": "9",
          "impactLevel": 2,
          "impactName": "Rendah",
        },
        {
          "riskSizeName": "14",
          "impactLevel": 3,
          "impactName": "Sedang",
        },
        {
          "riskSizeName": "19",
          "impactLevel": 4,
          "impactName": "Tinggi",
        },
        {
          "riskSizeName": "24",
          "impactLevel": 5,
          "impactName": "Sangat Tinggi",
        }
      ]
    },
    {
      "probabilityLevel": 3,
      "probabilityName": "Kadang terjadi",
      "riskSizes": [
        {
          "riskSizeName": "3",
          "impactLevel": 1,
          "impactName": "Sangat Rendah",
        },
        {
          "riskSizeName": "8",
          "impactLevel": 2,
          "impactName": "Rendah",
        },
        {
          "riskSizeName": "13",
          "impactLevel": 3,
          "impactName": "Sedang",
        },
        {
          "riskSizeName": "18",
          "impactLevel": 4,
          "impactName": "Tinggi",
        },
        {
          "riskSizeName": "23",
          "impactLevel": 5,
          "impactName": "Sangat Tinggi",
        }
      ]
    },
    {
      "probabilityLevel": 2,
      "probabilityName": "Jarang terjadi",
      "riskSizes": [
        {
          "riskSizeName": "2",
          "impactLevel": 1,
          "impactName": "Sangat Rendah",
        },
        {
          "riskSizeName": "6",
          "impactLevel": 2,
          "impactName": "Rendah",
        },
        {
          "riskSizeName": "11",
          "impactLevel": 3,
          "impactName": "Rendah",
        },
        {
          "riskSizeName": "16",
          "impactLevel": 4,
          "impactName": "Tinggi",
        },
        {
          "riskSizeName": "21",
          "impactLevel": 5,
          "impactName": "Sangat Tinggi",
        }
      ]
    },
    {
      "idProbability": "44bec94b-dd1e-4d43-bd22-6dd8357549fe",
      "probabilityLevel": 1,
      "probabilityName": "Hampir tidak terjadi",
      "riskSizes": [
        {
          "riskSizeName": "1",
          "impactLevel": 1,
          "impactName": "Sangat Rendah",
        },
        {
          "riskSizeName": "5",
          "impactLevel": 2,
          "impactName": "Sangat Rendah",
        },
        {
          "riskSizeName": "10",
          "impactLevel": 3,
          "impactName": "Rendah",
        },
        {
          "riskSizeName": "15",
          "impactLevel": 4,
          "impactName": "Sedang",
        },
        {
          "riskSizeName": "20",
          "impactLevel": 5,
          "impactName": "Sangat Tinggi",
        }
      ]
    }
  ]

  const dashboardColumns = [
    {
      title: "Metriks Analisis Risiko 5x5",
      width: "5%",
      className: "text-base !bg-[#077439] !rounded-xl !text-white" ,
      onHeaderCell: (_, index) => ({
        rowSpan: 2,
      }),
      children: [
        {
          title: "Level Dampak1 ",
          dataIndex: "key",
          rowScope: "row",
          className: "!bg-[#077439] !text-white border-t-4 border-[#f9fcf6]",
          width: "1%",
          onCell: (_, index) => ({
            rowSpan: index === 0 ? 5 : 0,
          }),
          onHeaderCell: (_, index) => ({
            rowSpan: 0,
          }),
          render: (val, text, data) => {
            return (
              <p className="flex justify-center items-center whitespace-nowrap w-2 -rotate-90">
                Level Kemungkinan
              </p>
            );
          },
        },
        {
          title: "Level Dampak2",
          onHeaderCell: (_, index) => ({
            rowSpan: 0,
          }),
          dataIndex: "probabilityLevel",
          // rowScope: "row",
          width: "5%",
          className: "font-semibold text-center text-slate-600 border-t-4 border-e-4 border-s-4 border-[#f9fcf6]",
          align: "center"
        },
        {
          title: "Level Dampak3",
          dataIndex: "probabilityName",
          key: "probabilityName",
          className: "font-semibold text-slate-600 border-t-4 border-[#f9fcf6]",
          onHeaderCell: (_, index) => ({
            rowSpan: 0,
          }),
          render: (val, text, data) => {
            return <p>
              {val.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                return letter.toUpperCase();
              }) 
              || ""}</p>;
          },
        },
      ],
    },
    {
      title: "Level Dampak",
      dataIndex: "name",
      key: "name",
      sortDirections: ["descend", "ascend"],
      className: "border-s-4 border-[#f9fcf6] !bg-[#077439] !text-white",
      render: (val, text, data) => {
        return <p>{val || ""}</p>;
      },
      children: [
        {
          title: "1-Tidak Signifikan",
          dataIndex: "riskSizes",
          key: "riskSizes",
          className: "text-center border-s-4 border-t-4 !border-b-0 border-[#f9fcf6]",
          sortDirections: ["descend", "ascend"],
          align: "center",
          width: "13%",
          render: (val) => {
            const find_val = find(val, { impactLevel: 1 });
            return {
              props: {
                className: dashboardBackground2(+find_val?.riskSizeName || 0),
              },
              children: 
              <div className="">
                {+find_val?.riskSizeName || 0}
              </div>,
            };
          },
        },
        {
          title: "2-Minor",
          dataIndex: "riskSizes",
          key: "riskSizes",
          className: "text-center border-s-4 border-t-4 !border-b-0 border-[#f9fcf6]",
          // sortDirections: ["descend", "ascend"],
          align: "center",
          width: "13%",
          render: (val) => {
            const find_val = find(val, { impactLevel: 2 });
            return {
              props: {
                className: dashboardBackground2(+find_val?.riskSizeName || 0),
              },
              children: 
              <div className="">
                  {+find_val?.riskSizeName || 0}
                </div>,
            };
          },
        },
        {
          title: "3-Moderate",
          dataIndex: "riskSizes",
          key: "riskSizes",
          className: "text-center border-s-4 border-t-4 !border-b-0 border-[#f9fcf6]",
          sortDirections: ["descend", "ascend"],
          align: "center",
          width: "13%",
          render: (val) => {
            const find_val = find(val, { impactLevel: 3 });
            return {
              props: {
                className: dashboardBackground2(+find_val?.riskSizeName || 0),
              },
              children: 
              <div className="">
                {+find_val?.riskSizeName || 0}
              </div>,
            };
          },
        },
        {
          title: "4-Signifikan",
          dataIndex: "riskSizes",
          key: "riskSizes",
          className: "text-center border-s-4 border-t-4 !border-b-0 border-[#f9fcf6]",
          sortDirections: ["descend", "ascend"],
          align: "center",
          width: "13%",
          render: (val) => {
            const find_val = find(val, { impactLevel: 4 });
            return {
              props: {
                className: dashboardBackground2(+find_val?.riskSizeName || 0),
              },
              children: 
              <div className="">
                {+find_val?.riskSizeName || 0}
              </div>,
            };
          },
        },
        {
          title: "5-Sangat Signifikan",
          dataIndex: "riskSizes",
          key: "riskSizes",
          className: "text-center border-s-4 border-t-4 !border-b-0 border-[#f9fcf6]",
          sortDirections: ["descend", "ascend"],
          align: "center",
          width: "13%",
          render: (val) => {
            const find_val = find(val, { impactLevel: 5 });
            return {
              props: {
                className: dashboardBackground2(+find_val?.riskSizeName || 0),
              },
              children: 
              <div className="">
                {+find_val?.riskSizeName || 0}
              </div>,
            };
          },
        },
      ],
    },
  ];

//   useEffect(() => {
//     let path = `${BASE_URL_MANRISK}/master-data/datatable-risk-size`;

//     axiosInstance
//       .get(path)
//       .then((response) => {
//         if (response.data.isSuccess) {
//           setData(response.data.payload.data);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   }, []);

  const data = [
    {
        levelRisiko: 'Sangat Tinggi (5)',
        besaranRisiko: '20 - 25',
        warna: 'Merah',
        classWarna: 'bg-[#d92d20]', 
    },
    {
        levelRisiko: 'Tinggi (4)',
        besaranRisiko: '16 - 19',
        warna: 'Oranye',
        classWarna: 'bg-[#f3742d]', 
    },
    {
        levelRisiko: 'Sedang (3)',
        besaranRisiko: '12 - 15',
        warna: 'Kuning',
        classWarna: 'bg-[#fec84b]', 
    },
    {
        levelRisiko: 'Rendah (2)',
        besaranRisiko: '6 - 11',
        warna: 'Hijau',
        classWarna: 'bg-[#669f2a]', 
    },
    {
        levelRisiko: 'Sangat Rendah (1)',
        besaranRisiko: '1 - 5',
        warna: 'Biru',
        classWarna: 'bg-[#06aed4]', 
    },
  ];

  const columns = [
    {
      title: "Level Risiko",
      dataIndex: "levelRisiko",
      key: "levelRisiko",
      align: "center",
      className: "font-semibold",
    },
    {
      title: "Besaran Risiko",
      dataIndex: "besaranRisiko",
      key: "besaranRisiko",
      align: "center",
      className: "font-semibold",

    },
    {
      title: "Warna",
      dataIndex: "warna",
      key: "warna",
      align: "center",
      className: "font-semibold",
      render: (val, record) => {
        return {
          props: {
            className: record.classWarna,
          },
          children: 
          <div className="">
            {val}
          </div>,
        };
      },
    },
  ];

  return (
    <>
      <div className="flex items-center py-5 flex-wrap">
        <Space>
          <Icon component={HomeLined} className="mr-2" />
          <RightOutlined className="mr-3 text-xs right-cursor" />
        </Space>
        <div>
          <p className="font-medium" style={{ color: "#475467" }}>
            Master Data
          </p>
        </div>
        <Space>
          <RightOutlined className="mx-3 text-xs right-cursor" />
        </Space>
        <div>
          <p className="font-medium" style={{ color: "#01A24A" }}>
            Besaran Risiko
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-medium">Data Besaran Risiko</h1>
      </div>

      <div
        className="table-list rounded-xl mb-5 max-w-screen-md m-auto"
        style={{ border: "1px solid #EAECF0" }}
      >
        <div>
            <Table
              columns={dashboardColumns}
              dataSource={dashboard}
              pagination={false}
              className="p-3 rounded-lg"
              style={{ overflowX: "auto" }}
            />
        </div>
      </div>

      <div
        className="table-list max-w-screen-md m-auto"
        style={{ border: "1px solid #EAECF0", borderRadius: "12px" }}
      >
        <div>
          {/* <Spin spinning={loading}> */}
            <Table
              columns={columns}
              dataSource={data}
              pagination={false}
              className="p-3"
              style={{ overflowX: "auto" }}
              // onChange={onSearch}
            />
            {/* <div className="flex table-paginator-wrapper my-3 px-2 justify-between">
                            <div className="flex flex-row justify-content-between mb-2 px-2 justify-between items-center">
                                <div className="show align-self-center pr-2 font-medium text-sm">
                                    {'Tampilkan Data'}
                                </div>
                                <div className="dropdown-paging align-self-center pr-2">
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                        <Select
                                            className="select-paging-ppd"
                                            style={{ width: "60px" }}
                                            options={[
                                                { value: 5, label: 5 },
                                                { value: 10, label: 10 },
                                                { value: 30, label: 30 },
                                                { value: 50, label: 50 },
                                                { value: 100, label: 100 },
                                            ]}
                                        />
                                    </Space>
                                </div>
                                <div className="border-paging align-self-center pr-2"></div>
                                <div className="total align-self-center pr-2">
                                    {
                                        // `${dataDonor.slice(0, 1).length} - ${dataDonor.length} data dari ${paginator?.itemCount} data`
                                    }
                                </div>
                            </div>
                            <div>
                                <Pagination />
                            </div>
                        </div> */}
          {/* </Spin> */}
        </div>
      </div>
    </>
  );
};

export default RiskSize;
