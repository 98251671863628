import { Avatar, Dropdown, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import Button from "components/atoms/Button/button";
import { NavLink } from "react-router-dom";

const Header = () => {
  const [open, setOpen] = useState(false);
  const items = [
    {
      key: "1",
      label: (
        <button onClick={() => setOpen(true)} className="px-2 font-semibold">
          Logout
        </button>
      ),
    },
  ];

  const { user } = useSelector(({ auth }) => auth);


  const onChangeRole = (role) => {
    localStorage.setItem("role", role);

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <>
      <nav className="shadow border-b top-0 fixed bg-white w-[calc(100vw-295px)] z-[40] items-center flex px-2 pe-4 justify-between">
        <div className="flex p-2">
          <NavLink
            to={
              process.env.REACT_APP_PORTAL_URL ??
              "https://identity.bpldh-dev.groot.id/portal/bpdlh"
            }
          >
            <Button label="Portal Menu E-Office" />
          </NavLink>
        </div>
        <div className="flex p-2">
          <Dropdown menu={{ items }} placement="bottomLeft" trigger={["click"]}>
            <div className="flex items-center gap-2 cursor-pointer">
              {user.UrlFoto &&
              user.UrlFoto !== "-" &&
              !isEmpty(user.UrlFoto) ? (
                <img
                  className="rounded-full w-8 h-8"
                  src={user?.UrlFoto}
                  alt=""
                />
              ) : (
                <Avatar
                  size={{
                    xs: 24,
                    sm: 32,
                    md: 40,
                    lg: 50,
                    xl: 50,
                    xxl: 50,
                  }}
                  icon={<UserOutlined />}
                />
              )}
              <div className="font-semibold text-[16px] text-black">
                {user?.Name}
                <br />
                <span className="text-sm font-normal">
                  {user?.Divisi.replace("Divisi ", "")}
                </span>
              </div>
            </div>
          </Dropdown>
        </div>
      </nav>
      <Modal
        title="Logout"
        open={open}
        onOk={() => window.location.href = '/logout'}
        onCancel={() => setOpen(false)}
      >
        <p>{"Apakah anda yakin akan keluar dari akun ini ?"}</p>
      </Modal>
    </>
  );
};

export default Header;
