/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo } from "react";
import {
  Space,
  Table,
  // Input,
  Spin,
  Pagination,
  Select,
} from "antd";
import { NavLink } from "react-router-dom";
import Icon, {
  EyeOutlined,
  RightOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { HomeLined } from "components/atoms/SVG";
import { useRiskHistory } from "hooks/useRiskHistory";

const RiskHistoryDetail = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [keyword, setKeyword] = useState("");

  const riskHistory = useRiskHistory([
    "fetchRiskHistory",
    {
      Length: pageSize,
      Start: (currentPage - 1) * pageSize,
      Keyword: keyword,
    },
  ]);

  const totalRecords = useMemo(
    () => riskHistory?.data?.payload?.recordsTotal ?? 0,
    [riskHistory]
  );

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = useMemo(() => {
    const defaultColumns = [
      {
        title: "No",
        key: "number",
        width: "68px",
        height: "72px",
        render: (val, text, data) => {
          return <p>{data + 1}</p>;
        },
      },
      {
        title: "Riwayat Management Resiko",
        dataIndex: "name",
        key: "name",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val || ""}</p>;
        },
      },
      {
        title: "Tahun",
        dataIndex: "year",
        key: "year",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val || ""}</p>;
        },
      },
      {
        title: "Action",
        key: "action",
        width: "15%",
        render: (val) => (
          <Space size="middle">
            <Space>
              <NavLink to={`${val.year}?name=${val.name}`}>
                <span className="mr-3 ml-3">
                  {<EyeOutlined style={{ fontSize: "18px" }} />}
                </span>
              </NavLink>
              {/* <NavLink to={`${val.year}?name=${val.name}`}>
                <span className="mr-3 ml-3">
                  {<DownloadOutlined style={{ fontSize: "18px" }} />}
                </span>
              </NavLink> */}
            </Space>
          </Space>
        ),
      },
    ];
    return defaultColumns;
  }, []);

  return (
    <>
      <div className="flex items-center py-4 flex-wrap">
        <Space>
          <Icon component={HomeLined} className="mr-2" />
          <RightOutlined className="mr-3 text-xs right-cursor" />
        </Space>
        <div>
          <p className="font-medium" style={{ color: "#475467" }}>
            Riwayat Management Resiko
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-medium">List Riwayat Management Resiko</h1>
      </div>
      <div
        className="table-list"
        style={{ border: "1px solid #EAECF0", borderRadius: "12px" }}
      >
        <div className="flex gap-4 py-6 px-6">
          <div className="space-x-5 flex w-full">
            {/* <SearchInput placeholder="Cari..." onChange={onSearch} /> */}
          </div>
        </div>
        <div>
          <Spin spinning={riskHistory.isLoading}>
            <Table
              columns={columns}
              dataSource={riskHistory?.data?.payload?.data}
              pagination={false}
              className="px-5"
              style={{ overflowX: "auto" }}
            />
          </Spin>
          <div className="flex table-paginator-wrapper my-3 px-2 justify-between">
            <div className="flex flex-row justify-content-between mb-2 px-2 justify-between items-center">
              <div className="show align-self-center pr-2 font-medium text-sm">
                {"Tampilkan Data"}
              </div>
              <div className="dropdown-paging align-self-center pr-2">
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Select
                    className="select-paging-ppd"
                    style={{ width: "60px" }}
                    options={[
                      { value: 5, label: 5 },
                      { value: 10, label: 10 },
                      { value: 30, label: 30 },
                      { value: 50, label: 50 },
                      { value: 100, label: 100 },
                    ]}
                    onChange={handlePageSizeChange}
                    value={pageSize}
                  />
                </Space>
              </div>
              <div className="border-paging align-self-center pr-2"></div>
              <div className="total align-self-center pr-2">
                {`Showing ${Math.min(
                  (currentPage - 1) * pageSize + 1,
                  totalRecords
                )} to ${Math.min(
                  currentPage * pageSize,
                  totalRecords
                )} of ${totalRecords} entries`}
              </div>
            </div>
            <div>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalRecords}
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RiskHistoryDetail;
