/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import {
  Space,
  Table,
  Button,
  // Input,
  Spin,
  Pagination,
  Select,
  Tag,
  notification,
} from "antd";
import { NavLink } from "react-router-dom";
import Icon, {
  PlusCircleOutlined,
  CloudDownloadOutlined,
  EyeOutlined,
  EditOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { BASE_URL_MANRISK } from "services/config";
import { HomeLined } from "components/atoms/SVG";
import { debounce, filter, toLower } from "lodash";
import SearchInput from "components/molecules/SearchInput";
import axiosInstance from "app/interceptors";
import mime from "mime";
import moment from "moment";

const MitigationPlan = () => {
  const userRole = localStorage.getItem("role");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [keyword, setKeyword] = useState("");

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const onSearch = debounce((e) => {
    setKeyword(toLower(e.target.value));
  }, 1000);

  const handleDownload = async (id) => {
    try {
      const response = await axiosInstance.get(`${BASE_URL_MANRISK}/risk-mitigation/download-risk-mitigation/${id}`, {
        responseType: 'blob',
      });
      
      const ext = mime.getExtension(response.data.type) === 'bin' ? 'pdf' : mime.getExtension(response.data.type); 
      if (ext !== 'json'){
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const filename = `${moment(Date.now()).format("YYYYMMDDkkmmss")}-rencana-mitigasi.${ext}`;
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }else{
        const errorMsg = JSON.parse(await response.data.text());
        notification.error({
          message: "Kesalahan",
          description: errorMsg.message,
        });
      }
      
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let path = `${BASE_URL_MANRISK}/risk-mitigation/datatable`;

        const response = await axiosInstance.get(path, {
          params: {
            Length: pageSize,
            Start: (currentPage - 1) * pageSize,
            keyword: keyword,
          },
        });

        if (response.data.isSuccess) {
          setData(response.data.payload.data);
          setTotalRecords(response.data.payload.recordsTotal);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage, pageSize, keyword]);

  const renderData = data.slice(0, pageSize);

  const columns = useMemo(() => {
    const defaultColumns = [
      // {
      //   title: "No",
      //   key: "number",
      //   width: "68px",
      //   height: "72px",
      //   render: (val, text, data) => {
      //     return <p>{data + 1}</p>;
      //   },
      // },
      {
        title: "Nomor Resiko",
        dataIndex: "riskNumber",
        key: "riskNumber",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val || '-'}</p>;
        },
      },
      {
        title: "Kejadian Resiko",
        dataIndex: "riskEvent",
        key: "riskEvent",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val || ""}</p>;
        },
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        sortDirections: ["descend", "ascend"],
        width: "15%",
        render: (status) => {
          let color;
          let desc;
          switch (status) {
            case "Selesai":
              color = "success";
              desc = status;
              break;
            case "Sedang Diproses":
              color = "warning";
              desc = "Sedang Diproses";
              break;
            case "BelumMulai":
              color = "default";
              desc = "Belum Mulai";
              break;
            default:
              color = "default";
              desc = status;
          }

          return (
            <Tag color={color}>
              <span className="font-semibold">{desc}</span>
            </Tag>
          );
        },
      },
      {
        title: "Action",
        key: "action",
        width: "15%",
        render: (val) => (
          <Space size="middle">
            <Space>
              {val.status === "BelumMulai" ? (
                <NavLink to={`create/${val.idRiskMitigation}`}>
                  <span className="mr-3 ml-3">
                    {<PlusCircleOutlined style={{ fontSize: "18px" }} />}
                  </span>
                </NavLink>
              ) : (
                <>
                  <NavLink to={`${val.idRiskMitigation}`}>
                    <span className="mr-1 ml-1">
                      {<EyeOutlined style={{ fontSize: "18px" }} />}
                    </span>
                  </NavLink>
                  <NavLink to={`create/${val.idRiskMitigation}`}>
                    <span className="mr-1 ml-1">
                      {<EditOutlined style={{ fontSize: "18px" }} />}
                    </span>
                  </NavLink>
                  <NavLink to="#" onClick={() => handleDownload(val.idRiskMitigation)}>
                    <span className="mr-1 ml-1">
                      {<CloudDownloadOutlined style={{ fontSize: "18px" }} />}
                    </span>
                  </NavLink>
                </>
              )}
            </Space>
          </Space>
        ),
      },
    ];
    return defaultColumns;
  }, []);

  return (
    <>
      <div className="flex items-center py-4 flex-wrap">
        <Space>
          <Icon component={HomeLined} className="mr-2" />
          <RightOutlined className="mr-3 text-xs right-cursor" />
        </Space>
        <div>
          <p className="font-medium" style={{ color: "#475467" }}>
            Dokumen Pendukung MR
          </p>
        </div>
        <Space>
          <RightOutlined className="mx-3 text-xs right-cursor" />
        </Space>

        <div>
          <p className="font-medium" style={{ color: "#01A24A" }}>
            Rencana Mitigasi
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-medium">List Rencana Mitigasi</h1>
        <Space>
          {/* <Button
            htmlType="submit"
            icon={<CloudDownloadOutlined />}
            className="btn-default mt-4"
          >
            <span className="ml-2 text-center">Download</span>
          </Button> */}
        </Space>
      </div>
      <div
        className="table-list"
        style={{ border: "1px solid #EAECF0", borderRadius: "12px" }}
      >
        <div className="flex gap-4 py-6 px-6">
          <div className="space-x-5 flex w-full">
            <SearchInput placeholder="Cari..." onChange={onSearch} />
          </div>
        </div>
        <div>
          <Spin spinning={loading}>
            <Table
              columns={columns}
              dataSource={renderData}
              pagination={false}
              className="px-5"
              style={{ overflowX: "auto" }}
            />
          </Spin>
          <div className="flex table-paginator-wrapper my-3 px-2 justify-between">
            <div className="flex flex-row justify-content-between mb-2 px-2 justify-between items-center">
              <div className="show align-self-center pr-2 font-medium text-sm">
                {"Tampilkan Data"}
              </div>
              <div className="dropdown-paging align-self-center pr-2">
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Select
                    className="select-paging-ppd"
                    style={{ width: "60px" }}
                    options={[
                      { value: 5, label: 5 },
                      { value: 10, label: 10 },
                      { value: 30, label: 30 },
                      { value: 50, label: 50 },
                      { value: 100, label: 100 },
                    ]}
                    onChange={handlePageSizeChange}
                    value={pageSize}
                  />
                </Space>
              </div>
              <div className="border-paging align-self-center pr-2"></div>
              <div className="total align-self-center pr-2">
                {`Showing ${Math.min(
                  (currentPage - 1) * pageSize + 1,
                  totalRecords
                )} to ${Math.min(
                  currentPage * pageSize,
                  totalRecords
                )} of ${totalRecords} entries`}
              </div>
            </div>
            <div>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalRecords}
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MitigationPlan;
