import { useQuery } from "@tanstack/react-query";
import {
  fetchRiskHistory,
  fetchRiskHistoryContext,
  fetchRiskHistoryManual,
  fetchRiskHistoryMitigation,
  fetchRiskHistoryProfile,
  fetchRiskHistoryRegister,
} from "services/risk_history";

export const useRiskHistory = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchRiskHistory(params, signal);
    },
    ...options,
  });
};

export const useRiskHistoryDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchRiskHistory(params, signal);
    },
    ...options,
  });
};

export const useRiskHistoryRegister = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchRiskHistoryRegister(params, signal);
    },
    ...options,
  });
};

export const useRiskHistoryContext = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchRiskHistoryContext(params, signal);
    },
    ...options,
  });
};

export const useRiskHistoryProfile = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchRiskHistoryProfile(params, signal);
    },
    ...options,
  });
};

export const useRiskHistoryManual = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchRiskHistoryManual(params, signal);
    },
    ...options,
  });
};

export const useRiskHistoryMitigation = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchRiskHistoryMitigation(params, signal);
    },
    ...options,
  });
};
