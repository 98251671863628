import {
  Button,
  Form,
  Modal,
  Pagination,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  notification,
} from "antd";
import React, { useCallback, useMemo, useState } from "react";
import Icon, {
  RightOutlined,
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { HomeLined } from "components/atoms/SVG";
import { useAgenda } from "hooks/useAgenda";
import CustomModal from "components/molecules/CustomModal";
import { useSelector } from "react-redux";
import TextArea from "antd/es/input/TextArea";
import axiosInstance from "app/interceptors";
import { BASE_URL_MANRISK } from "services/config";
import moment from "moment";

const DiscussionAgenda = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isModalShow, setModalShow] = useState(false);
  const [isReasonModalShow, setReasonModalShow] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  // const directoratCode = useSelector((state) => state.auth.user.DirectoratCode);
  const [form] = Form.useForm();

  const agenda = useAgenda([
    "fetchagenda",
    {
      // IdDirectorat: directoratCode !== "-" ? directoratCode : "",
      Length: pageSize,
      Start: (currentPage - 1) * pageSize,
    },
  ]);

  const totalRecords = useMemo(
    () => agenda?.data?.payload?.recordsTotal ?? 0,
    [agenda]
  );

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const closeModal = useCallback(() => {
    setSelectedData(null);
    setModalShow(false);
    setReasonModalShow(false);
    form.resetFields();
  }, []);

  const columns = useMemo(() => {
    const defaultColumns = [
      {
        title: "No",
        key: "number",
        width: "68px",
        height: "72px",
        render: (val, text, data) => {
          return <p>{data + 1}</p>;
        },
      },
      {
        title: "Nama Divisi",
        dataIndex: "divisionName",
        key: "divisionName",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val || "-"}</p>;
        },
      },
      {
        title: "Jadwal Rapat",
        dataIndex: "date",
        key: "date",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          if (val) {
            const formattedDate = moment(val).format('dddd, DD MMMM YYYY / HH:mm');
            return <p>{formattedDate}</p>;
          }
          return <p>-</p>;
        },
      },
      {
        title: "Status Kehadiran",
        dataIndex: "status",
        key: "status",
        sortDirections: ["descend", "ascend"],
        render: (status, text, data) => {
          let color, label;
          switch (status) {
            case "Accept":
              color = "success";
              label = "Disetujui";
              break;
            case "Reject":
              color = "error";
              label = "Ditolak";
              break;
            default:
              color = "default";
          }

          return (
            <Tag color={color}>
              <span className="font-semibold">{label ?? "-"}</span>
            </Tag>
          );
        },
      },
      {
        title: "Action",
        key: "action",
        render: (val) =>
          !val.status && (
            <Space size="middle">
              <Button
                type="primary"
                onClick={() => {
                  setModalShow(true);
                  setSelectedData(val);
                }}
                icon={<CheckOutlined />}
              >
                Setujui
              </Button>
              <Button
                type="default"
                danger={true}
                onClick={() => {
                  setReasonModalShow(true);
                  setSelectedData(val);
                  form.resetFields();
                }}
                icon={<CloseOutlined />}
              >
                Tolak
              </Button>
            </Space>
          ),
      },
    ];
    return defaultColumns;
  }, []);

  const handleModalClick = async (values) => {
    try {
      const response = await axiosInstance.patch(
        `${BASE_URL_MANRISK}/meeting-schedule/approval`,
        {
          id: selectedData?.id,
          status: values?.status,
          reason: values?.reason ?? "",
        }
      );

      if (response?.data?.isSuccess) {
        let msg = "Agenda pembahasan telah ditolak";
        if (values?.status === 1) {
          msg = "Agenda pembahasan telah disetujui";
        }
        notification.success({
          message: response.data.message,
          description: msg,
        });
        // setFetchTrigger(!fetchTrigger);
      } else {
        notification.error({
          message: "Kesalahan",
          description: response.data.message,
        });
      }
    } catch (error) {
      notification.error({
        message: "Kesalahan",
        description: error.message,
      });
    } finally {
      setSelectedData(null);
      setModalShow(false);
      setReasonModalShow(false);
      agenda.refetch();
    }
  };

  return (
    <>
      <div className="flex items-center py-4 flex-wrap">
        <Space>
          <Icon component={HomeLined} className="mr-2" />
          <RightOutlined className="mr-3 text-xs right-cursor" />
        </Space>
        <div>
          <p className="font-medium" style={{ color: "#475467" }}>
            Daftar Agenda Pembahasan
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-medium">Daftar Agenda Pembahasan</h1>
      </div>

      <div className="border border-gray-200 rounded-lg p-3 w-full">
        {/* <div className="flex gap-4 pb-3">
          <div className="space-x-5 flex w-full">
            <SearchInput placeholder="Cari..." onChange={() => {}} />
          </div>
        </div> */}
        <>
          <Spin spinning={agenda.isLoading}>
            <Table
              columns={columns}
              dataSource={agenda?.data?.payload?.data}
              pagination={false}
              style={{ overflowX: "auto" }}
            />
          </Spin>
          <div className="flex table-paginator-wrapper my-3 px-2 justify-between">
            <div className="flex flex-row justify-content-between mb-2 px-2 justify-between items-center">
              <div className="show align-self-center pr-2 font-medium text-sm">
                {"Tampilkan Data"}
              </div>
              <div className="dropdown-paging align-self-center pr-2">
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Select
                    className="select-paging-ppd"
                    style={{ width: "60px" }}
                    options={[
                      { value: 5, label: 5 },
                      { value: 10, label: 10 },
                      { value: 30, label: 30 },
                      { value: 50, label: 50 },
                      { value: 100, label: 100 },
                    ]}
                    onChange={handlePageSizeChange}
                    value={pageSize}
                  />
                </Space>
              </div>
              <div className="border-paging align-self-center pr-2"></div>
              <div className="total align-self-center pr-2">
                {`Showing ${Math.min(
                  (currentPage - 1) * pageSize + 1,
                  totalRecords
                )} to ${Math.min(
                  currentPage * pageSize,
                  totalRecords
                )} of ${totalRecords} entries`}
              </div>
            </div>
            <div>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalRecords}
                onChange={handlePageChange}
              />
            </div>
          </div>
        </>
      </div>
      <Modal
        title="Konfirmasi Agenda Pembahasan"
        open={isModalShow}
        onOk={() => {
          handleModalClick({ status: 1 });
        }}
        onCancel={closeModal}
        closable={false}
      >
        <p>{"Apakah anda yakin akan menerima agenda pembahasan ini ?"}</p>
      </Modal>
      <Modal
        open={isReasonModalShow}
        onClose={closeModal}
        onOk={() => {
          form.submit();
        }}
        onCancel={closeModal}
        title="Masukkan alasan penolakan untuk agenda ini"
      >
        <Form
          name="form-tolak-agenda"
          form={form}
          onFinish={handleModalClick}
          initialValues={{
            id: selectedData?.id,
            status: 0,
            reason: null,
          }}
        >
          <Form.Item noStyle name={"id"} />
          <Form.Item noStyle name={"status"} />
          <Form.Item
            name={"reason"}
            rules={[{ required: true, message: "Wajib Diisi" }]}
          >
            <TextArea
              placeholder="Agenda pembahasan ini ditolak karena..."
              type="text"
              className="form-input mt-2"
              rows={5}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default DiscussionAgenda;
