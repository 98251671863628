import { useMutation, useQuery } from "@tanstack/react-query";
import {
  fetchAgenda,
  fetchAgendaById,
  postAgenda,
  updateAgenda,
} from "services/agenda";

export const useAgenda = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchAgenda(params, signal);
    },
    ...options,
  });
};

export const useAgendaById = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const id = queryKey[1];
      return fetchAgendaById(id, signal);
    },
    ...options,
  });
};

export const useStoreAgenda = (options) => {
  return useMutation({
    mutationFn: (params) => postAgenda(params),
    ...options,
  });
};

export const useUpdateAgenda = (options) => {
  return useMutation({
    mutationFn: (params) => updateAgenda(params),
    ...options,
  });
};
