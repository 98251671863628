import React from "react";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";

const customStyles = {
  overlay: {
    position: "fixed",
    // inset: "0px",
    backgroundColor: "rgba(0,0,0, 0.75)",
    zIndex: 99,
  },
  content: {
    // top: "50%",
    // left: "50%",
    // right: "auto",
    // bottom: "auto",
    // marginRight: "-50%",
    // transform: "translate(-50%, -50%)",
    borderRadius: 12,
    paddingTop: 16,
    paddingBottom: 0,
    paddingLeft: 16,
    paddingRight: 16,
    // width: "100%",
    maxWidth: "42rem",
    margin: "auto",
    inset: "1rem",
    height: "fit-content",
    maxHeight: "97vh",
  },
};

Modal.setAppElement('#root');

const CustomModal = ({
  isOpen = false,
  onClose,
  title = "",
  showCloseBtn = false,
  children,
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <div>
        <div className="flex space-x-4 mb-6">
          <div className="text-lg font-semibold text-gray-900 flex-1">
            {title}
          </div>
          {showCloseBtn && (
            <IoIosClose
              className="text-gray-500 cursor-pointer"
              size={30}
              onClick={onClose}
            />
          )}
        </div>
        {children}
      </div>
    </Modal>
  );
};

export default CustomModal;
