/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Space,
  Table,
  Button,
  // Input,
  Spin,
  Pagination,
  Select,
  Tag,
  notification,
} from "antd";
import { NavLink } from "react-router-dom";
import Icon, {
  CloudDownloadOutlined,
  // SearchOutlined,
  PlusCircleOutlined,
  EyeOutlined,
  EditOutlined,
  RightOutlined,
} from "@ant-design/icons";
import "../index.scss";
import { BASE_URL_MANRISK } from "services/config";
import { HomeLined } from "components/atoms/SVG";
import axiosInstance from "app/interceptors";
import SearchInput from "components/molecules/SearchInput";
import { debounce, toLower } from "lodash";
import mime from 'mime';
import moment from "moment";

const RiskProfileList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const userRole = localStorage.getItem("role");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [keyword, setKeyword] = useState("");

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const onSearch = debounce((e) => {
    setKeyword(toLower(e.target.value));
  }, 1000);

  const handleDownload = async () => {
    try {
      const response = await axiosInstance.get(`${BASE_URL_MANRISK}/risk-profile-admin/download`, {
        responseType: 'blob',
      });
      
      const ext = mime.getExtension(response.data.type) === 'bin' ? 'pdf' : mime.getExtension(response.data.type); 
      if (ext !== 'json'){
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const filename = `${moment(Date.now()).format("YYYYMMDDkkmmss")}-profile-resiko.${ext}`;
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }else{
        const errorMsg = JSON.parse(await response.data.text());
        notification.error({
          message: "Kesalahan",
          description: errorMsg.message,
        });
      }
      
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let path = "";

        path = `${BASE_URL_MANRISK}/risk-profile-admin/datatable`;

        const response = await axiosInstance.get(path, {
          params: {
            Keyword: keyword, 
            Length: pageSize,
            Start: (currentPage - 1) * pageSize,
          },
        });

        if (response.data.isSuccess) {
          setData(response.data.payload.data);
          setTotalRecords(response.data.payload.recordsTotal);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userRole, currentPage, pageSize, keyword]);

  const renderData = data.slice(0, pageSize);

  const columns = [
    {
      title: "No",
      key: "number",
      width: "68px",
      height: "72px",
      render: (val, text, data) => {
        return <p>{data + 1}</p>;
      },
    },
    {
      title: "Sasaran Organisasi",
      dataIndex: "organizationTargetName",
      key: "organizationTargetName",
    },
    {
      title: "IKU",
      dataIndex: "iku",
      key: "iku",
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "Divisi",
      dataIndex: "kadivProbisNames",
      key: "kadivProbisNames",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      key: "action",
      render: (val) => (
        <Space size="middle">
          {val.kadivProbisNames?.length === 0 ? (
            <NavLink to={`create-admin/${val.id}`}>
              <span className="mr-3 ml-3">
                {<PlusCircleOutlined style={{ fontSize: "18px" }} />}
              </span>
            </NavLink>
          ) : (
            <NavLink to={`update-admin/${val.id}`}>
              <span className="mr-3 ml-3">
                {<EditOutlined style={{ fontSize: "18px" }} />}
              </span>
            </NavLink>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="flex items-center py-4 flex-wrap">
        <Space>
          <Icon component={HomeLined} className="mr-2" />
          <RightOutlined className="mr-3 text-xs right-cursor" />
        </Space>
        <div>
          <p className="font-medium" style={{ color: "#475467" }}>
            Dokumen Pendukung MR
          </p>
        </div>
        <Space>
          <RightOutlined className="mx-3 text-xs right-cursor" />
        </Space>

        <div>
          <p className="font-medium" style={{ color: "#01A24A" }}>
            Profile Risiko - Direktur
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-medium">List Profile Risiko - Direktur</h1>
        <Space>
          <Button
            htmlType="submit"
            icon={<CloudDownloadOutlined />}
            className="btn-default mt-4"
            onClick={handleDownload}
          >
            <span className="ml-2 text-center">Download</span>
          </Button>
        </Space>
      </div>
      <div
        className="table-list"
        style={{ border: "1px solid #EAECF0", borderRadius: "12px" }}
      >
        <div className="flex gap-4 py-6 px-6">
          <div className="space-x-5 flex w-full">
            <SearchInput placeholder="Cari sasaran organisasi..." onChange={onSearch} />
          </div>
        </div>
        <div>
          <Spin spinning={loading}>
            <Table
              columns={columns}
              dataSource={renderData}
              pagination={false}
              className="px-5"
              style={{ overflowX: "auto" }}
            />
          </Spin>
          <div className="flex table-paginator-wrapper my-3 px-2 justify-between">
            <div className="flex flex-row justify-content-between mb-2 px-2 justify-between items-center">
              <div className="show align-self-center pr-2 font-medium text-sm">
                {"Tampilkan Data"}
              </div>
              <div className="dropdown-paging align-self-center pr-2">
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Select
                    className="select-paging-ppd"
                    style={{ width: "60px" }}
                    options={[
                      { value: 5, label: 5 },
                      { value: 10, label: 10 },
                      { value: 30, label: 30 },
                      { value: 50, label: 50 },
                      { value: 100, label: 100 },
                    ]}
                    onChange={handlePageSizeChange}
                    value={pageSize}
                  />
                </Space>
              </div>
              <div className="border-paging align-self-center pr-2"></div>
              <div className="total align-self-center pr-2">
                {`Showing ${Math.min(
                  (currentPage - 1) * pageSize + 1,
                  totalRecords
                )} to ${Math.min(
                  currentPage * pageSize,
                  totalRecords
                )} of ${totalRecords} entries`}
              </div>
            </div>
            <div>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalRecords}
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RiskProfileList;
