import React, { useEffect, useState, useMemo } from "react";
import {
  Form,
  Button,
  Space,
  Row,
  Col,
  Typography,
  notification,
  Radio,
  Tabs,
  InputNumber,
  Collapse,
} from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  SaveOutlined,
} from "@ant-design/icons";

import "./../Create/index.scss";
import { NavLink, useParams } from "react-router-dom";
import Icon, { RightOutlined } from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";

import { useNavigate } from "react-router-dom";
import { BASE_URL_MANRISK } from "services/config";
import { Input } from "components";
import TextArea from "antd/es/input/TextArea";
import { each, flatMapDeep, flatten, isEmpty, map } from "lodash";
import { useStoreManualIru } from "hooks/useManualiru";
import axiosInstance from "app/interceptors";

const HomeLine = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.66667 13.1667H12.3333M8.18141 1.30333L2.52949 5.69927C2.15168 5.99312 1.96278 6.14005 1.82669 6.32405C1.70614 6.48704 1.61633 6.67065 1.56169 6.86588C1.5 7.08627 1.5 7.32558 1.5 7.80421V13.8333C1.5 14.7667 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1585 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1585 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7667 16.5 13.8333V7.80421C16.5 7.32558 16.5 7.08627 16.4383 6.86588C16.3837 6.67065 16.2939 6.48704 16.1733 6.32405C16.0372 6.14005 15.8483 5.99312 15.4705 5.69927L9.81859 1.30333C9.52582 1.07562 9.37943 0.961766 9.21779 0.918001C9.07516 0.879384 8.92484 0.879384 8.78221 0.918001C8.62057 0.961766 8.47418 1.07562 8.18141 1.30333Z"
      stroke="#667085"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DetailManualIru = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const requiredField = [{ required: true, message: "Wajib Diisi" }];
  const nonRequiredField = [{ required: false }];

  const [item, setItem] = useState({});
  // const [division, setDivision] = useState({})
  // const [position, setPosition] = useState([])
  const [nameDivision, setNameDivision] = useState("");
  const [namePosition, setNamePosition] = useState("");
  const [selectedButton, setSelectedButton] = useState("");

  // manual iru mutation
  const postManualIru = useStoreManualIru({
    // error handling
    onError: (error) => {
      notification.error({
        message: "Kesalahan",
        description: error?.message,
      });
    },
    // success handling
    onSuccess: (data) => {
      if (data.isSuccess) {
        notification.success({
          message: data?.message,
          description: "Data telah disimpan",
        });

        navigate("/manual-iru-staff");
      } else {
        notification.error({
          message: data?.message ?? "Kesalahan",
          description: data?.innerMessage ?? data?.message,
        });
      }
    },
  });

  const defaultValueQuarterItem = useMemo(
    () => ({
      y: [
        {
          id: null,
          idManualIruItem: item?.payload?.id,
          quarter: 0,
          quarterY: 0,
          bm: 0,
          ba: 0,
          bb: 0,
          actual: 0,
        },
        {
          id: null,
          idManualIruItem: null,
          quarter: 1,
          quarterY: 0,
          bm: 0,
          ba: 0,
          bb: 0,
          actual: 0,
        },
        {
          id: null,
          idManualIruItem: null,
          quarter: 2,
          quarterY: 0,
          bm: 0,
          ba: 0,
          bb: 0,
          actual: 0,
        },
        {
          id: null,
          idManualIruItem: null,
          quarter: 3,
          quarterY: 0,
          bm: 0,
          ba: 0,
          bb: 0,
          actual: 0,
        },
      ],
      y1: [
        {
          id: null,
          idManualIruItem: null,
          quarter: 0,
          quarterY: 1,
          bm: 0,
          ba: 0,
          bb: 0,
          actual: 0,
        },
        {
          id: null,
          idManualIruItem: null,
          quarter: 1,
          quarterY: 1,
          bm: 0,
          ba: 0,
          bb: 0,
          actual: 0,
        },
        {
          id: null,
          idManualIruItem: null,
          quarter: 2,
          quarterY: 1,
          bm: 0,
          ba: 0,
          bb: 0,
          actual: 0,
        },
        {
          id: null,
          idManualIruItem: null,
          quarter: 3,
          quarterY: 1,
          bm: 0,
          ba: 0,
          bb: 0,
          actual: 0,
        },
      ],
      y2: [
        {
          id: null,
          idManualIruItem: null,
          quarter: 0,
          quarterY: 2,
          bm: 0,
          ba: 0,
          bb: 0,
          actual: 0,
        },
        {
          id: null,
          idManualIruItem: null,
          quarter: 1,
          quarterY: 2,
          bm: 0,
          ba: 0,
          bb: 0,
          actual: 0,
        },
        {
          id: null,
          idManualIruItem: null,
          quarter: 2,
          quarterY: 2,
          bm: 0,
          ba: 0,
          bb: 0,
          actual: 0,
        },
        {
          id: null,
          idManualIruItem: null,
          quarter: 3,
          quarterY: 2,
          bm: 0,
          ba: 0,
          bb: 0,
          actual: 0,
        },
      ],
    }),
    [item]
  );

  const TabContent = ({ field, name = [] }) => (
    <Col span={6}>
      <label className="font-medium">{field.toUpperCase()}</label>
      <Form.Item name={name} rules={requiredField}>
        <InputNumber
          placeholder=""
          type="text"
          className="form-input mt-2"
          style={{ width: "100%" }}
          disabled
        />
      </Form.Item>
    </Col>
  );

  useEffect(() => {
    axiosInstance.get(`${BASE_URL_MANRISK}/manual-iru/${id}`)
    .then((response) => response.data)
    .then(data => {

      setItem(data.payload);

      form.setFieldsValue({
        manualIruItems: {
          id: data.payload?.id,
          risk: data.payload?.risk,
          iru: data.payload?.iru,
          descriptionIru: data.payload?.descriptionIru,
          idOrganizationTarget: data.payload?.idOrganizationTarget,
          idKadivProbis: data.payload?.idKadivProbis,
          idRiskCategory: data.payload?.idRiskCategory,
          limitValue: data.payload?.limitValue,
          formula: data.payload?.formula,
          note: data.payload?.note,
          dataSource: data.payload?.dataSource,
          uom: data.payload?.uom,
          periodConsolidationType: data.payload?.periodConsolidationType,
          // locationConsolidationType: data.payload?.locationConsolidationType,
          polarization: data.payload?.polarization,
          reportingPeriod: data.payload?.reportingPeriod,
        },
        manualIruQuarterItems: {
          y: !isEmpty(data.payload?.y)
            ? data.payload?.y
            : defaultValueQuarterItem.y.map((item) => ({
                ...item,
                idManualIruItem: data?.payload?.id,
              })),
          y1: !isEmpty(data.payload?.y1)
            ? data.payload?.y1
            : defaultValueQuarterItem.y1.map((item) => ({
                ...item,
                idManualIruItem: data?.payload?.id,
              })),
          y2: !isEmpty(data.payload?.y2)
            ? data.payload?.y2
            : defaultValueQuarterItem.y2.map((item) => ({
                ...item,
                idManualIruItem: data?.payload?.id,
              })),
        },
      });
    });

  }, [form, id]);

  useEffect(() => {
    if (item && item?.idKadivProbis) {
      axiosInstance.get(`${BASE_URL_MANRISK}/master-data/division/${item?.idKadivProbis}`)
        .then((response) => response.data)
        .then(data => {
          // setDivision(data.payload);
          setNameDivision(data.payload.name);

          form.setFieldsValue({
            manualIruItems: {
              resposibleUnit: data.payload.id,
            },
          });
        });

      axiosInstance.get(
        `${BASE_URL_MANRISK}/master-data/position?idDivision=${item?.idKadivProbis}`
      ).then((response) => response.data)
        .then(data => {
          // setPosition(data.payload);
          setNamePosition(data.payload[0]?.name);

          form.setFieldsValue({
            manualIruItems: {
              dataProvideUnit: data.payload[0]?.id,
            },
          });
        });
      
    }
  }, [form, item]);

  // const onFinish = (values) => {
  //     setLoading(true)

  //     const url = selectedButton === 'draftButton'
  //         ? `${BASE_URL_MANRISK}/risk-profile-probis/draft`
  //         : `${BASE_URL_MANRISK}/risk-profile-probis/complete`

  //     axios
  //         .post(url, values)
  //         .then((response) => {
  //             if (response.data.isSuccess) {
  //                 notification.success({
  //                     message: response.data.message,
  //                     description: "Data telah disimpan"
  //                 })

  //                 navigate('/profil-risiko')
  //             } else {
  //                 notification.error({
  //                     message: "Kesalahan",
  //                     description: response.data.message
  //                 })
  //             }
  //         })
  //         .catch((err) => {
  //             notification.error({
  //                 message: "Kesalahan",
  //                 description: err.message
  //             })
  //         })
  //         .finally(() => {
  //             setLoading(false)
  //         })
  // }

  // const SubmitButton = ({ form }) => {
  //     const [submittable, setSubmittable] = React.useState(false)

  //     const values = Form.useWatch([], form)
  //     useEffect(() => {
  //         form
  //             .validateFields({
  //                 validateOnly: true,
  //             })
  //             .then(
  //                 () => {
  //                     setSubmittable(true)
  //                 },
  //                 () => {
  //                     setSubmittable(false)
  //                 },
  //             )
  //     }, [values, form])

  //     const completeSubmit = () => {
  //         setSelectedButton('completeButton')
  //         form.submit()
  //     }

  //     return (
  //         <Button
  //             className="btn-submit mt-4 ml-4"
  //             onClick={completeSubmit}
  //             loading={loading}
  //             disabled={!submittable}
  //         >
  //             <span className="ml-2 text-center">Simpan</span>
  //             <Icon component={ArrowRightOutlined} className="mr-2" style={{ fontSize: 15 }} />
  //         </Button>
  //     )
  // }

  // const DraftButton = ({ form }) => {

  //     const values = Form.useWatch([], form)
  //     useEffect(() => {
  //         form
  //             .validateFields({
  //                 validateOnly: true,
  //             })
  //     }, [values, form])

  //     const draftSubmit = () => {
  //         setSelectedButton('draftButton')
  //         form.submit()
  //     }

  //     return (
  //         <Button
  //             icon={<SaveOutlined />}
  //             className="btn-back"
  //             onClick={draftSubmit}
  //             loading={loading}
  //         >
  //             <span className="ml-2 text-center">Simpan & Draft</span>
  //         </Button>
  //     )
  // }

  const onFinish = (values, type = "complete") => {
    // map each manual iru items to an array using flatMapDeep
    const mapQuarterItems = map(
      flatMapDeep(values?.manualIruQuarterItems),
      (item) => flatMapDeep(item)
    );
    // merge results of quarterItems into an array
    const quarterItemsResult = flatten(mapQuarterItems);
    values.manualIruItems.manualIruQuarterItems = quarterItemsResult;
    // remove the raw manualIruQuarterItems from values
    delete values["manualIruQuarterItems"];

    postManualIru.mutate({
      type,
      params: values,
    });
  };

  const SubmitButton = () => (
    <Button
      className="btn-submit mt-4 ml-4"
      onClick={() => {
        form.submit();
      }}
      loading={postManualIru.status === "pending"}
    >
      <span className="ml-2 text-center">Simpan</span>
      <Icon
        component={ArrowRightOutlined}
        className="mr-2"
        style={{ fontSize: 15 }}
      />
    </Button>
  );

  const onSaveDraft = async () => {
    try {
      const values = await form.getFieldsValue();
      onFinish(values, "draft");
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Error",
        description: "Failed to save draft, please try again later.",
      });
    }
  };

  const DraftButton = () => (
    <Button
      icon={<SaveOutlined />}
      className="btn-back"
      onClick={onSaveDraft}
      loading={postManualIru.status === "pending"}
    >
      <span className="ml-2 text-center">Simpan & Draft</span>
    </Button>
  );

  const plainOptions = [
    { label: "Bulanan", value: 0 },
    { label: "Triwulan", value: 1 },
    { label: "Semesteran", value: 2 },
    { label: "Tahunan", value: 3 },
  ];
  return (
    <>
      <div className="px-4">
        <Form
          name="form-update-probis"
          form={form}
          onFinish={onFinish}
          initialValues={{
            manualIruQuarterItems: defaultValueQuarterItem,
          }}
        >
          <div className="flex items-center py-5 flex-wrap">
            <Space>
              <Icon component={HomeLine} className="mr-2" />
              <RightOutlined className="mr-3 text-xs right-cursor" />
            </Space>
            <div>
              <p className="font-medium mr-3">Dokumen Pendukung MR</p>
            </div>
            <Space>
              <RightOutlined className="mr-3 text-xs right-cursor" />
            </Space>
            <div>
              <p className="font-medium mr-3">Manual IRU</p>
            </div>

            <Space>
              <RightOutlined className="mr-3 text-xs right-cursor" />
            </Space>
            <div>
              <p className="font-medium" style={{ color: "#01A24A" }}>
                Detail Data Manual IRU
              </p>
            </div>
          </div>

          <div className="py-5">
            <p className="text-2xl font-medium">
              Formulir Manual Indikator Risiko Utama (IRU)
            </p>
          </div>

          <div
            className="w-100 overflow-hidden mb-24"
            style={{
              borderRadius: "8px",
              border: "1px solid #EAECF0",
              background: "#FFFF",
            }}
          >
            <div className="">
              <div
                className=""
                style={{ background: "#00BD52", height: "10px" }}
              ></div>

              <div
                className="m-5 w-100 overflow-hidden mb-6"
                style={{
                  borderRadius: "8px",
                  border: "1px solid #EAECF0",
                  background: "#FFFF",
                }}
              >
                <div className="">
                  <div
                    className=""
                    style={{ background: "#00BD52", height: "10px" }}
                  ></div>
                </div>

                <div className="row px-5 pb-3 mt-4">
                  <label className="field-required font-medium">
                    Sasaran Organisasi
                  </label>
                  <Form.Item name={["manualIruItems", "id"]} noStyle />
                  <Form.Item name={["manualIruItems", "risk"]} noStyle />
                  <Form.Item name={["manualIruItems", "iru"]} noStyle />
                  <Form.Item
                    name={["manualIruItems", "idOrganizationTarget"]}
                    noStyle
                  />
                  <Form.Item
                    name={["manualIruItems", "idKadivProbis"]}
                    noStyle
                  />
                  {/* <Form.Item
                                        name={["manualIruItems", "idRiskCategory"]}
                                        noStyle
                                    /> */}

                  <Input
                    placeholder="Sasaran Organisasi"
                    type="text"
                    className="form-input mt-2"
                    style={{ width: "100%" }}
                    disabled="true"
                    value={item?.organizationTarget}
                  />
                </div>
                <div className="px-5 pb-3">
                  <label className="field-required font-medium">
                    Kejadian Risiko
                  </label>

                  <Input
                    placeholder="Kejadian Risiko"
                    type="text"
                    className="form-input mt-2"
                    style={{ width: "100%" }}
                    disabled="true"
                    value={item?.risk}
                  />
                </div>

                <div className="px-5 pb-3">
                  <label className="field-required font-medium">Nama IRU</label>

                  <Input
                    placeholder="Nama IRU"
                    type="text"
                    className="form-input mt-2"
                    style={{ width: "100%" }}
                    disabled="true"
                    value={item?.iru}
                  />
                </div>
              </div>

              <div
                className="m-5 w-100 overflow-hidden mb-6"
                style={{
                  borderRadius: "8px",
                  border: "1px solid #EAECF0",
                  background: "#FFFF",
                }}
              >
                <div className="">
                  <div
                    className=""
                    style={{ background: "#00BD52", height: "10px" }}
                  ></div>
                  <div className="row px-5 mt-2">
                    <Row gutter={24} className="">
                      <Col span={24}>
                        <div className="">
                          <label className="field-required font-medium">
                            Deskripsi IRU
                          </label>
                          <Form.Item
                            name={["manualIruItems", "descriptionIru"]}
                            rules={
                              selectedButton === "draftButton"
                                ? nonRequiredField
                                : requiredField
                            }
                          >
                            <TextArea
                              placeholder="Deskripsi IRU"
                              type="text"
                              className="form-input mt-2"
                              style={{ width: "100%" }}
                              disabled
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              <div
                className="m-5 w-100 overflow-hidden mb-6"
                style={{
                  borderRadius: "8px",
                  border: "1px solid #EAECF0",
                  background: "#FFFF",
                }}
              >
                <div className="">
                  <div
                    className=""
                    style={{ background: "#00BD52", height: "10px" }}
                  ></div>
                  <div className="row px-5 mt-2">
                    <Row gutter={24} className="">
                      <Col span={24}>
                        <div className="">
                          <label className="field-required font-medium">
                            Formula
                          </label>
                          <Form.Item
                            name={["manualIruItems", "formula"]}
                            rules={
                              selectedButton === "draftButton"
                                ? nonRequiredField
                                : requiredField
                            }
                          >
                            <TextArea
                              placeholder="Formula"
                              type="text"
                              className="form-input mt-2"
                              style={{ width: "100%" }}
                              disabled
                            />
                          </Form.Item>
                        </div>
                      </Col>

                      <Col span={24}>
                        <div className="">
                          <label className="field-required font-medium">
                            Satuan Pengukuran
                          </label>
                          <Form.Item
                            name={["manualIruItems", "uom"]}
                            rules={
                              selectedButton === "draftButton"
                                ? nonRequiredField
                                : requiredField
                            }
                          >
                            <InputNumber
                              placeholder="Satuan Pengukuran"
                              type="text"
                              className="form-input mt-2"
                              style={{ width: "100%" }}
                              disabled
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              <div
                className="m-5 w-100 overflow-hidden mb-6"
                style={{
                  borderRadius: "8px",
                  border: "1px solid #EAECF0",
                  background: "#FFFF",
                }}
              >
                <div className="">
                  <div
                    className=""
                    style={{ background: "#00BD52", height: "10px" }}
                  ></div>
                  <div className="row px-5 mt-2">
                    <Row gutter={24} className="">
                      <Col span={24}>
                        <div className="">
                          <label className="field-required font-medium">
                            Jenis Konsolidasi Periode
                          </label>
                          <hr className="mt-4" />
                          <Form.Item
                            name={["manualIruItems", "periodConsolidationType"]}
                            rules={
                              selectedButton === "draftButton"
                                ? nonRequiredField
                                : requiredField
                            }
                          >
                            <Radio.Group
                              name="radiogroup"
                              initia
                              className="mt-4"
                              disabled

                            >
                              <Space
                                direction="horizontal"
                                className="flex justify-evenly"
                              >
                                <Radio value={0} 
                              >Sum</Radio>
                                <Radio value={1}
                              >Average</Radio>
                                <Radio value={2}
                              >
                                  Take last Known Value (TLK)
                                </Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              {/* <div
                className="m-5 w-100 overflow-hidden mb-6"
                style={{
                  borderRadius: "8px",
                  border: "1px solid #EAECF0",
                  background: "#FFFF",
                }}
              >
                <div className="">
                  <div
                    className=""
                    style={{ background: "#00BD52", height: "10px" }}
                  ></div>
                  <div className="row px-5 mt-2">
                    <Row gutter={24} className="">
                      <Col span={24}>
                        <div className="">
                          <label className="field-required font-medium">
                            Jenis Konsolidasi Lokasi
                          </label>
                          <hr className="mt-4" />
                          <Form.Item
                            name={[
                              "manualIruItems",
                              "locationConsolidationType",
                            ]}
                            rules={
                              selectedButton === "draftButton"
                                ? nonRequiredField
                                : requiredField
                            }
                          >
                            <Radio.Group
                              name="radiogroup"
                              initia
                              className="mt-4"
                              disabled

                            >
                              <Space
                                direction="horizontal"
                                className="flex justify-evenly"
                              >
                                <Radio value={0} 
                              >Maximize</Radio>
                                <Radio value={1}
                              >Minimize</Radio>
                                <Radio value={2}
                              >Stabilize</Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div> */}

              <div
                className="m-5 w-100 overflow-hidden mb-6"
                style={{
                  borderRadius: "8px",
                  border: "1px solid #EAECF0",
                  background: "#FFFF",
                }}
              >
                <div className="">
                  <div
                    className=""
                    style={{ background: "#00BD52", height: "10px" }}
                  ></div>
                  <div className="row px-5 mt-2">
                    <Row gutter={24} className="">
                      <Col span={24}>
                        <div className="">
                          <label className="field-required font-medium">
                            Polarisasi
                          </label>
                          <hr className="mt-4" />
                          <Form.Item
                            name={["manualIruItems", "polarization"]}
                            rules={
                              selectedButton === "draftButton"
                                ? nonRequiredField
                                : requiredField
                            }
                          >
                            <Radio.Group
                              name="radiogroup"
                              initia
                              className="mt-4"
                              disabled
                            >
                              <Space
                                direction="horizontal"
                                className="flex justify-evenly"
                              >
                                <Radio value={0}
                              >Maximize</Radio>
                                <Radio value={1}
                              >Minimize</Radio>
                                <Radio value={2}
                              >Stabilize</Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              <div
                className="m-5 w-100 overflow-hidden mb-6"
                style={{
                  borderRadius: "8px",
                  border: "1px solid #EAECF0",
                  background: "#FFFF",
                }}
              >
                <div className="">
                  <div
                    className=""
                    style={{ background: "#00BD52", height: "10px" }}
                  ></div>
                  <div className="row px-5 pb-3 mt-2">
                    <Row gutter={24} className="">
                      <Col span={12}>
                        <div className="">
                          <label className="field-required font-medium">
                            Unit Penanggung Jawab
                          </label>
                          <Form.Item
                            name={["manualIruItems", "resposibleUnit"]}
                            noStyle
                          />
                          <Input
                            value={nameDivision}
                            placeholder=""
                            disabled={true}
                            type="text"
                            className="form-input mt-2"
                            style={{ width: "100%" }}
                          />
                          {/* </Form.Item> */}
                        </div>
                      </Col>

                      <Col span={12}>
                        <div className="">
                          <label className="field-required font-medium">
                            Unit Penyedia Data
                          </label>
                          <Form.Item
                            name={["manualIruItems", "dataProvideUnit"]}
                            noStyle
                          />
                          <Input
                            value={namePosition}
                            placeholder=""
                            disabled={true}
                            type="text"
                            className="form-input mt-2"
                            style={{ width: "100%" }}
                          />
                          {/* </Form.Item> */}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              <div
                className="m-5 w-100 overflow-hidden mb-6"
                style={{
                  borderRadius: "8px",
                  border: "1px solid #EAECF0",
                  background: "#FFFF",
                }}
              >
                <div className="">
                  <div
                    className=""
                    style={{ background: "#00BD52", height: "10px" }}
                  ></div>
                  <div className="row px-5 mt-2">
                    <Row gutter={24} className="">
                      <Col span={24}>
                        <div className="">
                          <label className="field-required font-medium">
                            Sumber Data
                          </label>
                          <hr className="mt-3" />
                          <Form.Item
                            name={["manualIruItems", "dataSource"]}
                            rules={
                              selectedButton === "draftButton"
                                ? nonRequiredField
                                : requiredField
                            }
                          >
                            {/* <label className="field-required font-medium">Nama Dokumen</label> */}
                            <TextArea
                              placeholder="Sumber Data"
                              type="text"
                              className="form-input mt-4"
                              style={{ width: "100%" }}
                              disabled

                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              <div
                className="m-5 w-100 overflow-hidden mb-6"
                style={{
                  borderRadius: "8px",
                  border: "1px solid #EAECF0",
                  background: "#FFFF",
                }}
              >
                <div className="">
                  <div
                    className=""
                    style={{ background: "#00BD52", height: "10px" }}
                  ></div>
                  <div className="row px-5 mt-2">
                    <Row gutter={24} className="">
                      <Col span={24}>
                        <div className="">
                          <label className="field-required font-medium">
                            Periode Pelaporan
                          </label>
                          <hr className="mt-3" />
                          <Form.Item
                            name={["manualIruItems", "reportingPeriod"]}
                            rules={
                              selectedButton === "draftButton"
                                ? nonRequiredField
                                : requiredField
                            }
                          >
                            {/* <label className="field-required font-medium">Nama Dokumen</label> */}
                            <Radio.Group
                              className="mt-4 primary"
                              options={plainOptions}
                              disabled
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div
                className="m-5 w-100 overflow-hidden mb-6"
                style={{
                  borderRadius: "8px",
                  border: "1px solid #EAECF0",
                  background: "#FFFF",
                }}
              >
                <div>
                  <div className="bg-[#00BD52] h-[10px]" />
                  <div className="row px-5 py-4 mt-2">
                  <div>
                      <div className="pb-3 font-medium">Indikator Triwulan</div>

                      <Collapse
                        defaultActiveKey={[0, 1, 2]}
                        size="small"
                        expandIconPosition="end"
                        bordered
                      >
                        <Collapse.Panel
                          header={"Y"}
                          className="border border-white"
                        >
                          <Form.List name={["manualIruQuarterItems", "y"]}>
                            {(fields) => {
                              return fields.map(({ name }) => (
                                <div>
                                  <div className=" p-3 font-bold text-[#00BD52] rounded border border-gray-400 bg-gray-50">
                                    Triwulan {name + 1}
                                  </div>
                                  <Row gutter={24} className="p-3">
                                    <Form.Item noStyle name={[name, "id"]} />
                                    <Form.Item
                                      noStyle
                                      name={[name, "idManualIruItem"]}
                                    />
                                    <Form.Item
                                      noStyle
                                      name={[name, "quarter"]}
                                    />
                                    <Form.Item
                                      noStyle
                                      name={[name, "quarterY"]}
                                    />
                                    <TabContent
                                      field={"bm"}
                                      name={[name, "bm"]}
                                    />
                                    <TabContent
                                      field={"ba"}
                                      name={[name, "ba"]}
                                    />
                                    <TabContent
                                      field={"bb"}
                                      name={[name, "bb"]}
                                    />
                                    <TabContent
                                      field={"actual"}
                                      name={[name, "actual"]}
                                    />
                                  </Row>
                                </div>
                              ));
                            }}
                          </Form.List>
                        </Collapse.Panel>
                        <Collapse.Panel
                          header={"Y-1"}
                          className="border border-white"
                        >
                          <Form.List name={["manualIruQuarterItems", "y1"]}>
                            {(fields) =>
                              fields.map((field) => (
                                <div>
                                  <div className=" p-3 font-bold text-[#00BD52] rounded border border-gray-400 bg-gray-50">
                                    Triwulan {field.name + 1}
                                  </div>
                                  <Row gutter={24} className="p-3">
                                    <Form.Item
                                      noStyle
                                      name={[field.name, "id"]}
                                    />
                                    <Form.Item
                                      noStyle
                                      name={[field.name, "idManualIruItem"]}
                                    />
                                    <Form.Item
                                      noStyle
                                      name={[field.name, "quarter"]}
                                    />
                                    <Form.Item
                                      noStyle
                                      name={[field.name, "quarterY"]}
                                    />
                                    <TabContent
                                      field={"bm"}
                                      name={[field.name, "bm"]}
                                    />
                                    <TabContent
                                      field={"ba"}
                                      name={[field.name, "ba"]}
                                    />
                                    <TabContent
                                      field={"bb"}
                                      name={[field.name, "bb"]}
                                    />
                                    <TabContent
                                      field={"actual"}
                                      name={[field.name, "actual"]}
                                    />
                                  </Row>
                                </div>
                              ))
                            }
                          </Form.List>
                        </Collapse.Panel>
                        <Collapse.Panel
                          header={"Y-2"}
                          className="border border-white"
                        >
                          <Form.List name={["manualIruQuarterItems", "y2"]}>
                            {(fields) =>
                              fields.map((field) => (
                                <div>
                                  <div className=" p-3 font-bold text-[#00BD52] rounded border border-gray-400 bg-gray-50">
                                    Triwulan {field.name + 1}
                                  </div>
                                  <Row gutter={24} className="p-3">
                                    <Form.Item
                                      noStyle
                                      name={[field.name, "id"]}
                                    />
                                    <Form.Item
                                      noStyle
                                      name={[field.name, "idManualIruItem"]}
                                    />
                                    <Form.Item
                                      noStyle
                                      name={[field.name, "quarter"]}
                                    />
                                    <Form.Item
                                      noStyle
                                      name={[field.name, "quarterY"]}
                                    />
                                    <TabContent
                                      field={"bm"}
                                      name={[field.name, "bm"]}
                                    />
                                    <TabContent
                                      field={"ba"}
                                      name={[field.name, "ba"]}
                                    />
                                    <TabContent
                                      field={"bb"}
                                      name={[field.name, "bb"]}
                                    />
                                    <TabContent
                                      field={"actual"}
                                      name={[field.name, "actual"]}
                                    />
                                  </Row>
                                </div>
                              ))
                            }
                          </Form.List>
                        </Collapse.Panel>
                      </Collapse>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="mt-6 px-4 rounded border border-[#EAECF0] bg-white"
            style={{
              position: "fixed",
              bottom: 0,
              width: "calc(100vw - 400px)",
            }}
          >
            <div className="flex justify-between items-center p-3">
              <Space>
                <NavLink to={"/manual-iru-staff"}>
                  <Button icon={<ArrowLeftOutlined />} className="btn-back">
                    <span className="ml-2 text-center">Kembali</span>
                  </Button>
                </NavLink>
              </Space>
              <div className="row"> 
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default DetailManualIru;
