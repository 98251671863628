import { BASE_URL_MANRISK } from "./config";
import axiosInstance from "app/interceptors";

export const fetchRiskHistory = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/risk-management-history/datatable`,
    { params, signal }
  );
  return data;
};

export const fetchRiskHistoryRegister = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/risk-management-history/risk-register`,
    { params, signal }
  );
  return data;
};

export const fetchRiskHistoryContext = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/risk-management-history/context-formulation`,
    { params, signal }
  );
  return data;
};

export const fetchRiskHistoryProfile = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/risk-management-history/risk-profile`,
    { params, signal }
  );
  return data;
};

export const fetchRiskHistoryManual = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/risk-management-history/manual-iru`,
    { params, signal }
  );
  return data;
};

export const fetchRiskHistoryMitigation = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/risk-management-history/risk-mitigation`,
    { params, signal }
  );
  return data;
};
