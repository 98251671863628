import { BASE_URL_MANRISK } from "./config";
import axiosInstance from "app/interceptors";

export const fetchRiskList = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/risk-register`,
    { params, signal }
  );
  return data;
};
