import { BASE_URL_MANRISK } from "./config";
import axiosInstance from "app/interceptors";

export const fetchDashboard = async (params, signal) => {

  let url = `${BASE_URL_MANRISK}/dashboard/analysis-matrix`;

  if (params.TW !== null) {
    url = `${BASE_URL_MANRISK}/dashboard/analysis-matrix-risk-report`;
  }

  const { data } = await axiosInstance.get(url,
    { signal, params }
  );



  return data;
};