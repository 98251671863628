import React, { useEffect, useMemo, useState } from "react";
import {
  Form,
  Button,
  Space,
  Select,
  DatePicker,
  Skeleton,
  notification,
  Radio,
  Checkbox,
} from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  PlusOutlined,
  SaveOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import "./index.scss";
import { NavLink, useParams } from "react-router-dom";
import Icon, { RightOutlined } from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";

import { useNavigate } from "react-router-dom";
import { Input } from "components";
import TextArea from "antd/es/input/TextArea";
import { CalenderIcon } from "components/atoms/SVG";
import {
  useMitigationById,
  useMitigationOption,
  useStoreMitigation,
} from "hooks/useMitigation";
import { usePosition, usePositionKadiv } from "hooks/useMaster";
import { first, isEmpty, isNil, map, omitBy } from "lodash";
import dayjs from "dayjs";

const HomeLine = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.66667 13.1667H12.3333M8.18141 1.30333L2.52949 5.69927C2.15168 5.99312 1.96278 6.14005 1.82669 6.32405C1.70614 6.48704 1.61633 6.67065 1.56169 6.86588C1.5 7.08627 1.5 7.32558 1.5 7.80421V13.8333C1.5 14.7667 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1585 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1585 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7667 16.5 13.8333V7.80421C16.5 7.32558 16.5 7.08627 16.4383 6.86588C16.3837 6.67065 16.2939 6.48704 16.1733 6.32405C16.0372 6.14005 15.8483 5.99312 15.4705 5.69927L9.81859 1.30333C9.52582 1.07562 9.37943 0.961766 9.21779 0.918001C9.07516 0.879384 8.92484 0.879384 8.78221 0.918001C8.62057 0.961766 8.47418 1.07562 8.18141 1.30333Z"
      stroke="#667085"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const { Option } = Select;
const CreateMitigationPlan = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const requiredField = [{ required: true, message: "Wajib Diisi" }];
  const [removedMitigation, setRemovedMitigation] = useState([]);

  // Fetches mitigation detail by id at first request
  const { data: mitigation, status: mitigation_status } = useMitigationById(
    ["fetchMitigationById", id],
    {
      refetchOnMount: true,
    }
  );

  // Parrarel fetch with mitigation options while fetching mitigation details
  const { data: mitigationOption, status: mitigationOption_status } =
    useMitigationOption(["fetchMitigationOption"], {
      refetchOnMount: true,
    });

  // fetch pic data when mitigation data is available because its related to idKadivProbis
  const { data: pic, status: pic_status } = usePositionKadiv(
    ["fetchPositionKadiv"],
    {
      refetchOnMount: true,
      enabled: !!mitigation?.payload?.idRiskMitigation,
    }
  );

  // mitigation mutation
  const postMitigation = useStoreMitigation({
    // error handling
    onError: (error) => {
      notification.error({
        message: "Kesalahan",
        description: error?.message,
      });
    },
    // success handling
    onSuccess: (data) => {
      if (data.isSuccess) {
        notification.success({
          message: data?.message,
          description: "Data telah disimpan",
        });

        navigate("/rencana-mitigasi-admin");
      } else {
        notification.error({
          message: data?.message ?? "Kesalahan",
          description: data?.innerMessage ?? data?.message,
        });
      }
    },
  });

  const defaultMitigationPlanItem = useMemo(() => {
    return {
      riskMitigationItemPlans: [
        {
          id: null,
          target: null,
          riskMitigationOptionPlan: null,
          output: null,
          obstecle: null,
          requiredResource: null,
          riskMitigationImplementations: null,
          // startImplementationSchedule: null,
          // endImplementationSchedule: null,
          personalInCharge: null,
        },
      ],
    };
  }, []);

  const onFinish = (values, type = "complete") => {
    // removing nil values on object before requesting
    const riskMitigationItemPlans = map(
      values?.riskMitigationItemPlans,
      (item) => omitBy(item, isNil)
    );

    // only take the first array of riskMitigationItemPlans since we dont support it yet
    const riskMitigationItems = first(mitigation?.payload?.riskMitigationItems);
    const params = {
      riskMitigationItem: {
        ...values,
        riskMitigationItemPlans,
        id: riskMitigationItems.id ?? null,
        deleteRiskMitigationItemPlans: removedMitigation,
      },
    };

    postMitigation.mutate({
      type,
      params,
    });
  };

  const SubmitButton = () => (
    <Button
      className="btn-submit mt-4 ml-4"
      onClick={() => {
        form.submit();
      }}
      loading={postMitigation.status === "pending"}
    >
      <span className="ml-2 text-center">Simpan</span>
      <Icon
        component={ArrowRightOutlined}
        className="mr-2"
        style={{ fontSize: 15 }}
      />
    </Button>
  );

  const onSaveDraft = async () => {
    try {
      const values = await form.getFieldsValue();
      onFinish(values, "draft");
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to save draft, please try again later.",
      });
    }
  };

  const DraftButton = () => (
    <Button
      icon={<SaveOutlined />}
      className="btn-back"
      onClick={onSaveDraft}
      loading={postMitigation.status === "pending"}
    >
      <span className="ml-2 text-center">Simpan & Draft</span>
    </Button>
  );

  const jadwalOptions = [
    {
      value: 0,
      label: "TW1",
    },
    {
      value: 1,
      label: "TW2",
    },
    {
      value: 2,
      label: "TW3",
    },
    {
      value: 3,
      label: "TW4",
    },
    {
      value: 4,
      label: "Semester 1",
    },
    {
      value: 5,
      label: "Semester 2",
    },
  ];

  useEffect(() => {
    if (
      mitigation_status === "success" &&
      mitigation &&
      mitigationOption_status === "success"
    ) {
      // only take the first array for now since we dont support multiple yet
      const riskMitigationItems = first(
        mitigation?.payload?.riskMitigationItems
      );

      if (riskMitigationItems?.riskMitigationItemPlans.length > 0) {
        // mutate the dates to have dayjs format (required by ant desgin Datepicker)
        const item_plans = map(
          riskMitigationItems?.riskMitigationItemPlans,
          (value) => {
            if (value.startImplementationSchedule)
              value.startImplementationSchedule = dayjs(
                value.startImplementationSchedule
              );
            if (value.endImplementationSchedule)
              value.endImplementationSchedule = dayjs(
                value.endImplementationSchedule
              );
            return value;
          }
        );

        // set mitigation item plans if exist
        form.setFieldsValue({
          idRiskMitigationOption: riskMitigationItems?.idRiskMitigationOption,
          riskMitigationItemPlans: item_plans,
          note: riskMitigationItems?.note,
        });
      }
    }
  }, [mitigation_status, mitigation, mitigationOption_status]);

  const removeMitigation = (remove, name) => {
    // get riskmitigationitemplans value based on user clicked on
    const riskMitigationItemPlans = form.getFieldValue(
      "riskMitigationItemPlans"
    );
    if (!isEmpty(riskMitigationItemPlans[name]?.id)) {
      // take previous and merge removed mitigationitemplans id
      setRemovedMitigation([
        ...removedMitigation,
        riskMitigationItemPlans[name]?.id,
      ]);
    }

    // Remove item UI
    remove(name);
  };

  return (
    <>
      <div className="px-4">
        <Form
          name="form-update-probis"
          form={form}
          onFinish={onFinish}
          initialValues={defaultMitigationPlanItem}
        >
          <div className="flex items-center py-5 flex-wrap">
            <Space>
              <Icon component={HomeLine} className="mr-2" />
              <RightOutlined className="mr-3 text-xs right-cursor" />
            </Space>
            <div>
              <p className="font-medium mr-3">Dokumen Pendukung MR</p>
            </div>
            <Space>
              <RightOutlined className="mr-3 text-xs right-cursor" />
            </Space>
            <div>
              <p className="font-medium mr-3">Rencana Mitigasi</p>
            </div>

            <Space>
              <RightOutlined className="mr-3 text-xs right-cursor" />
            </Space>
            <div>
              <p className="font-medium" style={{ color: "#01A24A" }}>
                Form Rencana Mitigasi
              </p>
            </div>
          </div>
          <div
            className="w-100 overflow-hidden mb-5"
            style={{
              borderRadius: "8px",
              border: "1px solid #EAECF0",
              background: "#FFFF",
            }}
          >
            <div className="">
              <div
                className=""
                style={{ background: "#00BD52", height: "10px" }}
              ></div>
              <div className="border-b mx-5 pb-3 mt-4 font-semibold">
                Risiko
              </div>

              <div className="flex items-start justify-center gap-5 w-full px-5 pb-3 mt-4">
                <div className="flex-1">
                  <label className="field-required font-medium">
                    Nomor Risiko
                  </label>
                  {mitigation_status === "pending" ? (
                    <div className="w-full mt-2">
                      <Skeleton.Input active size={"large"} block />
                    </div>
                  ) : (
                    <Input
                      placeholder="Nomor Risiko"
                      type="text"
                      className="form-input mt-2"
                      style={{ width: "100%" }}
                      disabled="true"
                      value={mitigation?.payload?.reCode ?? "-"}
                    />
                  )}
                </div>

                <div className="flex-1">
                  <label className="field-required font-medium">Kejadian</label>
                  {mitigation_status === "pending" ? (
                    <div className="w-full mt-2">
                      <Skeleton.Input active size={"large"} block />
                    </div>
                  ) : (
                    <TextArea
                      placeholder="Kejadian"
                      type="text"
                      className="form-input mt-2"
                      style={{ width: "100%" }}
                      rows={3}
                      disabled="true"
                      value={mitigation?.payload?.riskEvent ?? "-"}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className="w-100 overflow-hidden mb-5"
            style={{
              borderRadius: "8px",
              border: "1px solid #EAECF0",
              background: "#FFFF",
            }}
          >
            <div className="">
              {/* <div className="border-b mx-5 pb-3 mt-4 font-semibold">
                Opsi Mitigasi Risiko
              </div>

              <div className="px-5 pb-3 mt-4">
                <label className="field-required font-medium">
                  Opsi Mitigasi Risiko
                </label>
                <Form.Item
                  shouldUpdate
                  name={"idRiskMitigationOption"}
                  rules={requiredField}
                >
                  <Select
                    placeholder="Opsi Mitigasi Risiko"
                    type="text"
                    className="mt-2"
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      console.log("value", value);
                    }}
                    loading={mitigationOption_status === "pending"}
                    allowClear
                    size="large"
                    val
                  >
                    {mitigationOption?.payload.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div> */}

              <Form.List name="riskMitigationItemPlans">
                {(fields, { add, remove }, { errors }) => {
                  return (
                    <>
                      {fields.length > 0 && (
                        <div
                          className=""
                          style={{ background: "#00BD52", height: "10px" }}
                        ></div>
                      )}
                      {fields.map(({ key, name }) => (
                        <div key={name}>
                          <div className="border-b mx-5 pb-3 mt-4 font-semibold flex justify-between items-center">
                            <div>Rencana Mitigasi</div>
                          </div>
                          <div className="w-full px-5 pb-3 mt-4 space-y-5">
                            <Form.Item name={[name, "id"]} noStyle />
                            <div>
                              <label className="field-required font-medium">
                                Opsi Mitigasi Risiko
                              </label>
                              <Form.Item
                                shouldUpdate
                                name={[name, "idRiskMitigationOption"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Opsi Mitigasi Risiko"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  onChange={(value) => {
                                    console.log("value", value);
                                  }}
                                  loading={
                                    mitigationOption_status === "pending"
                                  }
                                  allowClear
                                  // size="large"
                                  val
                                >
                                  {mitigationOption?.payload.map((item) => (
                                    <Option key={item.id} value={item.id}>
                                      {item.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                            <div className="">
                              <label className="field-required font-medium">
                                Rencana Aksi Mitigasi Risiko
                              </label>
                              <Form.Item
                                name={[name, "riskMitigationOptionPlan"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Rencana Aksi Mitigasi Risiko"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                  rows={3}
                                />
                              </Form.Item>
                            </div>

                            <div className="flex items-start justify-center gap-5">
                              <div className="flex-1">
                                <label className="field-required font-medium">
                                  Target
                                </label>
                                <Form.Item
                                  name={[name, "target"]}
                                  rules={requiredField}
                                >
                                  <TextArea
                                    placeholder="Target"
                                    type="text"
                                    className="form-input mt-2"
                                    style={{ width: "100%" }}
                                    rows={3}
                                  />
                                </Form.Item>
                              </div>

                              <div className="flex-1">
                                <label className="field-required font-medium">
                                  Output
                                </label>
                                <Form.Item
                                  name={[name, "output"]}
                                  rules={requiredField}
                                >
                                  <TextArea
                                    placeholder="Output"
                                    type="text"
                                    className="form-input mt-2"
                                    style={{ width: "100%" }}
                                    rows={3}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="flex items-start justify-center gap-5">
                              <div className="flex-1">
                                <label className="field-required font-medium">
                                  Kendala
                                </label>
                                <Form.Item
                                  name={[name, "obstecle"]}
                                  rules={requiredField}
                                >
                                  <TextArea
                                    placeholder="Kendala"
                                    type="text"
                                    className="form-input mt-2"
                                    style={{ width: "100%" }}
                                    rows={3}
                                  />
                                </Form.Item>
                              </div>

                              <div className="flex-1">
                                <label className="field-required font-medium">
                                  Sumber Daya Yang Dibutuhkan
                                </label>
                                <Form.Item
                                  name={[name, "requiredResource"]}
                                  rules={requiredField}
                                >
                                  <TextArea
                                    placeholder="Sumber Daya Yang Dibutuhkan"
                                    type="text"
                                    className="form-input mt-2"
                                    style={{ width: "100%" }}
                                    rows={3}
                                  />
                                </Form.Item>
                              </div>
                            </div>

                            <div className="flex-1">
                              <label className="field-required font-medium">
                                Jadwal Implementasi
                              </label>
                              <div className="flex justify-between items-center gap-5">
                                <Form.Item
                                  name={[name, "riskMitigationImplementations"]}
                                  rules={requiredField}
                                >
                                  <Checkbox.Group options={jadwalOptions} />
                                </Form.Item>
                              </div>
                            </div>

                            <div className="flex-1">
                              <label className="field-required font-medium">
                                Penanggung Jawab
                              </label>
                              <Form.Item
                                shouldUpdate
                                name={[name, "personalInCharge"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Penanggung Jawab"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  onChange={(value) => {
                                    console.log("value", value);
                                  }}
                                  loading={
                                    pic_status === "pending" ||
                                    mitigation_status === "pending"
                                  }
                                  allowClear
                                  size="large"
                                >
                                  {pic?.payload?.map((item) => (
                                    <Option key={item.id} value={item.id}>
                                      {item.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                            <div className="w-full pb-5 flex justify-center items-center gap-2">
                              {fields.length > 1 ? (
                                <Button
                                  icon={<DeleteOutlined />}
                                  className="btn-danger"
                                  onClick={() => removeMitigation(remove, name)}
                                  title="Hapus Rencana Mitigasi"
                                >
                                  <span className="ml-2 text-center">
                                    Hapus
                                  </span>
                                </Button>
                              ) : (
                                <></>
                              )}
                              {fields.length - 1 === name ? (
                                <Button
                                  icon={<PlusOutlined />}
                                  className="btn-back"
                                  onClick={() => add()}
                                >
                                  <span className="ml-2 text-center">
                                    Tambah
                                  </span>
                                </Button>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                      {fields.length <= 0 && (
                        <div className="w-full pb-5">
                          <Button
                            icon={<PlusOutlined />}
                            className="btn-back mx-auto"
                            onClick={() => add()}
                          >
                            <span className="ml-2 text-center">Tambah</span>
                          </Button>
                        </div>
                      )}
                    </>
                  );
                }}
              </Form.List>
            </div>
          </div>

          <div
            className="w-100 overflow-hidden mb-28"
            style={{
              borderRadius: "8px",
              border: "1px solid #EAECF0",
              background: "#FFFF",
            }}
          >
            <div className="px-5 pb-3 mt-4">
              <div className="flex-1">
                <label className="font-medium">Catatan</label>
                <Form.Item
                  className="flex-1"
                  name={"note"}
                  // rules={requiredField}
                >
                  <TextArea
                    placeholder="Catatan"
                    type="text"
                    className="form-input mt-2"
                    style={{ width: "100%" }}
                    rows={3}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div
            className="mt-6 px-4 rounded border border-[#EAECF0] bg-white"
            style={{
              position: "fixed",
              bottom: 0,
              width: "calc(100vw - 400px)",
            }}
          >
            <div className="flex justify-between items-center">
              <Space>
                <NavLink to={"/rencana-mitigasi-admin"}>
                  <Button icon={<ArrowLeftOutlined />} className="btn-back">
                    <span className="ml-2 text-center">Kembali</span>
                  </Button>
                </NavLink>
              </Space>
              <div className="row">
                <Form.Item>
                  <Space>
                    <DraftButton form={form} />
                  </Space>
                  <Space>
                    <SubmitButton form={form} />
                  </Space>
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default CreateMitigationPlan;
