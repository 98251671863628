import { Button, Pagination, Select, Space, Spin, Table, Tag } from "antd";
import React, { useMemo, useState } from "react";
import Icon, {
  RightOutlined,
  CloudDownloadOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { HomeLined } from "components/atoms/SVG";
import SearchInput from "components/molecules/SearchInput";
import { useQuarterReport } from "hooks/useQuaterReport";
import { NavLink } from "react-router-dom";
import { debounce, toLower } from "lodash";
import { useSelector } from "react-redux";

const QuarterlyReport = ({ year }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [keyword, setKeyword] = useState("");
  const { JabatanCode, HierarchySequence } = useSelector(({ auth }) => auth.user);

  const onSearch = debounce((e) => {
    setKeyword(toLower(e.target.value));
  }, 1000);

  const quarterReport = useQuarterReport([
    "fetchQuarterReport",
    {
      Length: pageSize,
      Start: (currentPage - 1) * pageSize,
      Keyword: keyword,
    },
  ]);

  const totalRecords = useMemo(
    () => quarterReport?.data?.payload?.recordsTotal ?? 0,
    [quarterReport]
  );

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = useMemo(() => {
    const defaultColumns = [
      {
        title: "No",
        key: "number",
        width: "68px",
        height: "72px",
        render: (val, text, data) => {
          return <p>{data + 1}</p>;
        },
      },
      {
        title: "Sasaran Organisasi",
        dataIndex: "organizationTarget",
        key: "organizationTarget",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val || "-"}</p>;
        },
      },
      {
        title: "IKU",
        dataIndex: "iku",
        key: "iku",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val || "-"}</p>;
        },
      },
      {
        title: "No Risiko",
        dataIndex: "riskNumber",
        key: "riskNumber",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val || "-"}</p>;
        },
      },
      {
        title: "Kejadian",
        dataIndex: "riskEvent",
        key: "riskEvent",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val || "-"}</p>;
        },
      },
      {
        title: "Divisi",
        dataIndex: "division",
        key: "division",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val ?? "-"}</p>;
        },
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        sortDirections: ["descend", "ascend"],
        render: (status) => {
          let color;
          let desc;
          switch (status) {
            case "Selesai":
              color = "success";
              desc = status;
              break;
            case "Sedang Diproses":
              color = "warning";
              desc = "Sedang Diproses";
              break;
            case "BelumMulai":
              color = "default";
              desc = "Belum Mulai";
              break;
            default:
              color = "default";
              desc = status;
          }

          return (
            <Tag color={color}>
              <span className="font-semibold">{desc}</span>
            </Tag>
          );
        },
      },
      {
        title: "Action",
        key: "action",
        render: (val) => (
          <Space size="middle">
            <>
              <NavLink to={`detail/${val.idRiskReportItem}`}>
                  {<EyeOutlined style={{ fontSize: "18px" }} />}
              </NavLink>
              {JabatanCode !== '631' && (
                <NavLink to={`form/${val.idRiskReportItem}`}>
                {<EditOutlined style={{ fontSize: "18px" }} />}
              </NavLink>
              )}
            </>
          </Space>
        ),
      },
    ];
    
    if(JabatanCode !== '631'){
      return defaultColumns.filter(column => column.key !== "division");
    }

    return defaultColumns;
    
  }, []);

  return (
    <>
      <div className="flex items-center py-4 flex-wrap">
        <Space>
          <Icon component={HomeLined} className="mr-2" />
          <RightOutlined className="mr-3 text-xs right-cursor" />
        </Space>
        <div>
          <p className="font-medium" style={{ color: "#01A24A" }}>
            Daftar Laporan Triwulan MR
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-medium">List Daftar Laporan</h1>
        <Space>
          <Button
            htmlType="submit"
            icon={<CloudDownloadOutlined />}
            className="btn-default mt-4"
          >
            <span className="ml-2 text-center">Download</span>
          </Button>
        </Space>
      </div>

      <div className="border border-gray-200 rounded-lg p-3 w-full">
        <div className="flex gap-4 pb-3">
          <div className="space-x-5 flex w-full">
            <SearchInput placeholder="Cari..." onChange={onSearch} />
          </div>
        </div>
        <>
          <Spin spinning={quarterReport.isLoading}>
            <Table
              columns={columns}
              dataSource={quarterReport?.data?.payload?.data}
              pagination={false}
              style={{ overflowX: "auto" }}
            />
          </Spin>
          <div className="flex table-paginator-wrapper my-3 px-2 justify-between">
            <div className="flex flex-row justify-content-between mb-2 px-2 justify-between items-center">
              <div className="show align-self-center pr-2 font-medium text-sm">
                {"Tampilkan Data"}
              </div>
              <div className="dropdown-paging align-self-center pr-2">
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Select
                    className="select-paging-ppd"
                    style={{ width: "60px" }}
                    options={[
                      { value: 5, label: 5 },
                      { value: 10, label: 10 },
                      { value: 30, label: 30 },
                      { value: 50, label: 50 },
                      { value: 100, label: 100 },
                    ]}
                    onChange={handlePageSizeChange}
                    value={pageSize}
                  />
                </Space>
              </div>
              <div className="border-paging align-self-center pr-2"></div>
              <div className="total align-self-center pr-2">
                {`Showing ${Math.min(
                  (currentPage - 1) * pageSize + 1,
                  totalRecords
                )} to ${Math.min(
                  currentPage * pageSize,
                  totalRecords
                )} of ${totalRecords} entries`}
              </div>
            </div>
            <div>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalRecords}
                onChange={handlePageChange}
              />
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default QuarterlyReport;
