/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Space,
  Table,
  // Input,
  Spin,
  Radio,
  Modal,
  Select,
  Pagination,
  notification,
} from "antd";
import Icon, { EyeOutlined, RightOutlined } from "@ant-design/icons";
import { HomeLined } from "components/atoms/SVG";
import "./index.scss";
import { dashboardBackground } from "helpers";
import { useDashboard } from "hooks/useDashboard";
import { find } from "lodash";
import starIcon from "../../assets/logo/star-icon.png";
import axiosInstance from "app/interceptors";
import { BASE_URL_MANRISK } from "services/config";
import { NavLink } from "react-router-dom";

const Dashboard = () => {
  const [filter, setFilter] = useState(null);
  const [year, setYear] = useState((new Date().getFullYear()));
  const [open, setOpen] = useState(false);
  const [eventData, setEventData] = useState([]);
  const [eventParams, setEventParams] = useState([]);
  const [eventTotalRecords, setEventTotalRecords] = useState(0);
  const [eventLoading, setEventLoading] = useState(true);
  const [eventCurrentPage, setEventCurrentPage] = useState(1);
  const [eventPageSize, setEventPageSize] = useState(10);

  const dashboard = useDashboard([
    "fetchQuarterReport",
    {
      Year: year,
      TW: filter,
    },
  ]);

  if (dashboard.isSuccess && !dashboard?.data?.isSuccess) {
    notification.error({
      message: "Kesalahan",
      description: dashboard?.data?.message,
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      setEventLoading(true);
      try {
        let path = `${BASE_URL_MANRISK}/dashboard/datatable`;
        if (filter !== null) {
          path = `${BASE_URL_MANRISK}/dashboard/datatable-risk-report`;
        }
        const response = await axiosInstance.get(path, {
          params: {
            ...eventParams,
            Length: eventPageSize,
            Start: (eventCurrentPage - 1) * eventPageSize,
          },
        });

        if (response.data.isSuccess) {
          setEventData(response.data.payload.data);
          setEventTotalRecords(response.data.payload.recordsTotal);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setEventLoading(false);
      }
    };

    fetchData();

  }, [eventParams, eventCurrentPage, eventPageSize]);

  const renderEventData = eventData.slice(0, eventPageSize);

  const showModal = (item) => {
    setEventParams({
      TW : filter,
      Year : item.year,
      IdRiskSize : item.idRiskSize
    });
    setOpen(true);
  };
  
  const handleCancel = (item) => {
    setOpen(false);
  };

  const onFilter = (e) => {
    const val = e.target.value;
    setFilter(val || null);
  };

  const handlePageSizeChange = (value) => {
    setEventPageSize(value);
    setEventCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setEventCurrentPage(page);
  };

  const columns = [
    {
      title: "Metriks Analisis Risiko 5x5",
      width: "5%",
      className: "text-xl !bg-[#077439] !rounded-xl !text-white" ,
      onHeaderCell: (_, index) => ({
        rowSpan: 2,
      }),
      children: [
        {
          title: "Level Dampak1 ",
          dataIndex: "key",
          rowScope: "row",
          className: "!bg-[#077439] !text-white border-t-4 border-[#f9fcf6]",
          width: "1%",
          onCell: (_, index) => ({
            rowSpan: index === 0 ? 5 : 0,
          }),
          onHeaderCell: (_, index) => ({
            rowSpan: 0,
          }),
          render: (val, text, data) => {
            return (
              <p className="flex justify-center items-center whitespace-nowrap w-4 -rotate-90 text-base">
                Level Kemungkinan
              </p>
            );
          },
        },
        {
          title: "Level Dampak2",
          onHeaderCell: (_, index) => ({
            rowSpan: 0,
          }),
          dataIndex: "probabilityLevel",
          // rowScope: "row",
          width: "5%",
          className: "font-semibold text-center text-base text-slate-600 border-t-4 border-e-4 border-s-4 border-[#f9fcf6]",
          align: "center"
        },
        {
          title: "Level Dampak3",
          dataIndex: "probabilityName",
          key: "probabilityName",
          className: "text-base font-semibold text-slate-600 border-t-4 border-[#f9fcf6]",
          onHeaderCell: (_, index) => ({
            rowSpan: 0,
          }),
          render: (val, text, data) => {
            return <p>
              {val.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                return letter.toUpperCase();
              }) 
              || ""}</p>;
          },
        },
      ],
    },
    {
      title: "Level Dampak",
      dataIndex: "name",
      key: "name",
      sortDirections: ["descend", "ascend"],
      className: "text-base border-s-4 border-[#f9fcf6] !bg-[#077439] !text-white",
      render: (val, text, data) => {
        return <p>{val || ""}</p>;
      },
      children: [
        {
          title: "1-Tidak Signifikan",
          dataIndex: "riskSizes",
          key: "riskSizes",
          className: "text-center border-s-4 border-t-4 !border-b-0 border-[#f9fcf6]",
          sortDirections: ["descend", "ascend"],
          align: "center",
          width: "13%",
          render: (val) => {
            const find_val = find(val, { impactLevel: 1 });
            return {
              props: {
                className: dashboardBackground(+find_val?.riskSizeName || 0),
              },
              children: 
              <div className="">
                <div 
                  className={`${find_val?.eventCount > 0 ? 'block ' : 'hidden '}absolute w-10 h-10 top-1 right-1 cursor-pointer`}
                  onClick={() => showModal({...find_val, ...{year: year}})}
                >
                  <div className="whitespace-nowrap text-xs absolute w-10 h-10 pt-3.5 z-10">{find_val?.eventCount}</div>
                  <img src={starIcon} className="w-10 h-10 drop-shadow" alt="" />
                </div>
                {+find_val?.riskSizeName || 0}
              </div>,
            };
          },
        },
        {
          title: "2-Minor",
          dataIndex: "riskSizes",
          key: "riskSizes",
          className: "text-center border-s-4 border-t-4 !border-b-0 border-[#f9fcf6]",
          // sortDirections: ["descend", "ascend"],
          align: "center",
          width: "13%",
          render: (val) => {
            const find_val = find(val, { impactLevel: 2 });
            return {
              props: {
                className: dashboardBackground(+find_val?.riskSizeName || 0),
              },
              children: 
              <div className="">
                <div 
                  className={`${find_val?.eventCount > 0 ? 'block ' : 'hidden '}absolute w-10 h-10 top-1 right-1 cursor-pointer`}
                  onClick={() => showModal({...find_val, ...{year: year}})}
                >
                  <div className="whitespace-nowrap text-xs absolute w-10 h-10 pt-3.5 z-10">{find_val?.eventCount}</div>
                  <img src={starIcon} className="w-10 h-10 drop-shadow" alt="" />
                </div>
                  {+find_val?.riskSizeName || 0}
                </div>,
            };
          },
        },
        {
          title: "3-Moderate",
          dataIndex: "riskSizes",
          key: "riskSizes",
          className: "text-center border-s-4 border-t-4 !border-b-0 border-[#f9fcf6]",
          sortDirections: ["descend", "ascend"],
          align: "center",
          width: "13%",
          render: (val) => {
            const find_val = find(val, { impactLevel: 3 });
            return {
              props: {
                className: dashboardBackground(+find_val?.riskSizeName || 0),
              },
              children: 
              <div className="">
                <div 
                  className={`${find_val?.eventCount > 0 ? 'block ' : 'hidden '}absolute w-10 h-10 top-1 right-1 cursor-pointer`}
                  onClick={() => showModal({...find_val, ...{year: year}})}
                >
                  <div className="whitespace-nowrap text-xs absolute w-10 h-10 pt-3.5 z-10">{find_val?.eventCount}</div>
                  <img src={starIcon} className="w-10 h-10 drop-shadow" alt="" />
                </div>
                {+find_val?.riskSizeName || 0}
              </div>,
            };
          },
        },
        {
          title: "4-Signifikan",
          dataIndex: "riskSizes",
          key: "riskSizes",
          className: "text-center border-s-4 border-t-4 !border-b-0 border-[#f9fcf6]",
          sortDirections: ["descend", "ascend"],
          align: "center",
          width: "13%",
          render: (val) => {
            const find_val = find(val, { impactLevel: 4 });
            return {
              props: {
                className: dashboardBackground(+find_val?.riskSizeName || 0),
              },
              children: 
              <div className="">
                <div 
                  className={`${find_val?.eventCount > 0 ? 'block ' : 'hidden '}absolute w-10 h-10 top-1 right-1 cursor-pointer`}
                  onClick={() => showModal({...find_val, ...{year: year}})}
                >
                  <div className="whitespace-nowrap text-xs absolute w-10 h-10 pt-3.5 z-10">{find_val?.eventCount}</div>
                  <img src={starIcon} className="w-10 h-10 drop-shadow" alt="" />
                </div>
                {+find_val?.riskSizeName || 0}
              </div>,
            };
          },
        },
        {
          title: "5-Sangat Signifikan",
          dataIndex: "riskSizes",
          key: "riskSizes",
          className: "text-center border-s-4 border-t-4 !border-b-0 border-[#f9fcf6]",
          sortDirections: ["descend", "ascend"],
          align: "center",
          width: "13%",
          render: (val) => {
            const find_val = find(val, { impactLevel: 5 });
            return {
              props: {
                className: dashboardBackground(+find_val?.riskSizeName || 0),
              },
              children: 
              <div className="">
                <div 
                  className={`${find_val?.eventCount > 0 ? 'block ' : 'hidden '}absolute w-10 h-10 top-1 right-1 cursor-pointer`}
                  onClick={() => showModal({...find_val, ...{year: year}})}
                >
                  <div className="whitespace-nowrap text-xs absolute w-10 h-10 pt-3.5 z-10">{find_val?.eventCount}</div>
                  <img src={starIcon} className="w-10 h-10 drop-shadow" alt="" />
                </div>
                {+find_val?.riskSizeName || 0}
              </div>,
            };
          },
        },
      ],
    },
  ];
  const eventColumns = [
    {
        title: 'No',
        key: 'number',
        width: '68px',
        height: '72px',
        render: (val, text, data) => {
            return <p>{data + 1}</p>
        }
    },
    {
        title: 'Sasaran Organisasi',
        dataIndex: 'strategicTargetName',
        key: 'strategicTargetName',
    },
    {
        title: 'IKU',
        dataIndex: 'ikuName',
        key: 'ikuName',
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Sub IKU',
        dataIndex: 'subIkuName',
        key: 'subIkuName',
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Kejadian Risiko',
        dataIndex: 'event',
        key: 'event',
        sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action',
            key: 'action',
            render: (val) => (
                <Space size="middle">
                    <Space>
                        <NavLink to={`/profil-risiko-staff/detail-kadiv-probis/${val.idRiskProfileProbis}`}>
                            <span className="mr-3 ml-3">{<EyeOutlined style={{ fontSize: "18px" }} />}</span>
                        </NavLink>
                    </Space>
                </Space>
            )
    }
  ];

  return (
    <>
      <div className="flex items-center py-4 flex-wrap">
        <Space>
          <Icon component={HomeLined} className="mr-2" />
          <RightOutlined className="mr-3 text-xs right-cursor" />
        </Space>
        <div>
          <p className="font-medium" style={{ color: "#475467" }}>
            Dashboard
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-medium">Dashboard</h1>
      </div>

      <div className="flex gap-4 mb-2 border-2 w-fit rounded-xl">
          <div className="space-x-5 flex">
            <div className="flex justify-center items-center">
              <Radio.Group
                defaultValue=""
                buttonStyle="solid"
                size="large"
                onChange={onFilter}
              >
                <Radio.Button value="">Awal Tahun</Radio.Button>
                <Radio.Button value="TW1">Triwulan I</Radio.Button>
                <Radio.Button value="TW2">Triwulan II</Radio.Button>
                <Radio.Button value="TW3">Triwulan III</Radio.Button>
                <Radio.Button value="TW4">Triwulan IV</Radio.Button>
              </Radio.Group>
            </div>
            {/* <SearchInput placeholder="Cari..." onChange={onSearch} /> */}
          </div>
        </div>
      <div
        className="table-list rounded-xl"
        style={{ border: "1px solid #EAECF0" }}
      >

        <div>
          <Spin spinning={dashboard.isLoading}>
            <Table
              columns={columns}
              dataSource={dashboard?.data?.payload}
              pagination={false}
              className="p-3 rounded-lg"
              style={{ overflowX: "auto" }}
            />
          </Spin>
        </div>
      </div>
      <Modal
        title="List Event"
        open={open}
        onCancel={handleCancel}
        footer={null}
        width="80vw"
      >
        <Spin spinning={eventLoading}>
          <Table
            columns={eventColumns}
            dataSource={renderEventData}
            pagination={false}
            className="p-3 rounded-lg"
            style={{ overflowX: "auto" }}
          />
        </Spin>
        <div className="flex table-paginator-wrapper my-3 px-2 justify-between">
            <div className="flex flex-row justify-content-between mb-2 px-2 justify-between items-center">
              <div className="show align-self-center pr-2 font-medium text-sm">
                {"Tampilkan Data"}
              </div>
              <div className="dropdown-paging align-self-center pr-2">
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Select
                    className="select-paging-ppd"
                    style={{ width: "60px" }}
                    options={[
                      { value: 5, label: 5 },
                      { value: 10, label: 10 },
                      { value: 30, label: 30 },
                      { value: 50, label: 50 },
                      { value: 100, label: 100 },
                    ]}
                    onChange={handlePageSizeChange}
                    value={eventPageSize}
                  />
                </Space>
              </div>
              <div className="border-paging align-self-center pr-2"></div>
              <div className="total align-self-center pr-2">
                {`Showing ${Math.min(
                  (eventCurrentPage - 1) * eventPageSize + 1,
                  eventTotalRecords
                )} to ${Math.min(
                  eventCurrentPage * eventPageSize,
                  eventTotalRecords
                )} of ${eventTotalRecords} entries`}
              </div>
            </div>
            <div>
              <Pagination
                current={eventCurrentPage}
                pageSize={eventPageSize}
                total={eventTotalRecords}
                onChange={handlePageChange}
              />
            </div>
          </div>
      </Modal>
    </>
  );
};

export default Dashboard;
