import { BASE_URL_MANRISK } from "./config";
import axiosInstance from "app/interceptors";

export const fetchDirectorat = async (signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/master-data/directorat`
  );

  return data;
};

export const fetchDivision = async (signal) => {
  const { data } = await axiosInstance.get(`${BASE_URL_MANRISK}/master-data/division`);

  return data;
};

export const fetchDivisionById = async (id, signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/master-data/division/${id}`,
    { signal }
  );
  return data;
};

export const fetchPosition = async (params, signal) => {
  const { data } = await axiosInstance.get(`${BASE_URL_MANRISK}/master-data/position`, {
    params,
    signal,
  });
  return data;
};

export const fetchPositionKadiv = async (params, signal) => {
  const { data } = await axiosInstance.get(`${BASE_URL_MANRISK}/master-data/position-kadiv`, {
    params,
    signal,
  });
  return data;
};

export const fetchAreaImpact = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/master-data/area-impact`,
    { params, signal }
  );
  return data;
};

export const fetchAreaImpactLevel = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/master-data/impact-level`,
    { params, signal }
  );
  return data;
};

export const fetchAreaImpactLevelCategory = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/master-data/impact-level-category/${params.id}`,
    { signal }
  );
  return data;
};

export const fetchRiskCategory = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/master-data/risk-category`,
    { params, signal }
  );
  return data;
};

export const fetchProbabilityLevel = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/master-data/probability-level`,
    { params, signal }
  );
  return data;
};

export const fetchLowProbabilityOption = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/master-data/low-dropdown`,
    { params, signal }
  );
  return data;
};

export const fetchLowProbabilityLevel = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/master-data/low-probability-level`,
    { params, signal }
  );
  return data;
};

export const fetchNonLowProbabilityOption = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/master-data/non-low-dropdown`,
    { params, signal }
  );
  return data;
};

export const fetchNonLowProbabilityLevel = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/master-data/non-low-probability-level`,
    { params, signal }
  );
  return data;
};

export const fetchRiskSize = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/master-data/risk-size`,
    { params, signal }
  );
  return data;
};

export const fetchRiskLevel = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/master-data/risk-level`,
    { params, signal }
  );
  return data;
};

export const fetchRiskLevelDatatable = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/master-data/datatable-risk-level`,
    { params, signal }
  );
  return data;
};

export const fetchRiskSizeDatatable = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/master-data/datatable-risk-size`,
    { params, signal }
  );
  return data;
};

export const fetchOrganizationalGoalsList = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/master-data/organization-target`,
    { params, signal }
  );
  return data;
};
