import Spinner from '../Spinner';
import BaseButton from './index';

const BUTTON_COLORS = {
  default: 'bg-white border-gray-300 text-gray-600 focus:ring-gray-400',
  primary:
    'bg-primary-600 border-primary-600 text-white focus:ring-primary-600',
  'primary-subgreen1':
    'bg-primary-subgreen1-500 border-primary-subgreen1-500 text-white focus:ring-primary-subgreen1-500',
  warning:
    'bg-warning-600 border-warning-600 text-white focus:ring-warning-600',
  error: 'bg-error-600 border-error-600 text-white focus:ring-error-600',
  success:
    'bg-success-600 border-success-600 text-white focus:ring-success-600',
  orange: 'bg-orange-600 border-orange-600 text-white focus:ring-orange-600',
  blue: 'bg-blue-500 border-blue-500 text-white focus:ring-blue-500',
};

const BUTTON_LINK_VARIANT = {
  default: 'text-gray-600 hover:text-gray-700',
  primary:
    'text-primary-600 hover:text-primary-700 focus:ring-primary-600 focus:ring-offset-primary-600',
  'primary-subgreen1':
    'text-primary-subgreen1-500 hover:text-primary-subgreen1-600 focus:ring-primary-subgreen1-500 focus:ring-offset-primary-subgreen1-500',
  warning:
    'text-warning-600 hover:text-warning-700 focus:ring-warning-600 focus:ring-offset-warning-600',
  error:
    'text-error-600 hover:text-error-700 focus:ring-error-600 focus:ring-offset-error-600',
  success:
    'text-success-600 hover:text-success-700 focus:ring-success-600 focus:ring-offset-success-600',
  orange:
    'text-orange-600 hover:text-orange-700 focus:ring-orange-600 focus:ring-offset-orange-600',
  blue: 'text-blue-500 hover:text-blue-600 focus:ring-blue-500 focus:ring-offset-blue-500',
};

const BUTTON_SIZES = {
  sm: 'px-3 py-2 h-[40px] text-sm',
  md: 'px-4 py-3 h-[44px] text-sm',
  lg: 'px-6 py-4 h-[48px] text-base',
  xl: 'px-8 py-6 h-[52px] text-lg',
};

const BUTTON_SHADOWS = {
  default: 'shadow',
  md: 'shadow-md',
  xs: 'shadow-xs',
  sm: 'shadow-sm',
  lg: 'shadow-lg',
  xl: 'shadow-xl',
  '2xl': 'shadow-2xl',
  none: 'shadow-none',
};

const BUTTON_ROUNDED = {
  md: 'rounded-md',
  xs: 'rounded-xs',
  sm: 'rounded-sm',
  lg: 'rounded-lg',
  xl: 'rounded-xl',
  '2xl': 'rounded-2xl',
  none: 'rounded-none',
};

const BUTTON_ICON_SIZES = {
  sm: 'h-[40px] w-[36px]',
  md: 'h-[44px] w-[44px]',
  lg: 'h-[48px] w-[48px]',
  xl: 'h-[52px] w-[52px]',
};

const Button = ({
  color = 'default',
  size = 'md',
  shadow = 'none',
  rounded = 'lg',
  label,
  noRing = false,
  icon = false,
  block = false,
  disabled = false,
  className,
  loading = false,
  variant = 'default',
  ...props
}) => {
  return (
    <BaseButton
      disabled={disabled}
      className={[
        'inline-flex items-center gap-2 justify-center font-semibold text-sm focus:outline-none transition duration-300',
        'disabled:bg-gray-300 disabled:border-gray-300 disabled:text-gray-500 disabled:shadow-none disabled:cursor-not-allowed',
        !noRing && 'focus:ring-2 focus:ring-offset-2',
        variant === 'default' && 'border',
        variant === 'default' && BUTTON_COLORS[color],
        variant === 'link' && 'hover:underline !px-0',
        variant === 'link' && BUTTON_LINK_VARIANT[color],
        BUTTON_SIZES[size],
        BUTTON_SHADOWS[shadow],
        BUTTON_ROUNDED[rounded],
        icon && 'rounded-full !p-0',
        icon && BUTTON_ICON_SIZES[size],
        block && 'w-full',
        className,
      ].join(' ')}
      label={
        <>
          {loading && <Spinner />}
          {label}
        </>
      }
      {...props}
    />
  );
};

export default Button;