import { Pagination, Select, Space, Spin, Table } from "antd";
import { useRiskHistoryRegister } from "hooks/useRiskHistory";
import React, { useMemo, useState } from "react";

import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";

const RiskListTabs = ({ year }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const riskRegister = useRiskHistoryRegister([
    "risk-history/riskRegister",
    {
      Year: year,
      Length: pageSize,
      Start: (currentPage - 1) * pageSize,
    },
  ]);

  const totalRecords = useMemo(
    () => riskRegister?.data?.payload?.recordsTotal ?? 0,
    [riskRegister]
  );

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = useMemo(() => {
    const defaultColumns = [
      {
        title: "No",
        key: "number",
        width: "68px",
        height: "72px",
        render: (val, text, data) => {
          return <p>{data + 1}</p>;
        },
      },
      {
        title: "Sasaran Organisasi",
        dataIndex: "organizationTarget",
        key: "organizationTarget",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val || "-"}</p>;
        },
      },
      {
        title: "Kode RE",
        dataIndex: "reCodeNew",
        key: "reCodeNew",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val || "-"}</p>;
        },
      },
      {
        title: "Kejadian Risiko",
        dataIndex: "riskEvent",
        key: "riskEvent",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val || "-"}</p>;
        },
      },
      {
        title: "Awal Periode",
        dataIndex: "period",
        key: "period",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val ?? 0}</p>;
        },
      },
      {
        title: "Residual Harapan",
        dataIndex: "residualHope",
        key: "residualHope",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val ?? 0}</p>;
        },
      },
      {
        title: "TW1",
        dataIndex: "tw1",
        key: "tw1",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val ?? "-"}</p>;
        },
      },
      {
        title: "TW2",
        dataIndex: "tw2",
        key: "tw2",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val ?? "-"}</p>;
        },
      },
      {
        title: "TW3",
        dataIndex: "tw3",
        key: "tw3",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val ?? "-"}</p>;
        },
      },
      {
        title: "TW4",
        dataIndex: "tw4",
        key: "tw4",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val ?? "-"}</p>;
        },
      },
    ];
    return defaultColumns;
  }, []);

  return (
    <>
      <Spin spinning={riskRegister.isLoading}>
        <Table
          columns={columns}
          dataSource={riskRegister?.data?.payload?.data}
          pagination={false}
          style={{ overflowX: "auto" }}
        />
      </Spin>
      <div className="flex table-paginator-wrapper my-3 px-2 justify-between">
        <div className="flex flex-row justify-content-between mb-2 px-2 justify-between items-center">
          <div className="show align-self-center pr-2 font-medium text-sm">
            {"Tampilkan Data"}
          </div>
          <div className="dropdown-paging align-self-center pr-2">
            <Space direction="vertical" style={{ width: "100%" }}>
              <Select
                className="select-paging-ppd"
                style={{ width: "60px" }}
                options={[
                  { value: 5, label: 5 },
                  { value: 10, label: 10 },
                  { value: 30, label: 30 },
                  { value: 50, label: 50 },
                  { value: 100, label: 100 },
                ]}
                onChange={handlePageSizeChange}
                value={pageSize}
              />
            </Space>
          </div>
          <div className="border-paging align-self-center pr-2"></div>
          <div className="total align-self-center pr-2">
            {`Showing ${Math.min(
              (currentPage - 1) * pageSize + 1,
              totalRecords
            )} to ${Math.min(
              currentPage * pageSize,
              totalRecords
            )} of ${totalRecords} entries`}
          </div>
        </div>
        <div>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalRecords}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default RiskListTabs;
