import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchManualIru, postManualIru } from "services/manual_iru";

export const useManualIru = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchManualIru(params, signal);
    },
    ...options,
  });
};

export const useStoreManualIru = (options) => {
  return useMutation({
    mutationFn: (params) => postManualIru(params),
    ...options,
  })
}