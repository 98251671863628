import { DeleteFilled } from "@ant-design/icons";
import { Form, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useProbabilityLevel, useRiskSize } from "hooks/useMaster";
import { useEffect, useState } from "react";

const RiskProgress = ({
  name,
  fields,
  remove,
  removeItem,
  deleteState,
  deleteFunction,
  data,
  form,
  isDetail,
}) => {
  const requiredField = [{ required: true, message: "Wajib Diisi" }];
  const [selectedProbability, setSelectedProbability] = useState(null);
  const [selectedEnumLevel, setSelectedEnumLevel] = useState(null);
  const enumLevel = [
    { id: 1, value: "1-Tidak Signifikan" },
    { id: 2, value: "2-Minor" },
    { id: 3, value: "3-Moderat" },
    { id: 4, value: "4-Signifikan" },
    { id: 5, value: "5-Sangat Signifikan" },
  ];
  const probabilityLevel = useProbabilityLevel(["fetchProbabilityLevel"], {
    refetchOnMount: true,
  });

  const riskSize = useRiskSize(
    [
      "fetchRiskSize",
      {
        IdProbabilityLevel: selectedProbability,
        ImpactLevelEnum: selectedEnumLevel,
      },
    ],
    {
      enabled: !!selectedProbability && !!selectedEnumLevel,
      refetchOnMount: true,
    }
  );

  useEffect(() => {
    let formValues = form.getFieldsValue();

    // Check if the riskSize value has changed before updating the form values
    const newIdRiskSize = riskSize?.data?.payload?.id;
    const currentIdRiskSize =
      formValues.riskReportItems.riskReportProjections[name]?.idRiskSize;

    if (newIdRiskSize !== currentIdRiskSize) {
      // Update the form values only if there is a change
      formValues.riskReportItems.riskReportProjections[name].idRiskSize =
        newIdRiskSize;

      // Update the form values in state or wherever necessary
      form.setFieldsValue(formValues);
    }
  }, [riskSize, form, name]);

  useEffect(() => {
    if (data?.payload?.riskProjections) {
      setSelectedProbability(
        data?.payload?.riskProjections[name]?.idProbabilityLevel
      );
      setSelectedEnumLevel(
        data?.payload?.riskProjections[name]?.impactLevelEnum
      );
    }
  }, [data]);

  return (
    <>
      <div className="text-[#01A24A] text-base flex items-center gap-3 mx-5 pb-3 mt-4 font-semibold">
        {fields.length > 1 ? (
          <div
            onClick={() =>
              removeItem(
                remove,
                {
                  namePath: "riskReportProjections",
                  deleteState,
                  deleteFunction,
                },
                name
              )
            }
          >
            <DeleteFilled className="text-xl rounded-lg text-white p-3 cursor-pointer hover:text-red-300 bg-red-500" />
          </div>
        ) : (
          <></>
        )}
        <div>Progress Besaran Risiko</div>
      </div>

      <Form.Item name={[name, "id"]} noStyle />

      <div className="flex items-start justify-center gap-5 w-full px-5 pb-3 mt-4">
        <div className="flex-1">
          <label className="field-required font-semibold">
            Level Kemungkinan
          </label>
          <Form.Item name={[name, "idProbabilityLevel"]} rules={requiredField}>
            <Select
              placeholder="Level Kemungkinan"
              type="text"
              className="mt-2 w-full"
              onChange={setSelectedProbability}
              disabled={isDetail}
            >
              {probabilityLevel?.data?.payload.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.parameter}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className="flex-1">
          <label className="field-required font-semibold">Level Dampak</label>
          <Form.Item name={[name, "impactLevelEnum"]} rules={requiredField}>
            <Select
              placeholder="Level Dampak"
              type="text"
              className="mt-2 w-full"
              onChange={setSelectedEnumLevel}
              disabled={isDetail}
            >
              {enumLevel.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className="flex-1">
          <label className="field-required font-semibold">Besaran Risiko</label>
          <Form.Item name={[name, "idRiskSize"]} noStyle />
          <Input
            placeholder=""
            type="text"
            className="w-full form-input mt-2"
            value={riskSize?.data?.payload?.name ?? ""}
            disabled
          />
        </div>
      </div>

      <div className="flex items-start justify-center gap-5 mx-5 pb-1 mb-5 border-b mt-4">
        <div className="flex-1">
          <label className="field-required font-semibold">
            Penjelasan Capaian Besaran Risiko
          </label>
          <Form.Item
            name={[name, "riskSizeAchievementNote"]}
            rules={requiredField}
          >
            <TextArea
              placeholder="Penjelasan Capaian Besaran Risiko"
              type="text"
              className="w-full form-input mt-2"
              rows={5}
              disabled={isDetail}
            />
          </Form.Item>
        </div>
      </div>
    </>
  );
};

export default RiskProgress;
