import React, { useState, useEffect, useMemo } from "react";
import {
  Space,
  Table,
  Button,
  Spin,
  Pagination,
  Select,
  notification,
  Modal,
} from "antd";
import { NavLink } from "react-router-dom";
import Icon, {
  PlusOutlined,
  EditOutlined,
  EyeOutlined,
  RightOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { BASE_URL_MANRISK } from "services/config";
import axiosInstance from "app/interceptors";

const HomeLined = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.66667 13.1667H12.3333M8.18141 1.30333L2.52949 5.69927C2.15168 5.99312 1.96278 6.14005 1.82669 6.32405C1.70614 6.48704 1.61633 6.67065 1.56169 6.86588C1.5 7.08627 1.5 7.32558 1.5 7.80421V13.8333C1.5 14.7667 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1585 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1585 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7667 16.5 13.8333V7.80421C16.5 7.32558 16.5 7.08627 16.4383 6.86588C16.3837 6.67065 16.2939 6.48704 16.1733 6.32405C16.0372 6.14005 15.8483 5.99312 15.4705 5.69927L9.81859 1.30333C9.52582 1.07562 9.37943 0.961766 9.21779 0.918001C9.07516 0.879384 8.92484 0.879384 8.78221 0.918001C8.62057 0.961766 8.47418 1.07562 8.18141 1.30333Z"
      stroke="#667085"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const RiskManagementCharter = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [keyword, setKeyword] = useState("");
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [open, setOpen] = useState(false);
  const [deletedItem, setDeletedItem] = useState(null);

  const totalRecords = useMemo(
    () => data?.data?.payload?.recordsTotal ?? 0,
    [data]
  );

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDeleteClick = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.delete(
        `${BASE_URL_MANRISK}/risk-management-charter/${deletedItem}`
      );

      if (response?.data?.isSuccess) {
        notification.success({
          message: response.data.message,
          description: "Data berhasil dihapus",
        });
        setFetchTrigger(!fetchTrigger);
      } else {
        notification.error({
          message: "Kesalahan",
          description: response.message,
        });
      }
    } catch (error) {
      notification.error({
        message: "Kesalahan",
        description: error.message,
      });
    } finally {
      setDeletedItem(null);
      setOpen(false);
      setLoading(false);
    }
  };

  const showModal = (item) => {
    setDeletedItem(item);
    setOpen(true);
  };

  const handleCancel = () => {
    setDeletedItem(null);
    setOpen(false);
  };

  useEffect(() => {
    let path = `${BASE_URL_MANRISK}/risk-management-charter/datatable`;

    axiosInstance
      .get(path, {
        params: {
          Length: pageSize,
          Start: (currentPage - 1) * pageSize,
          Keyword: keyword,
        },
      })
      .then((response) => {
        if (response.data.isSuccess) {
          setData(response.data.payload.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageSize, currentPage, keyword, fetchTrigger]);

  const columns = [
    {
      title: "No",
      key: "number",
      width: "68px",
      height: "72px",
      render: (val, text, data) => {
        return <p>{data + 1}</p>;
      },
    },
    {
      title: "Nama UPR",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Tahun",
      dataIndex: "yearString",
      key: "yearString",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Nomor",
      dataIndex: "nomor",
      key: "nomor",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      key: "action",
      render: (val) => (
        <Space size="middle">
          <Space>
            <NavLink to={`/piagam-manajemen-risiko/detail/${val.id}`}>
              <span className="mr-1 ml-1">
                {<EyeOutlined style={{ fontSize: "18px" }} />}
              </span>
            </NavLink>
            <NavLink to={`/piagam-manajemen-risiko/edit/${val.id}`}>
              <span className="mr-1 ml-1">
                {<EditOutlined style={{ fontSize: "18px" }} />}
              </span>
            </NavLink>
            <NavLink to="#" onClick={() => showModal(val.id)}>
              <span className="mr-1 ml-1">
                {<DeleteOutlined style={{ fontSize: "18px" }} />}
              </span>
            </NavLink>
          </Space>
        </Space>
      ),
    },
  ];
  return (
    <>
      <div className="flex items-center py-5 flex-wrap">
        <Space>
          <Icon component={HomeLined} className="mr-2" />
          <RightOutlined className="mr-3 text-xs right-cursor" />
        </Space>
        <div>
          <p className="font-medium" style={{ color: "#01A24A" }}>
            Piagam Manajemen Resiko
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-medium">List Data Upload Piagam</h1>
        <Space>
          <NavLink to={"/piagam-manajemen-risiko/create"}>
            <Button
              style={{
                background: "#00BD52",
                color: "#FFFF",
                borderRadius: "8px",
              }}
              htmlType="submit"
              icon={<PlusOutlined />}
              className="flex justify-center items-center font-medium mt-4 py-6 px-6"
            >
              <span className="ml-2 text-center">Buat Baru</span>
            </Button>
          </NavLink>
        </Space>
      </div>
      <div
        className="table-list"
        style={{ border: "1px solid #EAECF0", borderRadius: "12px" }}
      >
        <div className="py-6 px-6">
          <Spin spinning={loading}>
            <Table
              columns={columns}
              dataSource={data}
              pagination={false}
              style={{ overflowX: "auto" }}
            />
          </Spin>
          <div className="flex table-paginator-wrapper my-3 px-2 justify-between">
            <div className="flex flex-row justify-content-between mb-2 px-2 justify-between items-center">
              <div className="show align-self-center pr-2 font-medium text-sm">
                {"Tampilkan Data"}
              </div>
              <div className="dropdown-paging align-self-center pr-2">
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Select
                    className="select-paging-ppd"
                    style={{ width: "60px" }}
                    options={[
                      { value: 5, label: 5 },
                      { value: 10, label: 10 },
                      { value: 30, label: 30 },
                      { value: 50, label: 50 },
                      { value: 100, label: 100 },
                    ]}
                    onChange={handlePageSizeChange}
                    value={pageSize}
                  />
                </Space>
              </div>
              <div className="border-paging align-self-center pr-2"></div>
              <div className="total align-self-center pr-2">
                {`Showing ${Math.min(
                  (currentPage - 1) * pageSize + 1,
                  totalRecords
                )} to ${Math.min(
                  currentPage * pageSize,
                  totalRecords
                )} of ${totalRecords} entries`}
              </div>
            </div>
            <div>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalRecords}
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Peringatan! Penghapusan data ini bersifat permanen."
        open={open}
        onOk={handleDeleteClick}
        onCancel={handleCancel}
      >
        <p>{"Apakah anda yakin akan menghapus data ini ?"}</p>
      </Modal>
    </>
  );
};

export default RiskManagementCharter;
