import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Space,
  notification,
  Spin,
  Switch,
  Select,
  Row,
  Col,
  Tabs,
} from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  LoadingOutlined,
  WarningOutlined,
  SaveOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import "./index.scss";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Icon, { RightOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { BASE_URL } from "services/config";
import { Input } from "components";
import TextArea from "antd/es/input/TextArea";
import axiosInstance from "app/interceptors";

const HomeLine = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.66667 13.1667H12.3333M8.18141 1.30333L2.52949 5.69927C2.15168 5.99312 1.96278 6.14005 1.82669 6.32405C1.70614 6.48704 1.61633 6.67065 1.56169 6.86588C1.5 7.08627 1.5 7.32558 1.5 7.80421V13.8333C1.5 14.7667 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1585 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1585 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7667 16.5 13.8333V7.80421C16.5 7.32558 16.5 7.08627 16.4383 6.86588C16.3837 6.67065 16.2939 6.48704 16.1733 6.32405C16.0372 6.14005 15.8483 5.99312 15.4705 5.69927L9.81859 1.30333C9.52582 1.07562 9.37943 0.961766 9.21779 0.918001C9.07516 0.879384 8.92484 0.879384 8.78221 0.918001C8.62057 0.961766 8.47418 1.07562 8.18141 1.30333Z"
      stroke="#667085"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CreateProposalSubmissionStepTwo = () => {
  const params = useParams();
  let navigate = useNavigate();
  let proposal;

  const [form] = Form.useForm();
  const spinIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
  const requiredField = [{ required: true, message: "Wajib Diisi" }];

  const [loading, setLoading] = useState(false);
  const [colDistribution, setColDistribution] = useState(true);
  const [colRisk, setColRisk] = useState(true);
  const [colResult, setColResult] = useState(true);
  const [colProjectStructure, setColProjectStructure] = useState(true);
  const [colSafeGuards, setColSafeGuards] = useState(true);
  const [colOthers, setColOthers] = useState(true);
  const [expandedPanels, setExpandedPanels] = useState([]);

  const proposalId = params?.id;
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  useEffect(() => {
    loadDetailDataDonor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDetailDataDonor = () => {
    setLoading(true);
    axiosInstance
      .get(`${BASE_URL}/proposal/${proposalId}`)
      .then((res) => {
        proposal = res.data.result;
        setLoading(false);

        proposal.projectPriorityPrograms?.forEach((item, idx) => {
          proposal.projectPriorityPrograms[idx].outcomes?.forEach((outcome) => {
            outcome?.outputs?.forEach((output) => {
              output?.indicators?.forEach((indicator) => {
                indicator?.parameters?.forEach((parameter) => {
                  let arr = [];

                  parameter?.indicativeActivities?.forEach((activity) => {
                    arr.push(activity.indicativeActivityName);
                  });

                  parameter._indicativeActivities = arr;
                });
              });
            });
          });
        });
        form.setFieldsValue({
          projectPriorityPrograms: proposal.projectPriorityPrograms,
        });
        form.setFieldsValue({
          investmentCriteria: proposal.investmentCriteria,
        });
        form.setFieldsValue({
          financialInstrument: proposal.financialInstrument,
        });

        form.setFieldsValue({ distribution: proposal.distribution });
        form.setFieldsValue({ principleObstacle: proposal.principleObstacle });
        form.setFieldsValue({
          communicatingResult: proposal.communicatingResult,
        });
        form.setFieldsValue({ projectStructure: proposal.projectStructure });
        form.setFieldsValue({ safeguards: proposal.safeguards });
        form.setFieldsValue({ others: proposal.others });
      })
      .catch((err) => {
        let errorMessage =
          err?.response?.data.message || "Internal Server Error";
        notification.error({
          message: "Kesalahan",
          duration: 5,
          description: errorMessage,
        });
        setLoading(false);
      });
  };

  const normalizePayload = (formValues) => {
    let data = JSON.parse(JSON.stringify(formValues));
    console.log("data", data);

    // if (Object.prototype.toString.call(data.projectPriorityPrograms) === '[object Array]') {
    //   data.projectPriorityPrograms = { ...data.projectPriorityPrograms[0] };
    //   if (Object.prototype.toString.call(data.projectPriorityPrograms.impact) === '[object Array]') {
    //     data.projectPriorityPrograms.impact = data.projectPriorityPrograms.impact[0]
    //   }
    // }

    if (
      Object.prototype.toString.call(data.financialInstrument) !==
      "[object Array]"
    ) {
      data.financialInstrument = [data.financialInstrument];
    }

    if (
      Object.prototype.toString.call(data.investmentCriteria) !==
      "[object Array]"
    ) {
      data.investmentCriteria = [data.investmentCriteria];
    }

    const payload = {
      ...data,
    };

    return payload;
  };

  const onFinish = (values, nav_btn) => {
    setLoading(true);
    const payload = normalizePayload(values);

    axiosInstance
      .put(`${BASE_URL}/proposal/${proposalId}/step/2`, payload)
      .catch((err) => {
        let errorMessage;
        let errorMessages = [];

        if (
          err?.response?.data.errors === undefined ||
          err?.response?.data.errors.length === 0
        ) {
          errorMessage = err?.response?.data.message;
        } else {
          <ul style={{ listStyleType: "disc" }}>
            {err.response.data.errors.forEach((error) => {
              errorMessages.push(<li>&#x2022; {error.message}</li>);
            })}
          </ul>;
        }

        setLoading(false);
        if (nav_btn === "back") {
          return navigate(
            `/proposal-submission/create-proposal/step/1/${proposalId}`
          );
        } else {
          notification.error({
            message: "Kesalahan",
            duration: 5,
            description: errorMessage ? errorMessage : errorMessages,
          });
        }
      })
      .then((res) => {
        setLoading(false);
        if (res) {
          if (nav_btn === "back") {
            return navigate(
              `/proposal-submission/create-proposal/step/1/${proposalId}`
            );
          } else if (nav_btn === "list") {
            return navigate(`/proposal-submission`);
          } else {
            return navigate(
              `/proposal-submission/create-proposal/step/3/${proposalId}`
            );
          }
        }
      });
  };

  const onColDistributionChange = (checked) => {
    setColDistribution(checked);
  };

  const onColRiskChange = (checked) => {
    setColRisk(checked);
  };

  const onColResultChange = (checked) => {
    setColResult(checked);
  };

  const onColProjectStructureChange = (checked) => {
    setColProjectStructure(checked);
  };

  const onColSageGuardsChange = (checked) => {
    setColSafeGuards(checked);
  };

  const onColOthersChange = (checked) => {
    setColOthers(checked);
  };

  const handlePanelChange = (panelKey) => {
    const expanded = [...expandedPanels];
    const index = expanded.indexOf(panelKey);

    if (index > -1) {
      expanded.splice(index, 1);
    } else {
      expanded.push(panelKey);
    }

    setExpandedPanels(expanded);
  };

  const expandIcon = ({ isActive }) => {
    if (isActive) {
      return <DownOutlined style={{ fontSize: 14 }} />;
    }
    return <UpOutlined style={{ fontSize: 14 }} />;
  };

  const tabItems = [
    {
      key: "1",
      label: "PPD",
      children: (
        <Spin spinning={loading} indicator={spinIcon}>
          <div className="px-4">
            <Form
              form={form}
              name="form-create-risk-profile"
              onFinish={onFinish}
              initialValues={{
                projectPriorityPrograms: [{}],
              }}
              disabled="true"
            >
              <div
                className="w-100 overflow-hidden mb-24"
                style={{
                  borderRadius: "8px",
                  border: "1px solid #EAECF0",
                  background: "#FFFF",
                }}
              >
                <div className="">
                  <div
                    className=""
                    style={{ background: "#00BD52", height: "10px" }}
                  ></div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                    </div>

                    <div className="row px-5 mt-4">
                      <label className="field-required font-medium">
                        Sasaran Organisasi
                      </label>
                      <Form.Item
                        name={["generalInformation", "title"]}
                        rules={requiredField}
                        style={{ width: "100%" }}
                      >
                        <Input
                          placeholder="Sasaran Organisasi"
                          type="text"
                          className="form-input mt-2"
                          style={{ width: "100%" }}
                          disabled="true"
                        />
                      </Form.Item>
                    </div>
                    <div className="px-5">
                      <label className="field-required font-medium">
                        Indikator Kinerja Utama
                      </label>
                      <Form.Item
                        name={["generalInformation", "title"]}
                        rules={requiredField}
                        style={{ width: "100%" }}
                      >
                        <Input
                          placeholder="Indikator Kinerja Utama"
                          type="text"
                          className="form-input mt-2"
                          style={{ width: "100%" }}
                          disabled="true"
                        />
                      </Form.Item>
                    </div>

                    <div className="px-5">
                      <label className="field-required font-medium">
                        SubIndikator Kinerja Utama
                      </label>
                      <Input
                        placeholder="SubIndikator Kinerja Utama"
                        type="text"
                        className="form-input my-3"
                        disabled
                        style={{ width: "100%" }}
                        value={""}
                      />
                    </div>

                    <div className="px-5">
                      <label className="field-required font-medium">
                        Target
                      </label>
                      <Input
                        placeholder="Target"
                        type="text"
                        className="form-input my-3"
                        disabled
                        style={{ width: "100%" }}
                        value={""}
                      />
                    </div>

                    <div className="px-5">
                      <label className="field-required font-medium">
                        Divisi
                      </label>
                      <Form.Item
                        name={["generalInformation", "title"]}
                        rules={requiredField}
                        style={{ width: "100%" }}
                        disabled="true"
                      >
                        <Input
                          placeholder="Divisi"
                          type="text"
                          className="form-input mt-2"
                          style={{ width: "100%" }}
                          disabled="true"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                      <div className="px-5 py-5">
                        <p className="text-2xl font-medium">Risiko</p>
                      </div>
                      <hr />

                      <div className="row px-5 mt-2">
                        <Row gutter={24} className="">
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                No
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Input
                                  placeholder="No"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                  disabled="true"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Kejadian
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Kejadian"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Dampak
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Dampak"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "1", label: "Penurunan Kinerja" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Sub Area Dampak
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Sub Area Dampak"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "1", label: "Penurunan Kinerja" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="">
                              <label className="field-required font-medium">
                                Penyebab
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Penyebab"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div>
                    <Row gutter={24} className="">
                      <Col span={12}>
                        <div
                          className="m-5 w-100 overflow-hidden mb-6"
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #EAECF0",
                            background: "#FFFF",
                          }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ background: "#00BD52", height: "10px" }}
                            ></div>
                          </div>

                          <div className="row px-5 mt-4">
                            <label className="field-required font-medium">
                              Kategori Risiko
                            </label>
                            <Form.Item
                              name={["generalInformation", "title"]}
                              rules={requiredField}
                              style={{ width: "100%" }}
                            >
                              <Select
                                placeholder="Kategori Risiko"
                                type="text"
                                className="mt-2"
                                style={{ width: "100%" }}
                                options={[
                                  { value: "1", label: "Risiko Operasional" },
                                ]}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>

                      <Col span={12}>
                        <div
                          className="m-5 w-100 overflow-hidden mb-6"
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #EAECF0",
                            background: "#FFFF",
                          }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ background: "#00BD52", height: "10px" }}
                            ></div>
                          </div>

                          <div className="row px-5 mt-4">
                            <label className="field-required font-medium">
                              Sistem Pengendalian yang dilaksanakan
                            </label>
                            <Form.Item
                              name={["generalInformation", "title"]}
                              rules={requiredField}
                              style={{ width: "100%" }}
                            >
                              <TextArea
                                placeholder="Sistem Pengendalian yang dilaksanakan"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                      <div className="px-5 py-5">
                        <p className="text-2xl font-medium">Kemungkinan</p>
                      </div>
                      <hr />

                      <div className="row px-5 mt-2">
                        <Row gutter={24} className="">
                          <Col span={24}>
                            <div className="">
                              <label className="field-required font-medium">
                                Kriteria Kemungkinan
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Input
                                  placeholder="Kriteria Kemungkinan"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                  disabled="true"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="">
                              <label className="field-required font-medium">
                                Jenis Kriteria Kemungkinan
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Jenis Kriteria Kemungkinan"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "1", label: "Risiko Operasional" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Jumlah Frekuensi Kejadian
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Jumlah Frekuensi Kejadian"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "1", label: "Risiko Operasional" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Probabilitas
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Input
                                  placeholder="Kriteria Kemungkinan"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Penjelasan
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Penjelasan"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Level Kemungkinan
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Level Kemungkinan"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "1", label: "Risiko Operasional" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                      <div className="px-5 py-5">
                        <p className="text-2xl font-medium">Dampak</p>
                      </div>
                      <hr />

                      <div className="row px-5 mt-2">
                        <Row gutter={24} className="">
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Level Dampak
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Level Kemungkinan"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "1", label: "Risiko Operasional" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Penjelasan
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Penjelasan"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </Col>

                          <Col span={24}>
                            <div className="">
                              <label className="field-required font-medium">
                                Deskripsi Level
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Deskripsi Level"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div>
                    <Row gutter={24} className="">
                      <Col span={12}>
                        <div
                          className="m-5 w-100 overflow-hidden mb-6"
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #EAECF0",
                            background: "#FFFF",
                          }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ background: "#00BD52", height: "10px" }}
                            ></div>
                          </div>

                          <div className="row px-5 mt-4">
                            <label className="field-required font-medium">
                              Besaran Risiko
                            </label>
                            <Form.Item
                              name={["generalInformation", "title"]}
                              rules={requiredField}
                              style={{ width: "100%" }}
                            >
                              <Input
                                placeholder="Besaran Risiko"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: "100%" }}
                                disabled="true"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>

                      <Col span={12}>
                        <div
                          className="m-5 w-100 overflow-hidden mb-6"
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #EAECF0",
                            background: "#FFFF",
                          }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ background: "#00BD52", height: "10px" }}
                            ></div>
                          </div>

                          <div className="row px-5 mt-4">
                            <label className="field-required font-medium">
                              Level Risiko
                            </label>
                            <Form.Item
                              name={["generalInformation", "title"]}
                              rules={requiredField}
                              style={{ width: "100%" }}
                            >
                              <Input
                                placeholder="Level Risiko"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: "100%" }}
                                disabled="true"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row gutter={24} className="">
                      <Col span={12}>
                        <div
                          className="m-5 w-100 overflow-hidden mb-6"
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #EAECF0",
                            background: "#FFFF",
                          }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ background: "#00BD52", height: "10px" }}
                            ></div>
                          </div>

                          <div className="row px-5 mt-4">
                            <label className="field-required font-medium">
                              Keputusan Mitigasi
                            </label>
                            <Form.Item
                              name={["generalInformation", "title"]}
                              rules={requiredField}
                              style={{ width: "100%" }}
                            >
                              <Select
                                placeholder="Keputusan Mitigasi"
                                type="text"
                                className="mt-2"
                                style={{ width: "100%" }}
                                options={[{ value: "1", label: "Lengkong" }]}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div
                          className="m-5 w-100 overflow-hidden mb-6"
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #EAECF0",
                            background: "#FFFF",
                          }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ background: "#00BD52", height: "10px" }}
                            ></div>
                          </div>

                          <div className="row px-5 mt-4">
                            <label className="field-required font-medium">
                              Prioritas Risiko
                            </label>
                            <Form.Item
                              name={["generalInformation", "title"]}
                              rules={requiredField}
                              style={{ width: "100%" }}
                            >
                              <Input
                                placeholder="Prioritas Risiko"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: "100%" }}
                                disabled="true"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                      <div className="px-5 py-5">
                        <p className="text-2xl font-medium">
                          Risiko Residual Harapan
                        </p>
                      </div>
                      <hr />

                      <div className="row px-5 mt-2">
                        <Row gutter={24} className="">
                          <Col span={8}>
                            <div className="">
                              <label className="field-required font-medium">
                                Level Kemungkinan
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Level Kemungkinan"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "USD", label: "Lengkong" },
                                    { value: "RUPIAH", label: "Pasir Layung" },
                                    { value: "EURO", label: "Cimahi Selatan" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className="">
                              <label className="field-required font-medium">
                                Level Dampak
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Level Dampak"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "USD", label: "Lengkong" },
                                    { value: "RUPIAH", label: "Pasir Layung" },
                                    { value: "EURO", label: "Cimahi Selatan" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className="">
                              <label className="field-required font-medium">
                                Besaran/Level Risiko
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Dampak"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    {
                                      value: "1",
                                      label: "Besaran/Level Risiko",
                                    },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                    </div>

                    <div className="row px-5 mt-4">
                      <label className="field-required font-medium">
                        Keputusan Mitigasi
                      </label>
                      <Form.Item
                        name={["generalInformation", "title"]}
                        rules={requiredField}
                        style={{ width: "100%" }}
                      >
                        <Input
                          placeholder="Keputusan Mitigasi"
                          type="text"
                          className="form-input mt-2"
                          style={{ width: "100%" }}
                          disabled="true"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                      <div className="px-5 py-5">
                        <p className="text-2xl font-medium">
                          Indikator Risiko Utama (IRU)
                        </p>
                      </div>
                      <hr />

                      <div className="row px-5 mt-2">
                        <Row gutter={24} className="">
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Nama
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Nama"
                                  type="text"
                                  className="form-input mt-2"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Batasan Nilai
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Batasan Nilai"
                                  type="text"
                                  className="form-input mt-2"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                    </div>

                    <div className="row px-5 mt-4">
                      <div className="">
                        <label className="font-medium">
                          Catatan
                        </label>
                        <Form.Item
                          name={["generalInformation", "country"]}
                          // rules={requiredField}
                        >
                          <TextArea
                            placeholder="Catatan"
                            type="text"
                            className="form-input mt-2"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="mt-6 px-4"
                style={{
                  borderRadius: "8px",
                  border: "1px solid #EAECF0",
                  background: "#FFFF",
                  position: "fixed",
                  bottom: 0,
                  width: "calc(100vw - 400px)",
                }}
              >
                <div className="flex justify-between items-center">
                  <Space>
                    <NavLink to={"/profil-risiko-admin"}>
                      <Button icon={<ArrowLeftOutlined />} className="btn-back">
                        <span className="ml-2 text-center">Kembali</span>
                      </Button>
                    </NavLink>
                  </Space>
                  <div className="row">
                    <Form.Item>
                      <Space>
                        {/* <NavLink to={'/proposal-submission'}> */}
                        <Button
                          icon={<SaveOutlined />}
                          className="btn-back"
                          onClick={() => {
                            onFinish(form.getFieldsValue(), "list");
                          }}
                        >
                          <span className="ml-2 text-center">
                            Simpan & Draft
                          </span>
                        </Button>
                        {/* </NavLink> */}
                      </Space>
                      <Space>
                        {/* <NavLink to={'/proposal-submission/create-proposal/step-2'}> */}
                        <Button
                          htmlType="submit"
                          className="btn-submit mt-4 ml-4"

                          // onClick={() => {
                          //   let error = form.getFieldsError();

                          //   console.log(error);
                          //   onFinish(form.getFieldsValue(), "next");
                          // }}
                        >
                          <span className="ml-2 text-center">Simpan</span>
                          <Icon
                            component={ArrowRightOutlined}
                            className="mr-2"
                            style={{ fontSize: 15 }}
                          />
                        </Button>
                        {/* </NavLink> */}
                      </Space>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Spin>
      ),
    },
    {
      key: "2",
      label: "FDB",
      children: (
        <Spin spinning={loading} indicator={spinIcon}>
          <div className="px-4">
            <Form
              form={form}
              name="form-create-risk-profile"
              onFinish={onFinish}
              initialValues={{
                projectPriorityPrograms: [{}],
              }}
              disabled="true"
            >
              <div
                className="w-100 overflow-hidden mb-24"
                style={{
                  borderRadius: "8px",
                  border: "1px solid #EAECF0",
                  background: "#FFFF",
                }}
              >
                <div className="">
                  <div
                    className=""
                    style={{ background: "#00BD52", height: "10px" }}
                  ></div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                    </div>

                    <div className="row px-5 mt-4">
                      <label className="field-required font-medium">
                        Sasaran Organisasi
                      </label>
                      <Form.Item
                        name={["generalInformation", "title"]}
                        rules={requiredField}
                        style={{ width: "100%" }}
                      >
                        <Input
                          placeholder="Sasaran Organisasi"
                          type="text"
                          className="form-input mt-2"
                          style={{ width: "100%" }}
                          disabled="true"
                        />
                      </Form.Item>
                    </div>
                    <div className="px-5">
                      <label className="field-required font-medium">
                        Indikator Kinerja Utama
                      </label>
                      <Form.Item
                        name={["generalInformation", "title"]}
                        rules={requiredField}
                        style={{ width: "100%" }}
                      >
                        <Input
                          placeholder="Indikator Kinerja Utama"
                          type="text"
                          className="form-input mt-2"
                          style={{ width: "100%" }}
                          disabled="true"
                        />
                      </Form.Item>
                    </div>

                    <div className="px-5">
                      <label className="field-required font-medium">
                        Divisi
                      </label>
                      <Form.Item
                        name={["generalInformation", "title"]}
                        rules={requiredField}
                        style={{ width: "100%" }}
                        disabled="true"
                      >
                        <Input
                          placeholder="Divisi"
                          type="text"
                          className="form-input mt-2"
                          style={{ width: "100%" }}
                          disabled="true"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                      <div className="px-5 py-5">
                        <p className="text-2xl font-medium">Risiko</p>
                      </div>
                      <hr />

                      <div className="row px-5 mt-2">
                        <Row gutter={24} className="">
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                No
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Input
                                  placeholder="No"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                  disabled="true"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Kejadian
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Kejadian"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Dampak
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Dampak"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "1", label: "Penurunan Kinerja" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Sub Area Dampak
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Sub Area Dampak"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "1", label: "Penurunan Kinerja" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="">
                              <label className="field-required font-medium">
                                Penyebab
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Penyebab"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div>
                    <Row gutter={24} className="">
                      <Col span={12}>
                        <div
                          className="m-5 w-100 overflow-hidden mb-6"
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #EAECF0",
                            background: "#FFFF",
                          }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ background: "#00BD52", height: "10px" }}
                            ></div>
                          </div>

                          <div className="row px-5 mt-4">
                            <label className="field-required font-medium">
                              Kategori Risiko
                            </label>
                            <Form.Item
                              name={["generalInformation", "title"]}
                              rules={requiredField}
                              style={{ width: "100%" }}
                            >
                              <Select
                                placeholder="Kategori Risiko"
                                type="text"
                                className="mt-2"
                                style={{ width: "100%" }}
                                options={[
                                  { value: "1", label: "Risiko Operasional" },
                                ]}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>

                      <Col span={12}>
                        <div
                          className="m-5 w-100 overflow-hidden mb-6"
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #EAECF0",
                            background: "#FFFF",
                          }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ background: "#00BD52", height: "10px" }}
                            ></div>
                          </div>

                          <div className="row px-5 mt-4">
                            <label className="field-required font-medium">
                              Sistem Pengendalian yang dilaksanakan
                            </label>
                            <Form.Item
                              name={["generalInformation", "title"]}
                              rules={requiredField}
                              style={{ width: "100%" }}
                            >
                              <TextArea
                                placeholder="Sistem Pengendalian yang dilaksanakan"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                      <div className="px-5 py-5">
                        <p className="text-2xl font-medium">Kemungkinan</p>
                      </div>
                      <hr />

                      <div className="row px-5 mt-2">
                        <Row gutter={24} className="">
                          <Col span={24}>
                            <div className="">
                              <label className="field-required font-medium">
                                Kriteria Kemungkinan
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Input
                                  placeholder="Kriteria Kemungkinan"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                  disabled="true"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="">
                              <label className="field-required font-medium">
                                Jenis Kriteria Kemungkinan
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Jenis Kriteria Kemungkinan"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "1", label: "Risiko Operasional" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Jumlah Frekuensi Kejadian
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Jumlah Frekuensi Kejadian"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "1", label: "Risiko Operasional" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Probabilitas
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Input
                                  placeholder="Kriteria Kemungkinan"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Penjelasan
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Penjelasan"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Level Kemungkinan
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Level Kemungkinan"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "1", label: "Risiko Operasional" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                      <div className="px-5 py-5">
                        <p className="text-2xl font-medium">Dampak</p>
                      </div>
                      <hr />

                      <div className="row px-5 mt-2">
                        <Row gutter={24} className="">
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Level Dampak
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Level Kemungkinan"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "1", label: "Risiko Operasional" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Penjelasan
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Penjelasan"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </Col>

                          <Col span={24}>
                            <div className="">
                              <label className="field-required font-medium">
                                Deskripsi Level
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Deskripsi Level"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div>
                    <Row gutter={24} className="">
                      <Col span={12}>
                        <div
                          className="m-5 w-100 overflow-hidden mb-6"
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #EAECF0",
                            background: "#FFFF",
                          }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ background: "#00BD52", height: "10px" }}
                            ></div>
                          </div>

                          <div className="row px-5 mt-4">
                            <label className="field-required font-medium">
                              Besaran Risiko
                            </label>
                            <Form.Item
                              name={["generalInformation", "title"]}
                              rules={requiredField}
                              style={{ width: "100%" }}
                            >
                              <Input
                                placeholder="Besaran Risiko"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: "100%" }}
                                disabled="true"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>

                      <Col span={12}>
                        <div
                          className="m-5 w-100 overflow-hidden mb-6"
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #EAECF0",
                            background: "#FFFF",
                          }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ background: "#00BD52", height: "10px" }}
                            ></div>
                          </div>

                          <div className="row px-5 mt-4">
                            <label className="field-required font-medium">
                              Level Risiko
                            </label>
                            <Form.Item
                              name={["generalInformation", "title"]}
                              rules={requiredField}
                              style={{ width: "100%" }}
                            >
                              <Input
                                placeholder="Level Risiko"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: "100%" }}
                                disabled="true"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row gutter={24} className="">
                      <Col span={12}>
                        <div
                          className="m-5 w-100 overflow-hidden mb-6"
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #EAECF0",
                            background: "#FFFF",
                          }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ background: "#00BD52", height: "10px" }}
                            ></div>
                          </div>

                          <div className="row px-5 mt-4">
                            <label className="field-required font-medium">
                              Keputusan Mitigasi
                            </label>
                            <Form.Item
                              name={["generalInformation", "title"]}
                              rules={requiredField}
                              style={{ width: "100%" }}
                            >
                              <Select
                                placeholder="Keputusan Mitigasi"
                                type="text"
                                className="mt-2"
                                style={{ width: "100%" }}
                                options={[{ value: "1", label: "Lengkong" }]}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div
                          className="m-5 w-100 overflow-hidden mb-6"
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #EAECF0",
                            background: "#FFFF",
                          }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ background: "#00BD52", height: "10px" }}
                            ></div>
                          </div>

                          <div className="row px-5 mt-4">
                            <label className="field-required font-medium">
                              Prioritas Risiko
                            </label>
                            <Form.Item
                              name={["generalInformation", "title"]}
                              rules={requiredField}
                              style={{ width: "100%" }}
                            >
                              <Input
                                placeholder="Prioritas Risiko"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: "100%" }}
                                disabled="true"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                      <div className="px-5 py-5">
                        <p className="text-2xl font-medium">
                          Risiko Residual Harapan
                        </p>
                      </div>
                      <hr />

                      <div className="row px-5 mt-2">
                        <Row gutter={24} className="">
                          <Col span={8}>
                            <div className="">
                              <label className="field-required font-medium">
                                Level Kemungkinan
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Level Kemungkinan"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "USD", label: "Lengkong" },
                                    { value: "RUPIAH", label: "Pasir Layung" },
                                    { value: "EURO", label: "Cimahi Selatan" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className="">
                              <label className="field-required font-medium">
                                Level Dampak
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Level Dampak"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "USD", label: "Lengkong" },
                                    { value: "RUPIAH", label: "Pasir Layung" },
                                    { value: "EURO", label: "Cimahi Selatan" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className="">
                              <label className="field-required font-medium">
                                Besaran/Level Risiko
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Dampak"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    {
                                      value: "1",
                                      label: "Besaran/Level Risiko",
                                    },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                    </div>

                    <div className="row px-5 mt-4">
                      <label className="field-required font-medium">
                        Keputusan Mitigasi
                      </label>
                      <Form.Item
                        name={["generalInformation", "title"]}
                        rules={requiredField}
                        style={{ width: "100%" }}
                      >
                        <Input
                          placeholder="Keputusan Mitigasi"
                          type="text"
                          className="form-input mt-2"
                          style={{ width: "100%" }}
                          disabled="true"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                      <div className="px-5 py-5">
                        <p className="text-2xl font-medium">
                          Indikator Risiko Utama (IRU)
                        </p>
                      </div>
                      <hr />

                      <div className="row px-5 mt-2">
                        <Row gutter={24} className="">
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Nama
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Nama"
                                  type="text"
                                  className="form-input mt-2"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Batasan Nilai
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Batasan Nilai"
                                  type="text"
                                  className="form-input mt-2"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                    </div>

                    <div className="row px-5 mt-4">
                      <div className="">
                        <label className="font-medium">
                          Catatan
                        </label>
                        <Form.Item
                          name={["generalInformation", "country"]}
                          // rules={requiredField}
                        >
                          <TextArea
                            placeholder="Catatan"
                            type="text"
                            className="form-input mt-2"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="mt-6 px-4"
                style={{
                  borderRadius: "8px",
                  border: "1px solid #EAECF0",
                  background: "#FFFF",
                  position: "fixed",
                  bottom: 0,
                  width: "calc(100vw - 400px)",
                }}
              >
                <div className="flex justify-between items-center">
                  <Space>
                    <NavLink to={"/profil-risiko-admin"}>
                      <Button icon={<ArrowLeftOutlined />} className="btn-back">
                        <span className="ml-2 text-center">Kembali</span>
                      </Button>
                    </NavLink>
                  </Space>
                  <div className="row">
                    <Form.Item>
                      <Space>
                        {/* <NavLink to={'/proposal-submission'}> */}
                        <Button
                          icon={<SaveOutlined />}
                          className="btn-back"
                          onClick={() => {
                            onFinish(form.getFieldsValue(), "list");
                          }}
                        >
                          <span className="ml-2 text-center">
                            Simpan & Draft
                          </span>
                        </Button>
                        {/* </NavLink> */}
                      </Space>
                      <Space>
                        {/* <NavLink to={'/proposal-submission/create-proposal/step-2'}> */}
                        <Button
                          htmlType="submit"
                          className="btn-submit mt-4 ml-4"

                          // onClick={() => {
                          //   let error = form.getFieldsError();

                          //   console.log(error);
                          //   onFinish(form.getFieldsValue(), "next");
                          // }}
                        >
                          <span className="ml-2 text-center">Simpan</span>
                          <Icon
                            component={ArrowRightOutlined}
                            className="mr-2"
                            style={{ fontSize: 15 }}
                          />
                        </Button>
                        {/* </NavLink> */}
                      </Space>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Spin>
      ),
    },
    {
      key: "3",
      label: "SDM",
      children: (
        <Spin spinning={loading} indicator={spinIcon}>
          <div className="px-4">
            <Form
              form={form}
              name="form-create-risk-profile"
              onFinish={onFinish}
              initialValues={{
                projectPriorityPrograms: [{}],
              }}
              disabled="true"
            >
              <div
                className="w-100 overflow-hidden mb-24"
                style={{
                  borderRadius: "8px",
                  border: "1px solid #EAECF0",
                  background: "#FFFF",
                }}
              >
                <div className="">
                  <div
                    className=""
                    style={{ background: "#00BD52", height: "10px" }}
                  ></div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                    </div>

                    <div className="row px-5 mt-4">
                      <label className="field-required font-medium">
                        Sasaran Organisasi
                      </label>
                      <Form.Item
                        name={["generalInformation", "title"]}
                        rules={requiredField}
                        style={{ width: "100%" }}
                      >
                        <Input
                          placeholder="Sasaran Organisasi"
                          type="text"
                          className="form-input mt-2"
                          style={{ width: "100%" }}
                          disabled="true"
                        />
                      </Form.Item>
                    </div>
                    <div className="px-5">
                      <label className="field-required font-medium">
                        Indikator Kinerja Utama
                      </label>
                      <Form.Item
                        name={["generalInformation", "title"]}
                        rules={requiredField}
                        style={{ width: "100%" }}
                      >
                        <Input
                          placeholder="Indikator Kinerja Utama"
                          type="text"
                          className="form-input mt-2"
                          style={{ width: "100%" }}
                          disabled="true"
                        />
                      </Form.Item>
                    </div>

                    <div className="px-5">
                      <label className="field-required font-medium">
                        Divisi
                      </label>
                      <Form.Item
                        name={["generalInformation", "title"]}
                        rules={requiredField}
                        style={{ width: "100%" }}
                        disabled="true"
                      >
                        <Input
                          placeholder="Divisi"
                          type="text"
                          className="form-input mt-2"
                          style={{ width: "100%" }}
                          disabled="true"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                      <div className="px-5 py-5">
                        <p className="text-2xl font-medium">Risiko</p>
                      </div>
                      <hr />

                      <div className="row px-5 mt-2">
                        <Row gutter={24} className="">
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                No
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Input
                                  placeholder="No"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                  disabled="true"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Kejadian
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Kejadian"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Dampak
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Dampak"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "1", label: "Penurunan Kinerja" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Sub Area Dampak
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Sub Area Dampak"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "1", label: "Penurunan Kinerja" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="">
                              <label className="field-required font-medium">
                                Penyebab
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Penyebab"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div>
                    <Row gutter={24} className="">
                      <Col span={12}>
                        <div
                          className="m-5 w-100 overflow-hidden mb-6"
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #EAECF0",
                            background: "#FFFF",
                          }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ background: "#00BD52", height: "10px" }}
                            ></div>
                          </div>

                          <div className="row px-5 mt-4">
                            <label className="field-required font-medium">
                              Kategori Risiko
                            </label>
                            <Form.Item
                              name={["generalInformation", "title"]}
                              rules={requiredField}
                              style={{ width: "100%" }}
                            >
                              <Select
                                placeholder="Kategori Risiko"
                                type="text"
                                className="mt-2"
                                style={{ width: "100%" }}
                                options={[
                                  { value: "1", label: "Risiko Operasional" },
                                ]}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>

                      <Col span={12}>
                        <div
                          className="m-5 w-100 overflow-hidden mb-6"
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #EAECF0",
                            background: "#FFFF",
                          }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ background: "#00BD52", height: "10px" }}
                            ></div>
                          </div>

                          <div className="row px-5 mt-4">
                            <label className="field-required font-medium">
                              Sistem Pengendalian yang dilaksanakan
                            </label>
                            <Form.Item
                              name={["generalInformation", "title"]}
                              rules={requiredField}
                              style={{ width: "100%" }}
                            >
                              <TextArea
                                placeholder="Sistem Pengendalian yang dilaksanakan"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                      <div className="px-5 py-5">
                        <p className="text-2xl font-medium">Kemungkinan</p>
                      </div>
                      <hr />

                      <div className="row px-5 mt-2">
                        <Row gutter={24} className="">
                          <Col span={24}>
                            <div className="">
                              <label className="field-required font-medium">
                                Kriteria Kemungkinan
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Input
                                  placeholder="Kriteria Kemungkinan"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                  disabled="true"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="">
                              <label className="field-required font-medium">
                                Jenis Kriteria Kemungkinan
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Jenis Kriteria Kemungkinan"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "1", label: "Risiko Operasional" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Jumlah Frekuensi Kejadian
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Jumlah Frekuensi Kejadian"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "1", label: "Risiko Operasional" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Probabilitas
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Input
                                  placeholder="Kriteria Kemungkinan"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Penjelasan
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Penjelasan"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Level Kemungkinan
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Level Kemungkinan"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "1", label: "Risiko Operasional" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                      <div className="px-5 py-5">
                        <p className="text-2xl font-medium">Dampak</p>
                      </div>
                      <hr />

                      <div className="row px-5 mt-2">
                        <Row gutter={24} className="">
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Level Dampak
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Level Kemungkinan"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "1", label: "Risiko Operasional" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Penjelasan
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Penjelasan"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </Col>

                          <Col span={24}>
                            <div className="">
                              <label className="field-required font-medium">
                                Deskripsi Level
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Deskripsi Level"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div>
                    <Row gutter={24} className="">
                      <Col span={12}>
                        <div
                          className="m-5 w-100 overflow-hidden mb-6"
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #EAECF0",
                            background: "#FFFF",
                          }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ background: "#00BD52", height: "10px" }}
                            ></div>
                          </div>

                          <div className="row px-5 mt-4">
                            <label className="field-required font-medium">
                              Besaran Risiko
                            </label>
                            <Form.Item
                              name={["generalInformation", "title"]}
                              rules={requiredField}
                              style={{ width: "100%" }}
                            >
                              <Input
                                placeholder="Besaran Risiko"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: "100%" }}
                                disabled="true"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>

                      <Col span={12}>
                        <div
                          className="m-5 w-100 overflow-hidden mb-6"
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #EAECF0",
                            background: "#FFFF",
                          }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ background: "#00BD52", height: "10px" }}
                            ></div>
                          </div>

                          <div className="row px-5 mt-4">
                            <label className="field-required font-medium">
                              Level Risiko
                            </label>
                            <Form.Item
                              name={["generalInformation", "title"]}
                              rules={requiredField}
                              style={{ width: "100%" }}
                            >
                              <Input
                                placeholder="Level Risiko"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: "100%" }}
                                disabled="true"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row gutter={24} className="">
                      <Col span={12}>
                        <div
                          className="m-5 w-100 overflow-hidden mb-6"
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #EAECF0",
                            background: "#FFFF",
                          }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ background: "#00BD52", height: "10px" }}
                            ></div>
                          </div>

                          <div className="row px-5 mt-4">
                            <label className="field-required font-medium">
                              Keputusan Mitigasi
                            </label>
                            <Form.Item
                              name={["generalInformation", "title"]}
                              rules={requiredField}
                              style={{ width: "100%" }}
                            >
                              <Select
                                placeholder="Keputusan Mitigasi"
                                type="text"
                                className="mt-2"
                                style={{ width: "100%" }}
                                options={[{ value: "1", label: "Lengkong" }]}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div
                          className="m-5 w-100 overflow-hidden mb-6"
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #EAECF0",
                            background: "#FFFF",
                          }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ background: "#00BD52", height: "10px" }}
                            ></div>
                          </div>

                          <div className="row px-5 mt-4">
                            <label className="field-required font-medium">
                              Prioritas Risiko
                            </label>
                            <Form.Item
                              name={["generalInformation", "title"]}
                              rules={requiredField}
                              style={{ width: "100%" }}
                            >
                              <Input
                                placeholder="Prioritas Risiko"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: "100%" }}
                                disabled="true"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                      <div className="px-5 py-5">
                        <p className="text-2xl font-medium">
                          Risiko Residual Harapan
                        </p>
                      </div>
                      <hr />

                      <div className="row px-5 mt-2">
                        <Row gutter={24} className="">
                          <Col span={8}>
                            <div className="">
                              <label className="field-required font-medium">
                                Level Kemungkinan
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Level Kemungkinan"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "USD", label: "Lengkong" },
                                    { value: "RUPIAH", label: "Pasir Layung" },
                                    { value: "EURO", label: "Cimahi Selatan" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className="">
                              <label className="field-required font-medium">
                                Level Dampak
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Level Dampak"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "USD", label: "Lengkong" },
                                    { value: "RUPIAH", label: "Pasir Layung" },
                                    { value: "EURO", label: "Cimahi Selatan" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className="">
                              <label className="field-required font-medium">
                                Besaran/Level Risiko
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Dampak"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    {
                                      value: "1",
                                      label: "Besaran/Level Risiko",
                                    },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                    </div>

                    <div className="row px-5 mt-4">
                      <label className="field-required font-medium">
                        Keputusan Mitigasi
                      </label>
                      <Form.Item
                        name={["generalInformation", "title"]}
                        rules={requiredField}
                        style={{ width: "100%" }}
                      >
                        <Input
                          placeholder="Keputusan Mitigasi"
                          type="text"
                          className="form-input mt-2"
                          style={{ width: "100%" }}
                          disabled="true"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                      <div className="px-5 py-5">
                        <p className="text-2xl font-medium">
                          Indikator Risiko Utama (IRU)
                        </p>
                      </div>
                      <hr />

                      <div className="row px-5 mt-2">
                        <Row gutter={24} className="">
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Nama
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Nama"
                                  type="text"
                                  className="form-input mt-2"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Batasan Nilai
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Batasan Nilai"
                                  type="text"
                                  className="form-input mt-2"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                    </div>

                    <div className="row px-5 mt-4">
                      <div className="">
                        <label className="font-medium">
                          Catatan
                        </label>
                        <Form.Item
                          name={["generalInformation", "country"]}
                          // rules={requiredField}
                        >
                          <TextArea
                            placeholder="Catatan"
                            type="text"
                            className="form-input mt-2"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="mt-6 px-4"
                style={{
                  borderRadius: "8px",
                  border: "1px solid #EAECF0",
                  background: "#FFFF",
                  position: "fixed",
                  bottom: 0,
                  width: "calc(100vw - 400px)",
                }}
              >
                <div className="flex justify-between items-center">
                  <Space>
                    <NavLink to={"/profil-risiko-admin"}>
                      <Button icon={<ArrowLeftOutlined />} className="btn-back">
                        <span className="ml-2 text-center">Kembali</span>
                      </Button>
                    </NavLink>
                  </Space>
                  <div className="row">
                    <Form.Item>
                      <Space>
                        {/* <NavLink to={'/proposal-submission'}> */}
                        <Button
                          icon={<SaveOutlined />}
                          className="btn-back"
                          onClick={() => {
                            onFinish(form.getFieldsValue(), "list");
                          }}
                        >
                          <span className="ml-2 text-center">
                            Simpan & Draft
                          </span>
                        </Button>
                        {/* </NavLink> */}
                      </Space>
                      <Space>
                        {/* <NavLink to={'/proposal-submission/create-proposal/step-2'}> */}
                        <Button
                          htmlType="submit"
                          className="btn-submit mt-4 ml-4"

                          // onClick={() => {
                          //   let error = form.getFieldsError();

                          //   console.log(error);
                          //   onFinish(form.getFieldsValue(), "next");
                          // }}
                        >
                          <span className="ml-2 text-center">Simpan</span>
                          <Icon
                            component={ArrowRightOutlined}
                            className="mr-2"
                            style={{ fontSize: 15 }}
                          />
                        </Button>
                        {/* </NavLink> */}
                      </Space>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Spin>
      ),
    },

    {
      key: "4",
      label: "Menrisk",
      children: (
        <Spin spinning={loading} indicator={spinIcon}>
          <div className="px-4">
            <Form
              form={form}
              name="form-create-risk-profile"
              onFinish={onFinish}
              initialValues={{
                projectPriorityPrograms: [{}],
              }}
              disabled="true"
            >
              <div
                className="w-100 overflow-hidden mb-24"
                style={{
                  borderRadius: "8px",
                  border: "1px solid #EAECF0",
                  background: "#FFFF",
                }}
              >
                <div className="">
                  <div
                    className=""
                    style={{ background: "#00BD52", height: "10px" }}
                  ></div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                    </div>

                    <div className="row px-5 mt-4">
                      <label className="field-required font-medium">
                        Sasaran Organisasi
                      </label>
                      <Form.Item
                        name={["generalInformation", "title"]}
                        rules={requiredField}
                        style={{ width: "100%" }}
                      >
                        <Input
                          placeholder="Sasaran Organisasi"
                          type="text"
                          className="form-input mt-2"
                          style={{ width: "100%" }}
                          disabled="true"
                        />
                      </Form.Item>
                    </div>
                    <div className="px-5">
                      <label className="field-required font-medium">
                        Indikator Kinerja Utama
                      </label>
                      <Form.Item
                        name={["generalInformation", "title"]}
                        rules={requiredField}
                        style={{ width: "100%" }}
                      >
                        <Input
                          placeholder="Indikator Kinerja Utama"
                          type="text"
                          className="form-input mt-2"
                          style={{ width: "100%" }}
                          disabled="true"
                        />
                      </Form.Item>
                    </div>

                    <div className="px-5">
                      <label className="field-required font-medium">
                        Divisi
                      </label>
                      <Form.Item
                        name={["generalInformation", "title"]}
                        rules={requiredField}
                        style={{ width: "100%" }}
                        disabled="true"
                      >
                        <Input
                          placeholder="Divisi"
                          type="text"
                          className="form-input mt-2"
                          style={{ width: "100%" }}
                          disabled="true"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                      <div className="px-5 py-5">
                        <p className="text-2xl font-medium">Risiko</p>
                      </div>
                      <hr />

                      <div className="row px-5 mt-2">
                        <Row gutter={24} className="">
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                No
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Input
                                  placeholder="No"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                  disabled="true"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Kejadian
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Kejadian"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Dampak
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Dampak"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "1", label: "Penurunan Kinerja" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Sub Area Dampak
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Sub Area Dampak"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "1", label: "Penurunan Kinerja" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="">
                              <label className="field-required font-medium">
                                Penyebab
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Penyebab"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div>
                    <Row gutter={24} className="">
                      <Col span={12}>
                        <div
                          className="m-5 w-100 overflow-hidden mb-6"
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #EAECF0",
                            background: "#FFFF",
                          }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ background: "#00BD52", height: "10px" }}
                            ></div>
                          </div>

                          <div className="row px-5 mt-4">
                            <label className="field-required font-medium">
                              Kategori Risiko
                            </label>
                            <Form.Item
                              name={["generalInformation", "title"]}
                              rules={requiredField}
                              style={{ width: "100%" }}
                            >
                              <Select
                                placeholder="Kategori Risiko"
                                type="text"
                                className="mt-2"
                                style={{ width: "100%" }}
                                options={[
                                  { value: "1", label: "Risiko Operasional" },
                                ]}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>

                      <Col span={12}>
                        <div
                          className="m-5 w-100 overflow-hidden mb-6"
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #EAECF0",
                            background: "#FFFF",
                          }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ background: "#00BD52", height: "10px" }}
                            ></div>
                          </div>

                          <div className="row px-5 mt-4">
                            <label className="field-required font-medium">
                              Sistem Pengendalian yang dilaksanakan
                            </label>
                            <Form.Item
                              name={["generalInformation", "title"]}
                              rules={requiredField}
                              style={{ width: "100%" }}
                            >
                              <TextArea
                                placeholder="Sistem Pengendalian yang dilaksanakan"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                      <div className="px-5 py-5">
                        <p className="text-2xl font-medium">Kemungkinan</p>
                      </div>
                      <hr />

                      <div className="row px-5 mt-2">
                        <Row gutter={24} className="">
                          <Col span={24}>
                            <div className="">
                              <label className="field-required font-medium">
                                Kriteria Kemungkinan
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Input
                                  placeholder="Kriteria Kemungkinan"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                  disabled="true"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="">
                              <label className="field-required font-medium">
                                Jenis Kriteria Kemungkinan
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Jenis Kriteria Kemungkinan"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "1", label: "Risiko Operasional" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Jumlah Frekuensi Kejadian
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Jumlah Frekuensi Kejadian"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "1", label: "Risiko Operasional" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Probabilitas
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Input
                                  placeholder="Kriteria Kemungkinan"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Penjelasan
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Penjelasan"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Level Kemungkinan
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Level Kemungkinan"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "1", label: "Risiko Operasional" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                      <div className="px-5 py-5">
                        <p className="text-2xl font-medium">Dampak</p>
                      </div>
                      <hr />

                      <div className="row px-5 mt-2">
                        <Row gutter={24} className="">
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Level Dampak
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Level Kemungkinan"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "1", label: "Risiko Operasional" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Penjelasan
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Penjelasan"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </Col>

                          <Col span={24}>
                            <div className="">
                              <label className="field-required font-medium">
                                Deskripsi Level
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Deskripsi Level"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div>
                    <Row gutter={24} className="">
                      <Col span={12}>
                        <div
                          className="m-5 w-100 overflow-hidden mb-6"
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #EAECF0",
                            background: "#FFFF",
                          }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ background: "#00BD52", height: "10px" }}
                            ></div>
                          </div>

                          <div className="row px-5 mt-4">
                            <label className="field-required font-medium">
                              Besaran Risiko
                            </label>
                            <Form.Item
                              name={["generalInformation", "title"]}
                              rules={requiredField}
                              style={{ width: "100%" }}
                            >
                              <Input
                                placeholder="Besaran Risiko"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: "100%" }}
                                disabled="true"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>

                      <Col span={12}>
                        <div
                          className="m-5 w-100 overflow-hidden mb-6"
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #EAECF0",
                            background: "#FFFF",
                          }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ background: "#00BD52", height: "10px" }}
                            ></div>
                          </div>

                          <div className="row px-5 mt-4">
                            <label className="field-required font-medium">
                              Level Risiko
                            </label>
                            <Form.Item
                              name={["generalInformation", "title"]}
                              rules={requiredField}
                              style={{ width: "100%" }}
                            >
                              <Input
                                placeholder="Level Risiko"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: "100%" }}
                                disabled="true"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row gutter={24} className="">
                      <Col span={12}>
                        <div
                          className="m-5 w-100 overflow-hidden mb-6"
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #EAECF0",
                            background: "#FFFF",
                          }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ background: "#00BD52", height: "10px" }}
                            ></div>
                          </div>

                          <div className="row px-5 mt-4">
                            <label className="field-required font-medium">
                              Keputusan Mitigasi
                            </label>
                            <Form.Item
                              name={["generalInformation", "title"]}
                              rules={requiredField}
                              style={{ width: "100%" }}
                            >
                              <Select
                                placeholder="Keputusan Mitigasi"
                                type="text"
                                className="mt-2"
                                style={{ width: "100%" }}
                                options={[{ value: "1", label: "Lengkong" }]}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div
                          className="m-5 w-100 overflow-hidden mb-6"
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #EAECF0",
                            background: "#FFFF",
                          }}
                        >
                          <div className="">
                            <div
                              className=""
                              style={{ background: "#00BD52", height: "10px" }}
                            ></div>
                          </div>

                          <div className="row px-5 mt-4">
                            <label className="field-required font-medium">
                              Prioritas Risiko
                            </label>
                            <Form.Item
                              name={["generalInformation", "title"]}
                              rules={requiredField}
                              style={{ width: "100%" }}
                            >
                              <Input
                                placeholder="Prioritas Risiko"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: "100%" }}
                                disabled="true"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                      <div className="px-5 py-5">
                        <p className="text-2xl font-medium">
                          Risiko Residual Harapan
                        </p>
                      </div>
                      <hr />

                      <div className="row px-5 mt-2">
                        <Row gutter={24} className="">
                          <Col span={8}>
                            <div className="">
                              <label className="field-required font-medium">
                                Level Kemungkinan
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Level Kemungkinan"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "USD", label: "Lengkong" },
                                    { value: "RUPIAH", label: "Pasir Layung" },
                                    { value: "EURO", label: "Cimahi Selatan" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className="">
                              <label className="field-required font-medium">
                                Level Dampak
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Level Dampak"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    { value: "USD", label: "Lengkong" },
                                    { value: "RUPIAH", label: "Pasir Layung" },
                                    { value: "EURO", label: "Cimahi Selatan" },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className="">
                              <label className="field-required font-medium">
                                Besaran/Level Risiko
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Dampak"
                                  type="text"
                                  className="mt-2"
                                  style={{ width: "100%" }}
                                  options={[
                                    {
                                      value: "1",
                                      label: "Besaran/Level Risiko",
                                    },
                                  ]}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                    </div>

                    <div className="row px-5 mt-4">
                      <label className="field-required font-medium">
                        Keputusan Mitigasi
                      </label>
                      <Form.Item
                        name={["generalInformation", "title"]}
                        rules={requiredField}
                        style={{ width: "100%" }}
                      >
                        <Input
                          placeholder="Keputusan Mitigasi"
                          type="text"
                          className="form-input mt-2"
                          style={{ width: "100%" }}
                          disabled="true"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                      <div className="px-5 py-5">
                        <p className="text-2xl font-medium">
                          Indikator Risiko Utama (IRU)
                        </p>
                      </div>
                      <hr />

                      <div className="row px-5 mt-2">
                        <Row gutter={24} className="">
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Nama
                              </label>
                              <Form.Item
                                name={["generalInformation", "country"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Nama"
                                  type="text"
                                  className="form-input mt-2"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="">
                              <label className="field-required font-medium">
                                Batasan Nilai
                              </label>
                              <Form.Item
                                name={["generalInformation", "province"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Batasan Nilai"
                                  type="text"
                                  className="form-input mt-2"
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div
                    className="m-5 w-100 overflow-hidden mb-6"
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #EAECF0",
                      background: "#FFFF",
                    }}
                  >
                    <div className="">
                      <div
                        className=""
                        style={{ background: "#00BD52", height: "10px" }}
                      ></div>
                    </div>

                    <div className="row px-5 mt-4">
                      <div className="">
                        <label className="font-medium">
                          Catatan
                        </label>
                        <Form.Item
                          name={["generalInformation", "country"]}
                          // rules={requiredField}
                        >
                          <TextArea
                            placeholder="Catatan"
                            type="text"
                            className="form-input mt-2"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="mt-6 px-4"
                style={{
                  borderRadius: "8px",
                  border: "1px solid #EAECF0",
                  background: "#FFFF",
                  position: "fixed",
                  bottom: 0,
                  width: "calc(100vw - 400px)",
                }}
              >
                <div className="flex justify-between items-center">
                  <Space>
                    <NavLink to={"/profil-risiko-admin"}>
                      <Button icon={<ArrowLeftOutlined />} className="btn-back">
                        <span className="ml-2 text-center">Kembali</span>
                      </Button>
                    </NavLink>
                  </Space>
                  <div className="row">
                    <Form.Item>
                      <Space>
                        {/* <NavLink to={'/proposal-submission'}> */}
                        <Button
                          icon={<SaveOutlined />}
                          className="btn-back"
                          onClick={() => {
                            onFinish(form.getFieldsValue(), "list");
                          }}
                        >
                          <span className="ml-2 text-center">
                            Simpan & Draft
                          </span>
                        </Button>
                        {/* </NavLink> */}
                      </Space>
                      <Space>
                        {/* <NavLink to={'/proposal-submission/create-proposal/step-2'}> */}
                        <Button
                          htmlType="submit"
                          className="btn-submit mt-4 ml-4"

                          // onClick={() => {
                          //   let error = form.getFieldsError();

                          //   console.log(error);
                          //   onFinish(form.getFieldsValue(), "next");
                          // }}
                        >
                          <span className="ml-2 text-center">Simpan</span>
                          <Icon
                            component={ArrowRightOutlined}
                            className="mr-2"
                            style={{ fontSize: 15 }}
                          />
                        </Button>
                        {/* </NavLink> */}
                      </Space>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Spin>
      ),
    },
  ];

  return (
    <>
      <div className="flex items-center py-5 flex-wrap">
        <Space>
          <Icon component={HomeLine} className="mr-2" />
          <RightOutlined className="mr-3 text-xs right-cursor" />
        </Space>
        <div>
          <p className="font-medium mr-3">Dokumen Pendukung MR</p>
        </div>
        <Space>
          <RightOutlined className="mr-3 text-xs right-cursor" />
        </Space>
        <div>
          <p className="font-medium mr-3">Profile Risiko</p>
        </div>

        <Space>
          <RightOutlined className="mr-3 text-xs right-cursor" />
        </Space>
        <div>
          <p className="font-medium" style={{ color: "#01A24A" }}>
            Detail Profile & Peta Risiko
          </p>
        </div>
      </div>
      <Tabs defaultActiveKey="1" centered items={tabItems} />
    </>
  );
};

export default CreateProposalSubmissionStepTwo;
