import { BASE_URL_FILE_SERVICE, BASE_URL_MANRISK } from "./config";
import axiosInstance from "app/interceptors";

export const fetchAgenda = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/meeting-schedule/datatable`,
    { params, signal }
  );
  return data;
};

export const fetchAgendaById = async (id, signal) => {
  
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/meeting-schedule/${id}`,
    { signal }
  );
  
  let attachment = null;
  if (data.payload.idAttachment){
    attachment = await axiosInstance.get(`${BASE_URL_FILE_SERVICE}/files/${data.payload.idAttachment}`);
  }
  
  let res = data;
  if (attachment != null){
    res = {...data, 'attachment':attachment?.data?.data}
  }

  return res;
};

export const postAgenda = async (params) => {
  const { data } = await axiosInstance.post(
    `${BASE_URL_MANRISK}/meeting-schedule`,
    params
  );

  return data;
};

export const updateAgenda = async (params) => {
  const { data } = await axiosInstance.patch(
    `${BASE_URL_MANRISK}/meeting-schedule/reschedule`,
    params
  );

  return data;
};
