import React, { useEffect, useMemo, useState } from "react";
import {
  Form,
  Button,
  Space,
  Select,
  DatePicker,
  notification,
  Tabs,
} from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  PlusOutlined,
  SaveOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import "./index.scss";
import { NavLink, useLocation, useParams } from "react-router-dom";
import Icon, { RightOutlined } from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";

import { useNavigate } from "react-router-dom";
import { Input } from "components";
import TextArea from "antd/es/input/TextArea";
import { CalenderIcon } from "components/atoms/SVG";
import { isEmpty } from "lodash";
import QuaterSection from "./sections/quater";
import RiskProgress from "./sections/risk_progress";
import {
  useQuarterReportById,
  useStoreQuaterReport,
} from "hooks/useQuaterReport";
import { toRoman } from "helpers";
import dayjs from "dayjs";

const HomeLine = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.66667 13.1667H12.3333M8.18141 1.30333L2.52949 5.69927C2.15168 5.99312 1.96278 6.14005 1.82669 6.32405C1.70614 6.48704 1.61633 6.67065 1.56169 6.86588C1.5 7.08627 1.5 7.32558 1.5 7.80421V13.8333C1.5 14.7667 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1585 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1585 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7667 16.5 13.8333V7.80421C16.5 7.32558 16.5 7.08627 16.4383 6.86588C16.3837 6.67065 16.2939 6.48704 16.1733 6.32405C16.0372 6.14005 15.8483 5.99312 15.4705 5.69927L9.81859 1.30333C9.52582 1.07562 9.37943 0.961766 9.21779 0.918001C9.07516 0.879384 8.92484 0.879384 8.78221 0.918001C8.62057 0.961766 8.47418 1.07562 8.18141 1.30333Z"
      stroke="#667085"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const { Option } = Select;
const QuarterlyReportForm = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const requiredField = [{ required: true, message: "Wajib Diisi" }];
  const [activeTab, setActiveTab] = useState("0");
  const [deleteRiskReportMitigationDone, setDeleteRiskReportMitigationDone] = useState(
    []
  );
  const [deleteRiskReportMitigationPlans, setDeleteRiskReportMitigationPlans] = useState(
    []
  );
  const [deleteRiskReportProjections, setDeleteRiskReportProjections] = useState(
    []
  );

  const [deleteQuater, setDeletedQuater] = useState([]);
  const isDetail = useLocation().pathname.includes("detail/");

  // fetch risk report detail by id at first request
  const { data, status } = useQuarterReportById(["fetchQuateReportById", id], {
    refetchOnMount: true,
  });
  // mitigation mutation
  const postQuaterReport = useStoreQuaterReport({
    // error handling
    onError: (error) => {
      notification.error({
        message: "Kesalahan",
        description: error?.message,
      });
    },
    // success handling
    onSuccess: (data) => {
      if (data.isSuccess) {
        notification.success({
          message: data?.message,
          description: "Data telah disimpan",
        });

        navigate("/laporan-triwulan");
      } else {
        notification.error({
          message: data?.message ?? "Kesalahan",
          description: data?.innerMessage ?? data?.message,
        });
      }
    },
  });

  const initialDefaultForm = useMemo(() => {
    return {
      riskReportItems: {
        id: null,
        riskMitigationPersonInCharge: null,
        riskMitigationDate: null,
        riskReportAmountQuarters: [
          {
            id: null,
            impactLevelEnum: null,
            idProbabilityLevel: null,
            idRiskSize: null,
            quater: 0,
            periodReportNote: "",
            riskSizeNote: "",
          },
        ],
        riskReportProjections: [
          {
            id: null,
            riskSizeAchievementNote: null,
            impactLevelEnum: null,
            idProbabilityLevel: null,
            idRiskSize: null,
          },
        ],
        riskReportMitigationDones: [
          {
            id: null,
            mitigationDoneNote: "",
          },
        ],
        riskReportMitigationPlans: [
          {
            id: null,
            mitigationPlanNote: "",
          },
        ],
        deleteRiskReportProjectons: null,
        deleteRiskReportMitigationDone: null,
        deleteRiskReportMitigationPlans: null,
      },
    };
  }, []);

  const onFinish = (values, type = "complete") => {
    // values.riskReportItems.riskReportAmountQuarters.map
    const filteredRiskReportAmountQuarters = values.riskReportItems.riskReportAmountQuarters.filter(item => 
      item.impactLevelEnum !== null 
      || item.idProbabilityLevel !== null 
      || item.idRiskSize !== undefined
      || item.periodReportNote !== ''
      || item.riskSizeNote !== ''
    );
    const filteredRiskReportProjections = values.riskReportItems.riskReportProjections.filter(item => 
      item.impactLevelEnum !== null 
      || item.idProbabilityLevel !== null 
      || item.idRiskSize !== undefined
      || item.riskSizeAchievementNote !== null
    );

    postQuaterReport.mutate({
      type,
      params: {
        ...data?.payload,
        ...values.riskReportItems,
        riskReportAmountQuarters : filteredRiskReportAmountQuarters,
        riskReportProjections : filteredRiskReportProjections,
        deleteRiskReportProjections : deleteRiskReportProjections,
        deleteRiskReportMitigationDone : deleteRiskReportMitigationDone,
        deleteRiskReportMitigationPlans : deleteRiskReportMitigationPlans,
        deleteRiskReportAmountQuarters : deleteQuater,
      }
    });
  };

  const SubmitButton = () => (
    <Button
      className="btn-submit mt-4 ml-4"
      onClick={() => {
        form.submit();
      }}
      loading={postQuaterReport.status === "pending"}
    >
      <span className="ml-2 text-center">Simpan</span>
      <Icon
        component={ArrowRightOutlined}
        className="mr-2"
        style={{ fontSize: 15 }}
      />
    </Button>
  );

  const onSaveDraft = async () => {
    try {
      const values = await form.getFieldsValue();
      onFinish(values, "draft");
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to save draft, please try again later.",
      });
    }
  };

  const DraftButton = () => (
    <Button
      icon={<SaveOutlined />}
      className="btn-back"
      onClick={onSaveDraft}
      loading={postQuaterReport.status === "pending"}
    >
      <span className="ml-2 text-center">Simpan & Draft</span>
    </Button>
  );

  const removeItem = (
    remove,
    { namePath, deleteState, deleteFunction },
    name
  ) => {
    // get riskReportItems value based on user clicked on
    const riskReportItems = form.getFieldValue("riskReportItems");

    if (!isEmpty(riskReportItems[namePath][name]?.id)) {
      deleteFunction([...deleteState, riskReportItems[namePath][name]?.id]);
    }

    // Remove item UI
    remove(name);
  };

  const onEdit = ({
    targetKey,
    action,
    add,
    remove,
    deleteQuater,
    setDeletedQuater,
    namePath,
    fields,
  }) => {
    if (action === "add") {
      add({
        id: null,
        impactLevelEnum: null,
        idProbabilityLevel: null,
        idRiskSize: null,
        quater: fields.length,
        periodReportNote: "",
        riskSizeNote: "",
      });
    } else {
      if (fields.length > 1) {
        removeItem(
          remove,
          {
            namePath,
            deleteState: deleteQuater,
            deleteFunction: setDeletedQuater,
          },
          +targetKey
        );
        setActiveTab(String(+targetKey - 1));
      }
    }
  };

  useEffect(() => {
    if (!isEmpty(data?.payload)) {
      console.log({
        riskReportItems: {
          ...data?.payload,
          riskReportProjections: data?.payload?.riskProjections,
        },
      });
      form.setFieldsValue({
        riskReportItems: {
          ...data?.payload,
          riskMitigationPersonInCharge: data?.payload?.riskMitigationPersonInCharge,
          riskReportAmountQuarters: data?.payload?.riskReportAmountQuarters.length > 0 ? data?.payload?.riskReportAmountQuarters : initialDefaultForm.riskReportItems.riskReportAmountQuarters,
          riskReportMitigationDones: data?.payload?.riskReportMitigationDones.length > 0 ? data?.payload?.riskReportMitigationDones : initialDefaultForm.riskReportItems.riskReportMitigationDones,
          riskReportMitigationPlans: data?.payload?.riskReportMitigationPlans.length > 0 ? data?.payload?.riskReportMitigationPlans : initialDefaultForm.riskReportItems.riskReportMitigationPlans,  
          riskMitigationDate: dayjs(data?.payload?.riskMitigationDate),
          riskReportProjections: data?.payload?.riskProjections.length > 0 ? data?.payload?.riskProjections : initialDefaultForm.riskReportItems.riskReportProjections,
        },
      });
    }
  }, [data]);

  return (
    <>
      <div className="px-4">
        <Form
          name="form-update-probis"
          form={form}
          onFinish={onFinish}
          initialValues={initialDefaultForm}
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
        >
          <div className="flex items-center py-5 flex-wrap">
            <Space>
              <Icon component={HomeLine} className="mr-2" />
              <RightOutlined className="mr-3 text-xs right-cursor" />
            </Space>
            <div>
              <p className="font-medium mr-3">Laporan Triwulan MR</p>
            </div>
            <Space>
              <RightOutlined className="mr-3 text-xs right-cursor" />
            </Space>
            <div>
              <p className="font-medium mr-3" style={{ color: "#01A24A" }}>Detail Laporan</p>
            </div>
          </div>
          <div className="pt-3 pb-7">
            <p className="text-2xl font-semibold">Laporan</p>
          </div>
          <div
            className="w-100 overflow-hidden mb-5"
            style={{
              borderRadius: "8px",
              border: "1px solid #EAECF0",
              background: "#FFFF",
            }}
          >
            <div className="">
              <div
                className=""
                style={{ background: "#00BD52", height: "10px" }}
              ></div>
              <div className="flex items-start justify-center gap-5 w-full px-5 pb-3 mt-4">
                <div className="flex-1">
                  <label className="field-required font-semibold">
                    Sasaran Organisasi
                  </label>
                  <Input
                    placeholder="Sasaran Organisasi"
                    type="text"
                    className="form-input mt-2"
                    style={{ width: "100%" }}
                    value={data?.payload?.organizationTarget ?? "-"}
                    disabled
                  />
                </div>

                <div className="flex-1">
                  <label className="field-required font-semibold">Risiko</label>
                  <Input
                    placeholder="Risiko"
                    type="text"
                    className="form-input mt-2"
                    style={{ width: "100%" }}
                    value={data?.payload?.riskEvent ?? "-"}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className="w-100 overflow-hidden mb-5"
            style={{
              borderRadius: "8px",
              border: "1px solid #EAECF0",
              background: "#FFFF",
            }}
          >
            <div className="">
              <div
                className=""
                style={{ background: "#00BD52", height: "10px" }}
              ></div>
              {/* Start */}
              <Form.List name={["riskReportItems", "riskReportAmountQuarters"]}>
                {(fields, { add, remove }, { errors }) => {
                  return (
                    <>
                      <Tabs
                        hideAdd={fields.length >= 4 || isDetail}
                        type="editable-card"
                        activeKey={activeTab}
                        onEdit={(targetKey, action) =>
                          onEdit({
                            targetKey,
                            action,
                            add,
                            remove,
                            deleteQuater,
                            setDeletedQuater,
                            namePath: "riskReportAmountQuarters",
                            fields,
                          })
                        }
                        onTabClick={setActiveTab}
                      >
                        {fields.map(({ key, name }) => (
                          <Tabs.TabPane
                            key={name}
                            forceRender
                            tab={`Triwulan ${toRoman(name + 1)}`}
                            closable={name !== 0 && !isDetail}
                          >
                            <QuaterSection key={key} name={name} data={data} form={form} isDetail={isDetail}/>
                          </Tabs.TabPane>
                        ))}
                      </Tabs>
                    </>
                  );
                }}
              </Form.List>

              {/* End */}
            </div>
          </div>

          <div
            className="w-100 overflow-hidden mb-5"
            style={{
              borderRadius: "8px",
              border: "1px solid #EAECF0",
              background: "#FFFF",
            }}
          >
            <div className="">
              <div
                className=""
                style={{ background: "#00BD52", height: "10px" }}
              ></div>
              <div className="text-base px-5 py-5 font-semibold border-b">
                Proyeksi Risiko
              </div>
              {/* Start */}
              <Form.List name={["riskReportItems", "riskReportProjections"]}>
                {(fields, { add, remove }, { errors }) => {
                  return (
                    <>
                      {fields.map(({ key, name }) => (
                        <RiskProgress
                          key={key}
                          name={name}
                          fields={fields}
                          remove={remove}
                          removeItem={removeItem}
                          deleteState={deleteRiskReportProjections}
                          deleteFunction={setDeleteRiskReportProjections}
                          data={data}
                          form={form}
                          isDetail={isDetail}
                        />
                      ))}
                      {!isDetail && (
                        <div className="w-full pb-5">
                          <Button
                            icon={<PlusOutlined />}
                            className="btn-back ml-auto mr-5"
                            onClick={() => add()}
                          >
                            <span className="ml-1 text-center">Tambah Proyeksi Risiko</span>
                          </Button>
                        </div>
                      )}
                    </>
                  );
                }}
              </Form.List>

              {/* End */}
            </div>
          </div>

          <div
            className="w-100 overflow-hidden mb-5"
            style={{
              borderRadius: "8px",
              border: "1px solid #EAECF0",
              background: "#FFFF",
            }}
          >
            <div className="">
              <div
                className=""
                style={{ background: "#00BD52", height: "10px" }}
              ></div>

              <Form.List
                name={["riskReportItems", "riskReportMitigationDones"]}
              >
                {(fields, { add, remove }, { errors }) => {
                  return (
                    <>
                      {fields.map(({ key, name }) => (
                        <div
                          key={name}
                          className="flex items-center gap-5 w-full px-5 mt-5"
                        >
                          {fields.length > 1 ? (
                            <div
                              onClick={() =>
                                removeItem(
                                  remove,
                                  {
                                    namePath: "riskReportMitigationDones",
                                    deleteState: deleteRiskReportMitigationDone,
                                    deleteFunction:
                                      setDeleteRiskReportMitigationDone,
                                  },
                                  name
                                )
                              }
                            >
                              <DeleteFilled className="text-xl rounded-lg text-white p-3 cursor-pointer hover:text-red-300 bg-red-500" />
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="flex-1">
                            <Form.Item name={[name, "id"]} noStyle />
                            <label className="field-required font-semibold">
                              Mitigasi Yang Telah Dilakukan
                            </label>
                            <Form.Item
                              name={[name, "mitigationDoneNote"]}
                              rules={requiredField}
                            >
                              <TextArea
                                placeholder="Mitigasi Yang Telah Dilakukan"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: "100%" }}
                                rows={3}
                                disabled={isDetail}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      ))}
                      {!isDetail && (
                        <div className="mx-5 py-5 border-t">
                          <Button
                            icon={<PlusOutlined />}
                            className="btn-back ml-auto"
                            onClick={() => add()}
                          >
                            <span className="ml-1 text-center">Tambah Mitigasi Yang Telah Dilakukan</span>
                          </Button>
                        </div>
                      )}
                    </>
                  );
                }}
              </Form.List>
            </div>
          </div>

          <div
            className="w-100 overflow-hidden mb-5"
            style={{
              borderRadius: "8px",
              border: "1px solid #EAECF0",
              background: "#FFFF",
            }}
          >
            <div className="">
              <div
                className=""
                style={{ background: "#00BD52", height: "10px" }}
              ></div>

              <Form.List
                name={["riskReportItems", "riskReportMitigationPlans"]}
              >
                {(fields, { add, remove }, { errors }) => {
                  return (
                    <>
                      {fields.map(({ key, name }) => (
                        <div
                          key={name}
                          className="flex items-center justify-center gap-5 w-full px-5 mt-5">
                          <Form.Item name={[name, "id"]} noStyle />
                          {fields.length > 1 ? (
                            <div
                              onClick={() =>
                                removeItem(
                                  remove,
                                  {
                                    namePath: "riskReportMitigationPlans",
                                    deleteState: deleteRiskReportMitigationPlans,
                                    deleteFunction: setDeleteRiskReportMitigationPlans,
                                  },
                                  name
                                )
                              }
                            >
                              <DeleteFilled className="text-xl rounded-lg text-white p-3 cursor-pointer hover:text-red-300 bg-red-500" />
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="flex-1">
                            <label className="field-required font-semibold">
                              Rencana Mitigasi
                            </label>
                            <Form.Item
                              name={[name, "mitigationPlanNote"]}
                              rules={requiredField}
                            >
                              <TextArea
                                placeholder="Rencana Mitigasi"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: "100%" }}
                                rows={3}
                                disabled={isDetail}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      ))}
                      {!isDetail && (
                        <div className="mx-5 py-5 border-t">
                          <Button
                            icon={<PlusOutlined />}
                            className="btn-back ml-auto"
                            onClick={() => add()}
                          >
                            <span className="ml-1 text-center">Tambah Rencana Mitigasi</span>
                          </Button>
                        </div>
                      )}
                    </>
                  );
                }}
              </Form.List>

              <div className="flex justify-center items-center px-5 pb-3 gap-5">
                <div className="flex-1">
                  <label className="field-required font-semibold">
                    Penanggung Jawab
                  </label>
                  {/* <Form.Item
                    shouldUpdate
                    name={"idRiskMitigationOption"}
                    rules={requiredField}
                  >
                    <Select
                      placeholder="Penanggung Jawab"
                      type="text"
                      className="mt-2"
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        console.log("value", value);
                      }}
                      loading={false}
                      allowClear
                      size="large"
                      val
                    >
                      {[].map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item> */}
                  <Form.Item
                    shouldUpdate
                    name={["riskReportItems", "riskMitigationPersonInCharge"]}
                    rules={requiredField}
                  >
                    <Input
                      placeholder="Penanggung Jawab"
                      type="text"
                      className="form-input mt-2"
                      style={{ width: "100%" }}
                      disabled={isDetail}
                    />
                  </Form.Item>
                </div>

                <div className="flex-1">
                  <label className="field-required font-semibold">
                    Waktu Pelaksanaan
                  </label>
                  <Form.Item
                    name={["riskReportItems", "riskMitigationDate"]}
                    rules={requiredField}
                    shouldUpdate
                  >
                    <DatePicker
                      suffixIcon={<CalenderIcon />}
                      className="form-input mt-2"
                      prefix={<CalenderIcon />}
                      style={{ width: "100%" }}
                      disabled={isDetail}
                    >
                      <Icon
                        component={CalenderIcon}
                        className="mr-2"
                        style={{ fontSize: 15 }}
                      />
                    </DatePicker>
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>

          {/* <div
            className="w-100 overflow-hidden mb-28"
            style={{
              borderRadius: "8px",
              border: "1px solid #EAECF0",
              background: "#FFFF",
            }}
          >
            <div className=" mx-5 pb-3 mt-4 font-semibold">
              Diagram Trend Risiko
            </div>
            <Barchart />
          </div> */}

          <div className="py-10"></div>

          <div
            className="mt-6 px-4 rounded border border-[#EAECF0] bg-white"
            style={{
              position: "fixed",
              bottom: 0,
              width: "calc(100vw - 400px)",
            }}
          >
            <div className="flex justify-between items-center">
              <Space>
                <NavLink to={"/laporan-triwulan"}>
                  <Button icon={<ArrowLeftOutlined />} className="btn-back my-4">
                    <span className="ml-2 text-center">Kembali</span>
                  </Button>
                </NavLink>
              </Space>
              <div className="row">
              {!isDetail && (
                <Form.Item>
                  <Space>
                    <DraftButton form={form} />
                  </Space>
                  <Space>
                    <SubmitButton form={form} />
                  </Space>
                </Form.Item>
              )}
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default QuarterlyReportForm;
