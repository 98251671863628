import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchRiskMitigationById, fetchRiskMitigationOption, postRiskMitigation } from "services/risk_mitigation";

export const useMitigationById = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const id = queryKey[1];
      return fetchRiskMitigationById(id, signal);
    },
    ...options,
  });
};

export const useMitigationOption = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal }) => {
      return fetchRiskMitigationOption(signal);
    },
    ...options,
  });
};

export const useStoreMitigation = (options) => {
  return useMutation({
    mutationFn: (params) => postRiskMitigation(params),
    ...options,
  })
}