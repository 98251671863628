import React, { useEffect, useMemo, useState } from "react";
import { Form, Button, Space, Select, DatePicker, notification } from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import "./index.scss";
import { NavLink, useParams } from "react-router-dom";
import Icon, { RightOutlined } from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";

import { useNavigate } from "react-router-dom";
import { Input } from "components";
import TextArea from "antd/es/input/TextArea";
import { CalenderIcon } from "components/atoms/SVG";
import { isEmpty, map, sortBy } from "lodash";
import dayjs from "dayjs";
import {
  useContextFormulationById,
  useStoreContextFormulation,
} from "hooks/useContextFormulation";
import axiosInstance from "app/interceptors";
import { useOrganizationalGoalsList } from "hooks/useMaster";

const HomeLine = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.66667 13.1667H12.3333M8.18141 1.30333L2.52949 5.69927C2.15168 5.99312 1.96278 6.14005 1.82669 6.32405C1.70614 6.48704 1.61633 6.67065 1.56169 6.86588C1.5 7.08627 1.5 7.32558 1.5 7.80421V13.8333C1.5 14.7667 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1585 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1585 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7667 16.5 13.8333V7.80421C16.5 7.32558 16.5 7.08627 16.4383 6.86588C16.3837 6.67065 16.2939 6.48704 16.1733 6.32405C16.0372 6.14005 15.8483 5.99312 15.4705 5.69927L9.81859 1.30333C9.52582 1.07562 9.37943 0.961766 9.21779 0.918001C9.07516 0.879384 8.92484 0.879384 8.78221 0.918001C8.62057 0.961766 8.47418 1.07562 8.18141 1.30333Z"
      stroke="#667085"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const { Option } = Select;
const ContextFormulationForm = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const requiredField = [{ required: true, message: "Wajib Diisi" }];
  const [deleteScopeItem, setDeleteScopeItem] = useState([]);
  const [userList, setUserList] = useState([]);
  const [sasaranList, setSasaranList] = useState([]);

  // Fetches context formulation detail by id at first request
  const { data, isLoading } = useContextFormulationById(
    ["fetchContextFormulationById", id],
    {
      refetchOnMount: true,
    }
  );

  const { data: organizationalGoals, status: organizationalGoals_status } = useOrganizationalGoalsList(
    ["fetchOrganizationalGoalsList"],
    {
      refetchOnMount: true,
    }
  );

  // mitigation mutation
  const postContextFormulation = useStoreContextFormulation({
    // error handling
    onError: (error) => {
      notification.error({
        message: "Kesalahan",
        description: error?.message,
      });
    },
    // success handling
    onSuccess: (data) => {
      if (data.isSuccess) {
        notification.success({
          message: data?.message,
          description: "Data telah disimpan",
        });

        navigate("/perumusan-konteks");
      } else {
        notification.error({
          message: data?.message ?? "Kesalahan",
          description: data?.innerMessage ?? data?.message,
        });
      }
    },
  });

  const defaultMitigationPlanItem = useMemo(() => {
    return {
      contextFormulationItems: {
        id: undefined,
        unitOrganization: "BPDLH",
        startDate: undefined,
        endDate: undefined,
        contextFormulationScopeItems: [
          {
            id: undefined,
            idContextFormulationItem: undefined,
            task: null,
            function: null,
            mandate: null,
          },
        ],
        contextFormulationStackholderItems: [
          {
            id: undefined,
            idContextFormulationItem: undefined,
            stakeholder: null,
            note: null,
          },
        ],
        contextFormulationTargetOrganizationItems: [
          {
            id: undefined,
            idContextFormulationItem: undefined,
            target: null,
            note: null,
          },
        ],
        contextFormulationUnitStructureItems: [
          {
            id: undefined,
            idContextFormulationItem: undefined,
            riskOwner: null,
            riskOwnerPosition: 0,
            position: null,
          },
          {
            id: undefined,
            idContextFormulationItem: undefined,
            riskOwner: null,
            riskOwnerPosition: 1,
            position: null,
          },
          {
            id: undefined,
            idContextFormulationItem: undefined,
            riskOwner: null,
            riskOwnerPosition: 2,
            position: null,
          },
        ],
      },
    };
  }, []);

  useEffect(() => {
    axiosInstance.get(`/user-management/v1/Employee/list`)
      .then((response) => response.data.data)
      .then((data) => {
        const result = data.map(item => {
            return {
               id: item.id,
               name: item.name,
               idJabatan: item.idJabatan,
               jabatan: item.jabatan,
               value: item.id,
               label: item.name,
            };
        });
        setUserList(result);
      });

      if(isEmpty(sasaranList)){
        setSasaranList(organizationalGoals?.payload.map(item => {
          return {
            id: item.id,
            name: item.strategicTarget,
            value: item.id,
            label: item.strategicTarget,
          }
        }))
      }
  }, [organizationalGoals])

  useEffect(() => {
    if (!isLoading && data?.payload) {
      // set mitigation item plans if exist

      const reorderStructurUnit = map(
        data?.payload?.contextFormulationUnitStructureItems,
        (riskOwner) => {
          if (riskOwner.riskOwnerPosition === "Manager")
            riskOwner.riskOwnerPosition = 0;
          if (riskOwner.riskOwnerPosition === "Coordinator")
            riskOwner.riskOwnerPosition = 1;
          if (riskOwner.riskOwnerPosition === "Administration")
            riskOwner.riskOwnerPosition = 2;

          if (riskOwner.positionLabel === undefined){
            riskOwner.positionLabel = userList.filter(item => item['id'] === riskOwner.riskOwner)?.[0]?.jabatan;
          }

          return riskOwner;
        }
      );

      form.setFieldsValue({
        contextFormulationItems: {
          id,
          unitOrganization: data?.payload?.unitOrganization,
          startDate: dayjs(data?.payload?.startDate),
          endDate: dayjs(data?.payload?.endDate),
          contextFormulationScopeItems:
            data?.payload?.contextFormulationScopeItems,
          contextFormulationStackholderItems:
            data?.payload?.contextFormulationStackholderItems,
          contextFormulationTargetOrganizationItems:
            data?.payload?.contextFormulationTargetOrganizationItems,
          contextFormulationUnitStructureItems: sortBy(
            reorderStructurUnit,
            "riskOwnerPosition"
          ),
        },
      });
    }
  }, [isLoading, data, id, userList]);

  const onFinish = (values, type = "complete") => {
    const params = {
      contextFormulationItems: {
        ...values.contextFormulationItems,
        deleteScopeItem,
        deleteStackholderItem: [],
        deleteTargetItem: [],
      },
    };
    postContextFormulation.mutate({
      type,
      params,
    });
  };

  const SubmitButton = () => (
    <Button
      className="btn-submit mt-4 ml-4"
      onClick={() => {
        form.submit();
      }}
      loading={postContextFormulation.status === "pending"}
    >
      <span className="ml-2 text-center">Simpan</span>
      <Icon
        component={ArrowRightOutlined}
        className="mr-2"
        style={{ fontSize: 15 }}
      />
    </Button>
  );

  const onSaveDraft = async () => {
    try {
      const values = await form.getFieldsValue();
      onFinish(values, "draft");
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to save draft, please try again later.",
      });
    }
  };

  const DraftButton = () => (
    <Button
      icon={<SaveOutlined />}
      className="btn-back"
      onClick={onSaveDraft}
      loading={postContextFormulation.status === "pending"}
    >
      <span className="ml-2 text-center">Simpan & Draft</span>
    </Button>
  );

  const removeItem = (
    remove,
    { namePath, deleteState, deleteFunction },
    name
  ) => {
    // get contextFormulationItems value based on user clicked on
    const contextFormulationItems = form.getFieldValue(
      "contextFormulationItems"
    );
    console.log(contextFormulationItems[namePath]);

    if (!isEmpty(contextFormulationItems[namePath][name]?.id)) {
      deleteFunction([
        ...deleteState,
        contextFormulationItems[namePath][name]?.id,
      ]);
    }

    // Remove item UI
    remove(name);
  };

  return (
    <>
      <div className="px-4">
        <Form
          name="form-update-probis"
          form={form}
          onFinish={onFinish}
          initialValues={defaultMitigationPlanItem}
        >
          <div className="flex items-center py-5 flex-wrap">
            <Space>
              <Icon component={HomeLine} className="mr-2" />
              <RightOutlined className="mr-3 text-xs right-cursor" />
            </Space>
            <div>
              <p className="font-medium mr-3">Dokumen Pendukung MR</p>
            </div>
            <Space>
              <RightOutlined className="mr-3 text-xs right-cursor" />
            </Space>
            <div>
              <p className="font-medium mr-3">Perumusan Konteks</p>
            </div>

            <Space>
              <RightOutlined className="mr-3 text-xs right-cursor" />
            </Space>
            <div>
              <p className="font-medium" style={{ color: "#01A24A" }}>
                Form Perumusan Konteks
              </p>
            </div>
          </div>

          <div
            className="w-100 overflow-hidden mb-5"
            style={{
              borderRadius: "8px",
              border: "1px solid #EAECF0",
              background: "#FFFF",
            }}
          >
            <div
              className=""
              style={{ background: "#00BD52", height: "10px" }}
            ></div>
            <div className="">
              <div className="flex items-start justify-center gap-5 w-full px-5 pb-3 mt-4">
                <Form.Item name={["contextFormulationItems", "id"]} noStyle />
                <div className="flex-1">
                  <label className="font-medium">Unit Organisasi</label>
                  <Form.Item
                    name={["contextFormulationItems", "unitOrganization"]}
                    rules={requiredField}
                  >
                    <Input
                      placeholder="Unit Organisasi"
                      type="text"
                      className="form-input mt-2 w-full"
                      disabled
                    />
                  </Form.Item>
                </div>

                <div className="flex-1">
                  <label className="field-required font-medium">
                    Periode Penerapan
                  </label>
                  <div className="flex gap-5">
                    <Form.Item
                      name={["contextFormulationItems", "startDate"]}
                      rules={requiredField}
                      className="w-full"
                    >
                      <DatePicker
                        suffixIcon={<CalenderIcon />}
                        className="form-input mt-2 w-full"
                        prefix={<CalenderIcon />}
                        picker="month"
                        placeholder="Bulan Mulai"
                        disabled
                      >
                        <Icon component={CalenderIcon} className="mr-2" />
                      </DatePicker>
                    </Form.Item>
                    <Form.Item
                      name={["contextFormulationItems", "endDate"]}
                      rules={requiredField}
                      className="w-full"
                    >
                      <DatePicker
                        suffixIcon={<CalenderIcon />}
                        className="form-input mt-2 flex-1 w-full"
                        prefix={<CalenderIcon />}
                        picker="month"
                        placeholder="Bulan Selesai"
                        disabled
                      >
                        <Icon component={CalenderIcon} className="mr-2" />
                      </DatePicker>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="w-100 overflow-hidden mb-5"
            style={{
              borderRadius: "8px",
              border: "1px solid #EAECF0",
              background: "#FFFF",
            }}
          >
            <div className="">
              <div
                className=""
                style={{ background: "#00BD52", height: "10px" }}
              ></div>

              <div className="border-b mx-5 pb-3 mt-4 font-semibold flex justify-between items-center">
                Ruang Lingkup
              </div>

              <Form.List
                name={[
                  "contextFormulationItems",
                  "contextFormulationScopeItems",
                ]}
              >
                {(fields, { add, remove }, { errors }) => {
                  return (
                    <>
                      {fields.map(({ key, name }) => (
                        <div key={name}>
                          <div className="mx-5 font-semibold flex justify-between items-center">
                            <div></div>
                          </div>
                          <div className="w-full px-5 pb-3 mt-4 space-y-5">
                            <Form.Item name={[name, "id"]} noStyle />
                            <Form.Item
                              name={[name, "idContextFormulationItem"]}
                              noStyle
                            />
                            <div className="">
                              <label className="field-required font-medium">
                                Tugas
                              </label>
                              <Form.Item
                                name={[name, "task"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Tugas"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                  rows={3}
                                  disabled
                                />
                              </Form.Item>
                            </div>
                            <div className="">
                              <label className="field-required font-medium">
                                Fungsi
                              </label>
                              <Form.Item
                                name={[name, "function"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Fungsi"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                  rows={3}
                                  disabled
                                />
                              </Form.Item>
                            </div>
                            <div className="">
                              <label className="field-required font-medium">
                                Mandat
                              </label>
                              <Form.Item
                                name={[name, "mandate"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Mandat"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                  rows={3}
                                  disabled
                                />
                              </Form.Item>
                            </div>
                          </div>
                          {fields.length > 1 ? <hr className="my-5" /> : <></>}
                        </div>
                      ))}
                    </>
                  );
                }}
              </Form.List>
            </div>
          </div>

          <div
            className="w-100 overflow-hidden mb-5"
            style={{
              borderRadius: "8px",
              border: "1px solid #EAECF0",
              background: "#FFFF",
            }}
          >
            <div className="">
              <div
                className=""
                style={{ background: "#00BD52", height: "10px" }}
              ></div>

              <div className="border-b mx-5 pb-3 mt-4 font-semibold flex justify-between items-center">
                1. Sasaran Organisasi
              </div>
              <Form.List
                name={[
                  "contextFormulationItems",
                  "contextFormulationTargetOrganizationItems",
                ]}
              >
                {(
                  fields,
                  { add: addTarget, remove: removeTarget },
                  { errors }
                ) => {
                  return (
                    <>
                      {fields.map(({ key, name }) => (
                        <div key={name}>
                          <div className="mx-5 font-semibold flex justify-between items-center">
                            <div></div>
                          </div>
                          <div className="w-full px-5 pb-3 mt-4 space-y-5">
                            <Form.Item name={[name, "id"]} noStyle />
                            <div className="">
                              <label className="field-required font-medium">
                                Daftar Sasaran
                              </label>
                              <Form.Item
                                name={[name, "target"]}
                                rules={requiredField}
                              >
                                <Select
                                  placeholder="Nama Sasaran"
                                  type="text"
                                  className="mt-2 w-full"
                                  loading={organizationalGoals_status === "pending"}
                                  showSearch
                                  optionFilterProp="label"
                                  options={sasaranList}
                                  disabled
                                />
                              </Form.Item>
                            </div>
                            <div className="">
                              <label className="field-required font-medium">
                                Keterangan
                              </label>
                              <Form.Item
                                name={[name, "note"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Fungsi"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                  rows={3}
                                  disabled
                                />
                              </Form.Item>
                            </div>
                          </div>
                          {fields.length > 1 ? <hr className="my-5" /> : <></>}
                        </div>
                      ))}
                    </>
                  );
                }}
              </Form.List>
            </div>
          </div>

          <div
            className="w-100 overflow-hidden mb-5"
            style={{
              borderRadius: "8px",
              border: "1px solid #EAECF0",
              background: "#FFFF",
            }}
          >
            <div className="">
              <div
                className=""
                style={{ background: "#00BD52", height: "10px" }}
              ></div>

              <div className="border-b mx-5 pb-3 mt-4 font-semibold flex justify-between items-center">
                2. Daftar Pemangku Kepentingan (Stakeholders)
              </div>
              <Form.List
                name={[
                  "contextFormulationItems",
                  "contextFormulationStackholderItems",
                ]}
              >
                {(
                  fields,
                  { add: addStakeholder, remove: removeStakeholder },
                  { errors }
                ) => {
                  return (
                    <>
                      {fields.map(({ key, name }) => (
                        <div key={name}>
                          <div className="mx-5 font-semibold flex justify-between items-center">
                            <div></div>
                          </div>
                          <div className="w-full px-5 pb-3 mt-4 space-y-5">
                            <Form.Item name={[name, "id"]} noStyle />
                            <div className="">
                              <label className="field-required font-medium">
                                Stakeholder
                              </label>
                              <Form.Item
                                name={[name, "stakeholder"]}
                                rules={requiredField}
                              >
                                <Input
                                  placeholder="Daftar Sasaran"
                                  type="text"
                                  className="form-input mt-2 w-full"
                                  disabled
                                />
                              </Form.Item>
                            </div>
                            <div className="">
                              <label className="field-required font-medium">
                                Keterangan
                              </label>
                              <Form.Item
                                name={[name, "note"]}
                                rules={requiredField}
                              >
                                <TextArea
                                  placeholder="Fungsi"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: "100%" }}
                                  rows={3}
                                  disabled
                                />
                              </Form.Item>
                            </div>
                          </div>
                          {fields.length > 1 ? <hr className="my-5" /> : <></>}
                        </div>
                      ))}
                    </>
                  );
                }}
              </Form.List>
            </div>
          </div>

          <div
            className="w-100 overflow-hidden mb-28"
            style={{
              borderRadius: "8px",
              border: "1px solid #EAECF0",
              background: "#FFFF",
            }}
          >
            <div
              className=""
              style={{ background: "#00BD52", height: "10px" }}
            ></div>
            <div className="border-b mx-5 pb-3 mt-4 font-semibold flex justify-between items-center">
              3. Struktur Unit Pemilik Risiko
            </div>

            <Form.List
              name={[
                "contextFormulationItems",
                "contextFormulationUnitStructureItems",
              ]}
            >
              {(fields, { add: addUnit, remove: removeUnit }, { errors }) => {
                return (
                  <>
                    {fields.map(({ key, name }) => (
                      <div key={name}>
                        <div className="px-5 mt-4 flex items-center gap-5">
                          <Form.Item
                            noStyle
                            name={[name, "riskOwnerPosition"]}
                          />
                          <div className="flex-1">
                            <label className="field-required font-medium">
                              {name === 0
                                ? "Manager"
                                : name === 1
                                ? "Koordinator"
                                : "Administrator"}{" "}
                              Risiko
                            </label>
                            <Form.Item
                              className="flex-1"
                              name={[name, "riskOwner"]}
                              rules={requiredField}
                            >
                              <Select
                                showSearch
                                optionFilterProp="label"
                                placeholder={
                                  name === 0
                                    ? "Manager"
                                    : name === 1
                                    ? "Koordinator"
                                    : "Administrator"
                                }
                                type="text"
                                className="mt-2"
                                options={userList}
                                disabled
                              />
                            </Form.Item>
                          </div>

                          <div className="flex-1">
                            <label className="font-medium">
                              Jabatan
                            </label>
                            <Form.Item name={[name, "position"]} noStyle/>
                            <Form.Item
                              className="flex-1"
                              name={[name, "positionLabel"]}
                              rules={requiredField}
                            >
                              <Input
                                placeholder="Jabatan"
                                type="text"
                                className="form-input mt-2 w-full"
                                disabled
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                );
              }}
            </Form.List>
          </div>

          <div
            className="mt-6 px-4 rounded border border-[#EAECF0] bg-white"
            style={{
              position: "fixed",
              bottom: 0,
              width: "calc(100vw - 400px)",
            }}
          >
            <div className="flex justify-between items-center">
              <Space className="p-5">
                <NavLink onClick={() => window.history.back()}>
                  <Button icon={<ArrowLeftOutlined />} className="btn-back">
                    <span className="ml-2 text-center">Kembali</span>
                  </Button>
                </NavLink>
              </Space>
              <div className="row">
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ContextFormulationForm;
