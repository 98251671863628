import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Space,
  Upload,
  message,
  notification,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { UploadIcon } from "components/atoms/SVG";
import React, { useEffect, useMemo, useState } from "react";
import { BASE_URL_FILE_SERVICE } from "services/config";
import moment from "moment";
import { useDirectorat, useDivision } from "hooks/useMaster";
import { useQueryClient } from "@tanstack/react-query";
import {
  useAgendaById,
  useStoreAgenda,
  useUpdateAgenda,
} from "hooks/useAgenda";
import { Spinner } from "components";
import dayjs from "dayjs";
import { NavLink } from "react-router-dom";
import { FilePdfOutlined } from "@ant-design/icons";

const AgendaDetail = ({ onClose, data = null, isView }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [fileList, setFileList] = useState([]);
  const [pdfData, setPdfData] = useState(null);

  const PdfViewer = ({ pdfUrl, pdfName, className = "" }) => {
    const openPdf = () => {
      window.open(pdfUrl, "_blank");
    };

    return (
      <div className={className}>
        <Button disabled={false} type="default" onClick={openPdf} icon={<FilePdfOutlined />}>
          {pdfName}
        </Button>
      </div>
    );
  };
  const { data: division, status: division_status } = useDivision(
    ["fetchDivision"],
    {
      refetchOnMount: true,
    }
  );

  const { data: agenda, status: agenda_status } = useAgendaById(
    ["fetchDetailAgenda", data?.id],
    {
      enabled: !!data,
      refetchOnMount: true,
    }
  );

  // mitigation mutation
  const postAgenda = useStoreAgenda();
  const updateAgenda = useUpdateAgenda();

  const onChange = (date, dateString) => {
    // console.log(date, dateString);
  };

  const handleAttachmentChange = (info) => {
    const { file } = info;
    let newFileList = [...info.fileList];

    if (file.status) {
      setFileList(newFileList);
    } else {
      setFileList([]);
      form.setFieldsValue({ idAttachment: pdfData.id ?? undefined });
    }

    if (file.status === "done") {
      setLoading(true);

      setTimeout(() => {
        form.setFieldsValue({ idAttachment: file.response.data.id });
        setLoading(false);
      }, 1000);
    }

    if (file.status === "uploading") {
      setLoading(true);
    }

    if (file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
      setFileList([]);
    }

    if (file.status === "removed") {
      form.setFieldsValue({ idAttachment: pdfData.id ?? undefined });
      setFileList([]);
    }
    // console.log(form.getFieldsValue());
  };

  const beforeUpload = (file) => {
    const isPDF = file.type === "application/pdf";
    if (!isPDF) {
      form.setFieldsValue({
        idAttachment: pdfData.id ?? undefined,
      });
      setFileList([]);
      message.error("You can only upload PDF files!");

      // console.log(form.getFieldsValue());
    }
    return isPDF;
  };

  const onFinish = (values) => {
    let action = updateAgenda;
    if (!agenda && !data) {
      action = postAgenda;
    }
    action.mutate(values, {
      onSuccess: (data) => {
        if (data.isSuccess) {
          notification.success({
            message: data?.message,
            description: "Data telah disimpan",
          });

          queryClient.invalidateQueries({ queryKey: ["fetchagenda"] });
          onClose();
        } else {
          notification.error({
            message: data?.message ?? "Kesalahan",
            description: data?.innerMessage ?? data?.message,
          });
        }
      },
    });
  };

  const props = {
    fileList: fileList,
    name: "file",
    multiple: false,
    action: `${BASE_URL_FILE_SERVICE}/files/docs`,
    beforeUpload: beforeUpload,
    onChange: handleAttachmentChange,
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  useEffect(() => {
    if (agenda) {
      form.setFieldsValue({
        id: agenda.payload?.id,
        idDivision: agenda.payload?.idDivision,
        idAttachment: agenda.payload?.idAttachment,
        note: agenda.payload?.note,
        reason: agenda.payload?.reason,
        date: dayjs(agenda.payload?.date),
      });
      setPdfData(agenda.attachment ?? null);
    }
  }, [agenda]);

  if (data && agenda_status === "pending") {
    return (
      <div className="flex justify-center items-center h-52 w-full">
        <div className="w-20 h-20 border border-green-600 rounded-full border-x-transparent animate-spin"></div>
      </div>
    );
  }

  return (
    <Form
      name="form-agenda"
      form={form}
      onFinish={onFinish}
      initialValues={{
        id: null,
        idDivision: null,
        date: null,
        note: null,
        idAttachment: null,
      }}
    >
      <div className="space-y-1">
        <Form.Item noStyle name={"id"} />
        <Row>
          <Col span={24}>
            <label className="field-required font-medium">
              Nama Divisi
            </label>
            <Form.Item
              shouldUpdate
              name={"idDivision"}
              rules={[{ required: true, message: "Wajib Diisi" }]}
            >
              <Select
                placeholder="Nama Divisi"
                type="text"
                className="mt-2 w-full"
                loading={division_status === "pending"}
                disabled={isView}
              >
                {division?.payload?.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label className="field-required font-medium">Jadwal Rapat</label>
            <Form.Item
              shouldUpdate
              name={"date"}
              rules={[{ required: true, message: "Wajib Diisi" }]}
            >
              <DatePicker
                className="w-full mt-2"
                onChange={onChange}
                showTime={{
                  defaultValue: dayjs("00:00:00", "HH:mm:ss"),
                  format: "HH:mm",
                  minuteStep: 15,
                }}
                showNow={false}
                needConfirm={false}
                placeholder="Pilih Waktu Jadwal Rapat"
                disabled={isView}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label className="font-medium">Catatan</label>

            <Form.Item
              name={"note"}
              // rules={[{ required: true, message: "Wajib Diisi" }]}
            >
              <TextArea
                placeholder="Catatan"
                type="text"
                className="form-input mt-2"
                rows={5}
                disabled={isView}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label className=" font-medium">Dokumen Pendukung</label>
            {pdfData && (
              <PdfViewer
                pdfUrl={pdfData.url}
                pdfName={pdfData.name}
                className="my-3"
              />
            )}
            {(!pdfData && isView) && <div className="my-3">-</div>}
            {!isView && (
              <Form.Item
                shouldUpdate
                name={"idAttachment"}
                // rules={[{ required: true, message: "Wajib Diisi" }]}
                className="my-3"
              >
                <Upload.Dragger
                  accept="application/msword, application/vnd.ms-excel, application/pdf"
                  maxCount={1}
                  {...props}
                  disabled={isView}
                >
                  <p className="ant-upload-drag-icon flex justify-center">
                    {/* <InboxOutlined /> */}
                    <UploadIcon />
                  </p>
                  <p className="ant-upload-text">
                    <strong style={{ color: "#01A24A" }}>
                      Klik untuk upload
                    </strong>{" "}
                    atau drag and drop
                  </p>
                  <p className="ant-upload-hint">PDF (max. 1mb)</p>
                </Upload.Dragger>
              </Form.Item>
            )}
          </Col>
        </Row>
        {(isView && agenda.payload.reason) && (
          <Row>
            <Col span={24}>
              <label className="font-medium">Alasan Penolakan</label>

              <Form.Item
                name={"reason"}
              >
                <TextArea
                  placeholder="Alasan penolakan"
                  type="text"
                  className="form-input mt-2"
                  rows={2}
                  disabled={isView}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {!isView && (
          <>
            <hr className="py-2" />
            <div className="flex justify-end">
              <Form.Item>
                <Space>
                  <Button className="btn-back" onClick={onClose}>
                    <span className="ml-2 text-center">Batal</span>
                  </Button>
                </Space>
                <Space>
                  <Button
                    className="btn-submit ml-3"
                    onClick={() => {
                      form.submit();
                    }}
                    loading={postAgenda.status === "pending"}
                  >
                    <span className="ml-2 text-center">Simpan</span>
                  </Button>
                </Space>
              </Form.Item>
            </div>
          </>
        )}
      </div>
    </Form>
  );
};

export default AgendaDetail;
