import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchRiskProfileById, fetchRiskProfileByIdSingle, fetchRiskProfileHeader, postRiskProfile } from "services/risk_profile";

export const useRiskProfileHeader = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const id = queryKey[1];
      return fetchRiskProfileHeader(id, signal);
    },
    ...options,
  });
};

export const useRiskProfileItem = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const id = queryKey[1];
      return fetchRiskProfileById(id, signal);
    },
    ...options,
  });
};

export const useRiskProfileItemSingle = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const id = queryKey[1];
      return fetchRiskProfileByIdSingle(id, signal);
    },
    ...options,
  });
};


export const useStoreRiskProfile = (options) => {
    return useMutation({
      mutationFn: (params) => postRiskProfile(params),
      ...options,
    })
  }