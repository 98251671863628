/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Button, Space, Tabs } from "antd";
import Icon, { RightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { HomeLined } from "components/atoms/SVG";
import { useLocation, useParams } from "react-router";
import RiskListTabs from "./tabs/risk-list";
import ContextFormulationTabs from "./tabs/context-formulation";
import RiskProfileTabs from "./tabs/risk-profile";
import ManualIRUTabs from "./tabs/manual-iru";
import MitigationPlanTabs from "./tabs/mitigation-plan";
import { NavLink } from "react-router-dom";

const RiskHistory = () => {
  const location = useLocation();
  const { year } = useParams();
  const name = new URLSearchParams(location.search).get("name");
  const [active, setActive] = useState("1");

  const onChangeTabs = (tab) => {
    setActive(tab);
  };

  const items = [
    {
      key: "1",
      label: "Daftar Resiko",
      children: <RiskListTabs year={year} />,
    },
    {
      key: "2",
      label: "Perumusan Konteks",
      children: <ContextFormulationTabs year={year} />,
    },
    {
      key: "3",
      label: "Profile Risiko",
      children: <RiskProfileTabs year={year} />,
    },
    {
      key: "4",
      label: "Manual IRU",
      children: <ManualIRUTabs year={year} />,
    },
    {
      key: "5",
      label: "Rencana Mitigasi",
      children: <MitigationPlanTabs year={year} />,
    },
  ];

  return (
    <>
      <div className="flex items-center py-4 flex-wrap">
        <Space>
          <Icon component={HomeLined} className="mr-2" />
          <RightOutlined className="mr-3 text-xs right-cursor" />
        </Space>
        <div>
          <p className="font-medium" style={{ color: "#475467" }}>
            Riwayat Management Resiko
          </p>
        </div>
        <Space>
          <RightOutlined className="mx-3 text-xs right-cursor" />
        </Space>
        <div>
          <p className="font-medium" style={{ color: "#475467" }}>
            {name || "Detail Dokumen Resiko"}
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-medium">
          {name || "Detail Dokumen Resiko"}
        </h1>
      </div>

      <div className="border border-gray-200 rounded-lg px-3 w-full mb-20">
        <Tabs
          size="large"
          defaultActiveKey={active}
          items={items}
          onChange={onChangeTabs}
        />
      </div>

      <div
        className="px-4 rounded border border-[#EAECF0] bg-white"
        style={{
          position: "fixed",
          bottom: 0,
          width: "calc(100vw - 330px)",
        }}
      >
        <div className="flex justify-between items-center p-3">
          <Space>
            <NavLink to={"/riwayat-manajemen-risiko"}>
              <Button icon={<ArrowLeftOutlined />} className="btn-back">
                <span className="ml-2 text-center">Kembali</span>
              </Button>
            </NavLink>
          </Space>
          <div className="row"></div>
        </div>
      </div>
    </>
  );
};

export default RiskHistory;
