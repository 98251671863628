import { useMutation, useQuery } from "@tanstack/react-query";
import {
  fetchQuarterReport,
  fetchQuarterReportById,
  postQuaterReport,
} from "services/quarter_report";

export const useQuarterReport = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchQuarterReport(params, signal);
    },
    ...options,
  });
};

export const useQuarterReportById = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchQuarterReportById(params, signal);
    },
    ...options,
  });
};

export const useStoreQuaterReport = (options) => {
  return useMutation({
    mutationFn: (params) => postQuaterReport(params),
    ...options,
  });
};
