import { useQuery } from "@tanstack/react-query";
import { fetchRiskList } from "services/risk_list";

export const useRiskList = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchRiskList(params, signal);
    },
    ...options,
  });
};