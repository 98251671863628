import { Form, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { toRoman } from "helpers";
import { useProbabilityLevel, useRiskSize } from "hooks/useMaster";
import { useEffect, useState } from "react";

const QuaterSection = ({ name, data, form, isDetail }) => {
  const requiredField = [{ required: true, message: "Wajib Diisi" }];
  const [selectedProbability, setSelectedProbability] = useState(null);
  const [selectedEnumLevel, setSelectedEnumLevel] = useState(null);
  const enumLevel = [
    { id: 1, value: "1-Tidak Signifikan" },
    { id: 2, value: "2-Minor" },
    { id: 3, value: "3-Moderat" },
    { id: 4, value: "4-Signifikan" },
    { id: 5, value: "5-Sangat Signifikan" },
  ];
  const probabilityLevel = useProbabilityLevel(["fetchProbabilityLevel"], {
    refetchOnMount: true,
  });

  const riskSize = useRiskSize(
    [
      "fetchRiskSize",
      {
        IdProbabilityLevel: selectedProbability,
        ImpactLevelEnum: selectedEnumLevel,
      },
    ],
    {
      enabled: !!selectedProbability && !!selectedEnumLevel,
      refetchOnMount: true,
    }
  );
  useEffect(() => {
    let formValues = form.getFieldsValue();

    // Check if the riskSize value has changed before updating the form values
    const newIdRiskSize = riskSize?.data?.payload?.id;
    const currentIdRiskSize =
      formValues.riskReportItems.riskReportAmountQuarters[name]?.idRiskSize;

    if (newIdRiskSize !== currentIdRiskSize) {
      // Update the form values only if there is a change
      formValues.riskReportItems.riskReportAmountQuarters[name].idRiskSize =
        newIdRiskSize;

      // Update the form values in state or wherever necessary
      form.setFieldsValue(formValues);
    }
  }, [riskSize, form, name]);

  useEffect(() => {
    if (data?.payload?.riskReportAmountQuarters) {
      setSelectedProbability(
        data?.payload?.riskReportAmountQuarters[name]?.idProbabilityLevel
      );
      setSelectedEnumLevel(
        data?.payload?.riskReportAmountQuarters[name]?.impactLevelEnum
      );
    }
  }, [data]);

  return (
    <>
      <div className="text-[#01A24A] text-base flex items-center justify-between mx-5 pb-3 mt-4 font-semibold">
        <div>Besaran Triwulan {toRoman(name + 1)}</div>
      </div>

      <Form.Item name={[name, "id"]} noStyle />
      <Form.Item name={[name, "quater"]} noStyle />

      <div className="flex items-start justify-center gap-5 w-full px-5 pb-3 mt-4">
        <div className="flex-1">
          <label className="field-required font-semibold">
            Penjelasan Capaian Sampai dengan Periode Laporan
          </label>
          <Form.Item name={[name, "periodReportNote"]} rules={requiredField}>
            <TextArea
              placeholder="Penjelasan Capaian Sampai dengan Periode Laporan"
              type="text"
              className="form-input mt-2"
              style={{ width: "100%" }}
              rows={5}
              disabled={isDetail}
            />
          </Form.Item>
        </div>
      </div>

      <div className="text-[#01A24A] text-base mx-5 pb-3 mt-4 font-semibold">
        Capaian besaran Risiko s.d Periode Pelaporan
      </div>

      <div className="flex items-start justify-center gap-5 w-full px-5 pb-3 mt-4">
        <div className="flex-1">
          <label className="field-required font-semibold">
            Level Kemungkinan
          </label>
          <Form.Item name={[name, "idProbabilityLevel"]} rules={requiredField}>
            <Select
              placeholder="Level Kemungkinan"
              type="text"
              className="mt-2 w-full"
              onChange={setSelectedProbability}
              disabled={isDetail}
            >
              {probabilityLevel?.data?.payload.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.parameter}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className="flex-1">
          <label className="field-required font-semibold">Level Dampak</label>
          <Form.Item name={[name, "impactLevelEnum"]} rules={requiredField}>
            <Select
              placeholder="Level Dampak"
              type="text"
              className="mt-2 w-full"
              onChange={setSelectedEnumLevel}
              disabled={isDetail}
            >
              {enumLevel.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className="flex-1">
          <label className="field-required font-semibold">Besaran Risiko</label>
          <Form.Item name={[name, "idRiskSize"]} noStyle />
          <Input
            placeholder=""
            type="text"
            className="w-full form-input mt-2"
            value={riskSize?.data?.payload?.name ?? ""}
            disabled
          />
        </div>
      </div>

      <div className="flex items-start justify-center gap-5 w-full px-5 pb-3 mt-4">
        <div className="flex-1">
          <label className="field-required font-semibold">
            Penjelasan Capaian Besaran Risiko
          </label>
          <Form.Item name={[name, "riskSizeNote"]} rules={requiredField}>
            <TextArea
              placeholder="Penjelasan Capaian Besaran Risiko"
              type="text"
              className="w-full form-input mt-2"
              rows={5}
              disabled={isDetail}
            />
          </Form.Item>
        </div>
      </div>
    </>
  );
};

export default QuaterSection;
