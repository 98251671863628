import { useQuery } from "@tanstack/react-query";
import { fetchDashboard } from "services/dashboard";

export const useDashboard = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return fetchDashboard(params, signal);
    },
    ...options,
  });
};
