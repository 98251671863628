import { BASE_URL_MANRISK } from "./config";
import axiosInstance from "app/interceptors";

export const fetchQuarterReport = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${BASE_URL_MANRISK}/risk-report/datatable`,
    { params, signal }
  );
  return data;
};

export const fetchQuarterReportById = async (id, signal) => {
  const { data } = await axiosInstance.get(`${BASE_URL_MANRISK}/risk-report/${id}`, {
    signal,
  });
  return data;
};

export const postQuaterReport = async ({ type, params }) => {
  const { data } = await axiosInstance.patch(`${BASE_URL_MANRISK}/risk-report/${type}`, params);

  return data;
};
