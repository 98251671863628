import React, { useState, useEffect } from 'react';
import { Space, Table, Spin } from 'antd';
import Icon, {
    RightOutlined,
} from '@ant-design/icons';
import { BASE_URL_MANRISK } from 'services/config';
import axiosInstance from 'app/interceptors';

const HomeLined = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.66667 13.1667H12.3333M8.18141 1.30333L2.52949 5.69927C2.15168 5.99312 1.96278 6.14005 1.82669 6.32405C1.70614 6.48704 1.61633 6.67065 1.56169 6.86588C1.5 7.08627 1.5 7.32558 1.5 7.80421V13.8333C1.5 14.7667 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1585 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1585 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7667 16.5 13.8333V7.80421C16.5 7.32558 16.5 7.08627 16.4383 6.86588C16.3837 6.67065 16.2939 6.48704 16.1733 6.32405C16.0372 6.14005 15.8483 5.99312 15.4705 5.69927L9.81859 1.30333C9.52582 1.07562 9.37943 0.961766 9.21779 0.918001C9.07516 0.879384 8.92484 0.879384 8.78221 0.918001C8.62057 0.961766 8.47418 1.07562 8.18141 1.30333Z" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

const ImpactArea = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let path = `${BASE_URL_MANRISK}/master-data/area-impact`

        axiosInstance
            .get(path)
            .then((response) => {
                if (response.data.isSuccess) {
                    setData(response.data.payload);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const columns = [
        {
            title: 'Area Dampak',
            dataIndex: 'name',
            key: 'name',

        },
        // {
        //     title: 'Nomor Urut Prioritas',
        //     dataIndex: 'priority',
        //     key: 'priority',
        //     width: '300px',

        // },
    ];

    return (
        <>
            <div className="flex items-center py-5 flex-wrap">
                <Space>
                    <Icon component={HomeLined} className="mr-2" />
                    <RightOutlined
                        className="mr-3 text-xs right-cursor"
                    />
                </Space>
                <div>
                    <p className="font-medium" style={{ color: "#475467" }}>Master Data</p>
                </div>
                <Space>
                    <RightOutlined
                        className="mx-3 text-xs right-cursor"
                    />
                </Space>
                <div>
                    <p className="font-medium" style={{ color: "#01A24A" }}>Area Dampak</p>
                </div>
            </div>
            <div className="flex items-center justify-between mb-4">
                <h1 className="text-2xl font-medium">List Data Area Dampak</h1>
            </div>
            <div className="table-list" style={{ border: "1px solid #EAECF0", borderRadius: "12px" }}>
                <div className="py-6 px-6">
                    <Spin spinning={loading}>
                        <Table
                            columns={columns}
                            dataSource={data}
                            pagination={false}
                            className="px-5"
                            style={{ overflowX: "auto" }}
                        // onChange={onSearch}
                        />
                        {/* <div className="flex table-paginator-wrapper my-3 px-2 justify-between">
                            <div className="flex flex-row justify-content-between mb-2 px-2 justify-between items-center">
                                <div className="show align-self-center pr-2 font-medium text-sm">
                                    {'Tampilkan Data'}
                                </div>
                                <div className="dropdown-paging align-self-center pr-2">
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                        <Select
                                            className="select-paging-ppd"
                                            style={{ width: "60px" }}
                                            options={[
                                                { value: 5, label: 5 },
                                                { value: 10, label: 10 },
                                                { value: 30, label: 30 },
                                                { value: 50, label: 50 },
                                                { value: 100, label: 100 },
                                            ]}
                                        />
                                    </Space>
                                </div>
                                <div className="border-paging align-self-center pr-2"></div>
                                <div className="total align-self-center pr-2">
                                    {
                                        // `${dataDonor.slice(0, 1).length} - ${dataDonor.length} data dari ${paginator?.itemCount} data`
                                    }
                                </div>
                            </div>
                            <div>
                                <Pagination/>
                            </div>
                        </div> */}
                    </Spin>
                </div>
            </div>
        </>
    );
}

export default ImpactArea;